@import "src/styles/helpers";

.fieldWrapper {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    word-wrap: break-word;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--secondary-500);

    &_empty {
        gap: 12px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__label {
            display: flex;
            align-items: center;
            gap: 6px;
            color: var(--dark);
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__field {
        width: 100%;

        &>div {
            width: 100%;
        }
    }
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 24px;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;
    }

    &__input {
        width: 100%;
    }

    &__fieldValue {
        color: var(--dark);
        @include bodyMedium;

        &_empty {
            color: var(--grey);
        }
    }

    &__fieldError {
        margin-top: 8px;
        color: var(--red);
        @include bodySmall;
    }

    &__footer {
        padding-bottom: 24px;
        display: flex;
        align-items: center;
        gap: 16px;

        .button {
            width: 100%;
        }
    }

    .actionButton {
        display: flex;
        align-items: center;
        color: var(--primary-500);
        background-color: transparent !important;
        text-decoration: underline;
        @include bodySmall;
        padding: 0;
        gap: 4px;

        &>svg {
            width: 18px;
            height: 18px;
        }

        &__add {
            &>svg {
                width: 12px;
                height: 12px;
            }
        }

        &:disabled {
            color: var(--primary-100);
            cursor: not-allowed;
        }
    }
}