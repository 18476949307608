.radio {

  :global {

    .MuiTypography-root {
      font-size: inherit !important;
      line-height: inherit !important;
      font-weight: 500 !important;
    }
  }
}

.icon {
  color: var(--primary-500);
}

.item {
  margin-right: 0 !important;
  margin-left: -9px !important;
}
