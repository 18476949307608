.form {
  display: grid;
  align-content: space-between;
  height: 100%;

  &__footer {
    padding: 32px 0 24px;

    button {
      width: 100%;
    }
  }

  &__checkboxLabel {
    margin-top: 40px;
  }
}
