.warningTooltip {
  --warning-background-color: #FDE3E3;

  &.popperArrow {
    .tooltip {
      padding: 6px 8px !important;
      background-color: var(--warning-background-color) !important;
      border: 1px solid var(--red) !important;
      color: var(--dark) !important;
      border-radius: 8px !important;
    }

    .arrow {
      bottom: -8px !important;
      top: unset !important;
      margin: 0 auto !important;
      left: 0 !important;
      right: 0 !important;
      border-width: 7px 8px 0px 8px !important;
      border-color: var(--red) transparent transparent transparent !important;
    }
  }


  &__iconWrapper {
    --warning-border-color: #FCCCCC;
    --warning-color: #D52D2D;
    --warning-background-color: #FDE3E3;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid var(--warning-border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    background: var(--warning-background-color);

    svg {
      color: var(--warning-color);
      max-height: 20px;
    }
  }
}