@import "src/styles/helpers";

.newUserBtn {
  margin-left: auto;

  @include media(mobile) {
    font-size: 24px;

    span {
      display: none;
    }
  }
}

.loader {
  position: relative;
  margin: 0 auto;
  top: calc((var(--vh, 1vh) * 100 - var(--header-height)) / 3);
}

.membersList {
  padding: 24px;
  display: flex;
  flex-direction: column;
  max-width: 1410px;
  gap: 8px;
  margin: auto;
  height: calc(100% - 74px);

  @include media(mobile) {
    max-width: calc(100 * var(--vw));
  }

  &_empty {
    justify-content: center;
  }

  &_loading {
    opacity: 0.5;
    cursor: progress;

    >* {
      pointer-events: none;
    }
  }

  &__empty {
    @include h5;
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 32px;

    &__img {
      width: 230px;
      height: 205px;
    }

    &__text {
      @include bodyMedium;
    }
  }

  @include media(notDesktop) {
    padding: 16px;
  }
}

.member {
  padding: 16px 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: var(--white);

  &__avatar {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
  }

  &__name {
    @include subtitle;

    .member__role {
      display: none;
    }

    @include media(mobile) {
      display: flex;
      flex-direction: column;

      .member__role {
        display: flex;
        gap: 8px;
        align-items: center;
        @include subtitleSmall;


        svg {
          width: 14px;
          height: 14px;
        }
      }


    }

  }


  &__pmLabel {
    display: grid;
    grid-template-columns: 16px auto;
    gap: 8px;
    padding: 8px;
    border-radius: 8px;
    background: var(--secondary-200);
    @include bodySmall;

    &_icon {
      width: 16px;
      height: 16px;
    }

    @include media(mobile) {
      display: none;
    }
  }

  &__label {
    @include bodySmall;
    margin-top: -16px;
    padding: 8px;
    border-radius: 32px;
    display: grid;
    grid-template-columns: 23px auto;
    gap: 8px;
    align-items: center;
    text-decoration: underline;
    background: var(--bg-main);

    &__icon {
      width: 23px;
      height: 23px;
    }
  }

  &__selector {
    width: 170px;
    @include bodySmall;

    @include media(mobile) {
      display: none !important;
    }
  }

  &__selectorPaper {
    max-height: 400px !important;
  }


  &__removeBtn {
    flex-shrink: 0;

    border-radius: 8px;
    padding: 12px;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media(mobile) {
      display: none;
    }

    &:hover {
      background: var(--secondary-100);
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &_icon {
      color: var(--red);
      width: 16px;
      height: 16px;
    }

  }

  &__optionsBtn {
    margin-left: auto;
    padding: 12px;
    display: none !important;

    @include media(mobile) {
      display: flex !important;
    }
  }
}

.selectorItem {
  display: grid;
  grid-template-columns: 14px auto;
  gap: 8px;
  align-items: center;
  @include bodySmall;

  svg {
    display: flex;
    align-items: center;
    width: 14px;
    height: 14px;
  }
}

.drawer {
  &>div {
    width: calc(480px - 48px);

    @include media(mobile) {
      width: 100%;
    }
  }
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__select {
    max-height: 400px !important;
  }

  &__section {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
  }

  &__autocomplete {
    height: 56px !important;

    &__label {
      left: -7px !important;

      &_active {
        left: 0 !important;
        top: 0 !important;
      }
    }

    svg {
      display: none;
    }

    &__icon {
      @include bodySmall;
      margin-right: 8px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      text-transform: uppercase;
      background-color: var(--primary-500);
      flex-shrink: 0;
    }
  }

  &__footer {
    padding: 32px 0 24px;
    background: var(--white);

    &__button {
      width: 100%;
    }
  }
}

.editAccessModal {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 24px;

  height: 100%;
  padding-bottom: 24px;

  .rolesList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    li {
      width: 100%;

      .role {
        display: flex;
        height: 56px;
        padding: 12px;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        &__icon {
          width: 20px;
          height: 20px;
        }

        &__caption {
          @include bodySmall;
        }

        &__selectIndicator {
          display: flex;
          width: 20px;
          height: 20px;
          padding: 4px;
          justify-content: center;
          align-items: center;

          margin-left: auto;

          border-radius: 10px;
          border: 1px solid var(--grey);

          &__active {
            width: 10px;
            height: 10px;

            border-radius: 50%;
            background-color: var(--primary-500);
          }
        }
      }
    }
  }

  .deleteButton {
    margin-top: auto;
    width: 100%;

    color: var(--red) !important;

    &__icon {
      width: 20px;
      height: 20px;
    }
  }

  &__loader {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    backdrop-filter: contrast(0.5);
  }
}

.memberSettings {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  &>div {
    width: 170px;
  }
}