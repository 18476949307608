@import "../../styles/helpers";

.wrapper {
  position: relative;
  width: 106px;
  height: 42px;
  border-radius: 8px;
}

.selector {
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  button {
    width: 100%;
  }

  &__selected {
    background: var(--secondary-100);
  }

  &__item {
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    &:hover {
      background: var(--secondary-200);
    }
  }

  &__lang {
    @include bodySmall;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 8px;
  }

  &__icon {
    width: 14px;
    height: 12px;
    position: absolute;
    right: 12px;
    color: var(--dark);
  }

  &__option {
    background: var(--white);
    border-top: 1px solid var(--secondary-100);
  }
}

.opened {
  .selector {
    box-shadow: 0 9px 45px 0 rgba(0, 41, 54, 0.16);

    &__icon {
      transform: rotate(180deg);
    }

    &__selected, &__selected:hover {
      background: var(--white);
    }
  }
}

.flagIcon {
  width: 18px;
  height: 18px;
}
