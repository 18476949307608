@import "src/styles/helpers";

.wrapper {
  padding: 16px;
  min-height: calc(var(--vh, 1vh) * 100 - 32px);
}
.withHeader {
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));

  .notFound {
    min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  }
}
.notFound {
  display: grid;
  justify-content: center;
  align-content: space-between;
  justify-items: center;
  background-color: var(--white);
  border-radius: 8px;
  padding: 32px;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100 - 32px);
}
.header {
  svg {
    width: 166px;
    height: 40px;
  }
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 500px;
    height: 262px;

    @include media(mobile) {
      width: 315px;
    }
  }
  p {
    @include bodyLarge;
    margin: 48px 0;

    @include media(mobile) {
      text-align: center;
    }
  }
}
.footer {
  @include bodySmall;
  color: var(--dark);
}
