@import "src/styles/helpers";

.mobile__tabsSelect__wrapper {
    width: 100%;
    padding: 12px 12px 0px 12px;
  
    display: none;
  
    @include media(mobile) {
      display: block;
    }
  
    button { 
      width: 100%;
      color: var(--primary-500);
    }
  
    .popoverButton__content {
      width: 100%;
      display: flex;
      height: 42px;
      padding: 11px 12px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    
      border-radius: 8px;
      background: var(--secondary-100);
    }
    
    .downloadButton__arrow {
      width: 20px;
      height: 20px;
    
      margin-left: auto;
    }
  }
  
  .popoverOption {
    color: var(--grey);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
  
    position: relative;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
    }
  
    &::before {
      content: "";

      position: absolute;
      top: -12px;
      left: -12px;
  
      height: 44px;
      width: 2px;
  
      background-color: transparent;
    }
  
    &:hover, &:focus {
      color: var(--primary-500);
    }
  
    &.activeOption {
      color: var(--primary-500);

      &::before {
        background-color: var(--primary-500);

      }
    }
  
    .popoverOption__text {
      margin-top: 2px;
    }
  }
  
  .clients__popoverPaper {
    width: 220px;
    left: 16px;
  }

  .tab__icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}