@import "src/styles/helpers";

.main {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  padding: 48px;
  background: #f3f3f3;

  > div {
    display: grid;
    gap: 48px;
  }
}

.block {
  display: grid;
  gap: 24px;
  padding: 64px;
  width: 100%;
  height: 100%;
  background: var(--white);

  > *:first-child {
    margin-bottom: 48px;
  }
}

.subtitle {
  @include subtitle;
}

.subtitleSmall {
  @include subtitleSmall;
}

.bodyLarge {
  @include bodyLarge;
}

.bodyMedium {
  @include bodyMedium;
}

.bodySmall {
  @include bodySmall;
}

.bodyExtraSmall {
  @include bodyExtraSmall;
}

.caption {
  @include caption;
}

.popoverPaper {
  padding: 12px;
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.h5 {
  @include h5;
}

.drawerContent {
  overflow-y: auto;

  span {
    display: block;
    height: 1000px;
  }
}

.button {
  white-space: nowrap;
}
.autocompleteIcon {
  width: 32px;
  height: 32px;
  background-color: var(--primary-500);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  margin-right: 8px;
}
