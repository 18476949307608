@import "src/styles/helpers";

.hint {
  padding: 16px;
  background: var(--secondary-100);
  border-radius: 8px;
  border: 1px solid var(--secondary-500);

  &_withIcon {
    display: grid;
    grid-template-columns: 24px auto;
    column-gap: 8px;
    align-items: center;
  }

  &__title {
    @include subtitle;
    margin-bottom: 16px;
    color: var(--dark);

    @at-root .hint_withIcon & {
      grid-column: 2 / 3;
    }
  }

  &__description {
    @include bodySmall;
    color: var(--dark);
    grid-column: 1 / 3;


    @at-root .hint_withIcon & {
      grid-column: 2 / 3;
    }

    @at-root .hint_withTitle & {
      grid-column: 1 / 3;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    grid-column: 1 / 2;
    align-self: center;
    grid-row: auto;

    &_withTitle {
      align-self: baseline;
    }
  }
}