@import 'src/styles/helpers';

.drawer {
  width: 800px;
  padding-bottom: 0;

  header {
    div {
      margin-bottom: 32px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 16px;
      }
    }
  }

  @include media(tabletPortrait) {
    width: 752px;
  }

  @include media(mobile) {
    width: 90%;
  }

  @include media(mobileSmall) {
    width: 100%;
  }
}

.heading {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    @include h5();
    color: var(--dark);
  }
}


.loader {
  margin: 0 auto;
}