@import "src/styles/helpers";

.passwordStrength {
  display: grid;
  gap: 12px;
  margin-top: 12px;
}

.strength {
  &__lines {
    margin-bottom: 6px;
    display: grid;
    gap: 4px;
    grid-template-columns: repeat(4, 1fr);

    & > div {
      background-color: var(--secondary-500);
      height: 4px;
      border-radius: 2px;
    }
  }
  &__lines.excellent {
    & > div:nth-child(1),
    & > div:nth-child(2),
    & > div:nth-child(3),
    & > div:nth-child(4) {
      background-color: var(--green);
    }
  }
  &__lines.good {
    & > div:nth-child(1),
    & > div:nth-child(2),
    & > div:nth-child(3) {
      background-color: var(--blue);
    }
  }
  &__lines.fair {
    & > div:nth-child(1),
    & > div:nth-child(2) {
      background-color: var(--orange);
    }
  }
  &__lines.poor {
    & > div:nth-child(1) {
      background-color: var(--red);
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    @include bodyExtraSmall;
    color: var(--grey);
  }
  &__status {
    @include subtitleSmall;
    text-transform: capitalize;
  }
  &__status.excellent {
    color: var(--green);
  }
  &__status.good {
    color: var(--blue);
  }
  &__status.fair {
    color: var(--orange);
  }
  &__status.poor {
    color: var(--red);
  }
}

.rules {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 8px;

  &__rule {
    color: var(--grey);
    display: flex;
    align-items: center;
    @include bodyExtraSmall;

    svg {
      margin-right: 4px;
      width: 14px;
      height: 14px;
    }
  }

  &__valid {
    color: var(--green);

    svg {
      color: var(--green);
    }
  }
}
