@import "src/styles/helpers";

.dropdown {
  padding: 8px;

  &__user {
    padding: 8px 12px 16px 12px;
    display: flex;
    border-bottom: 1px solid var(--outlines-light);

    & > svg {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }

    h5 {
      @include bodySmall;
      margin-bottom: 2px;
    }

    p {
      @include bodyExtraSmall;
      color: var(--grey);
    }
  }

  &__item {
    padding: 12px;
    display: flex;
    color: var(--grey);
    @include ease;

    &:hover {
      color: var(--dark);
    }

    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 14px;
    }

    p {
      @include subtitleSmall;
    }
  }
}

.userPlaceholder {
  color: var(--primary-500);
}

.userButton {
  width: 24px;
  height: 24px;
  color: var(--primary-500);

  &:hover {
    color: var(--primary-400);
  }
}

.userBlock {

  @include media(notTabletLandscape) {
    display: none;
  }
}
