@import "src/styles/helpers";

.root {
  font-weight: normal !important;

  &.withPlaceholder {
    :global {
      .MuiInputLabel-shrink {
        display: none;
      }

      fieldset {
        * {
          display: none !important;
        }
      }
    }
  }


  :global {

    label.Mui-disabled,
    .MuiInputBase-root.Mui-disabled {
      opacity: 0.5;
    }

    .MuiSelect-icon {
      width: 16px !important;
      height: 16px !important;
      right: 18px !important;

      path {
        fill: var(--grey);
      }
    }

    .MuiInputLabel-shrink {
      font-weight: 600 !important;
      color: var(--dark) !important;
    }

    .MuiInputBase-input {
      @include bodyMedium;
    }

    .MuiInputLabel-root {
      &:not(.MuiInputLabel-shrink) {
        padding-top: 2px;
        @include bodyMedium;
      }
    }

    label:has(+ .Mui-disabled) {
      color: var(--grey) !important;
    }

    .MuiInputBase-root {
      .MuiSelect-select {
        padding: 18px 17px;
      }


      fieldset {
        border-color: var(--secondary-500) !important;
        border-radius: 8px !important;
        border-width: 1px !important;
      }

      &:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused) {

        &:hover {

          fieldset {
            border-color: var(--secondary-700) !important;
          }
        }
      }

      &:focus-within,
      &:focus-visible {

        fieldset {
          border: 1px solid var(--primary-500) !important
        }
      }

      &.Mui-error {

        fieldset {
          border-color: var(--red) !important;
        }
      }
    }

    .MuiSelect-iconOpen+fieldset {
      border-radius: 8px 8px 0 0 !important;
      border-bottom: none !important;
    }
  }

  .selectAll {
    :global {
      .MuiMenuItem-root:last-of-type {
        border-radius: 1px solid var(--secondary-500) !important;
      }
    }
  }


}

// TODO: The tile and tile__paper selections styles are currently dependent on the root styles.
// We need to refactor them to be independent.

.tile {
  :global {
    .MuiSelect-select {
      @include bodySmall;
      padding: 12px 35px 12px 12px !important;
      background: var(--secondary-100);
      border-radius: 8px !important;
      border: 1px solid transparent !important;


      &[aria-expanded="true"] {
        border-radius: 8px 8px 0 0 !important;
        border-bottom: none !important;
        border: 1px solid var(--secondary-400) !important;
        border-bottom: 1px solid transparent !important;
        background: var(--secondary-50) !important;
      }

      &:focus-within,
      &:focus-visible,
      &:hover {
        border: 1px solid transparent;
        background-color: var(--secondary-200);
      }
    }

    .MuiInputLabel-root {
      color: var(--dark) !important;
      transform: translate(14px, 11px) scale(1);
      // this is bodySmall mixin with !important;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 16px !important;

      transition: none;

      &.MuiFormLabel-filled {
        display: none;
      }
    }

    .MuiInputLabel-shrink {
      transform: translate(14px, 13px) scale(1);
    }

    .MuiInputBase-root {

      &:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused):hover fieldset,
      &:not(.Mui-disabled):not(.Mui-error):focus-within fieldset,
      &:not(.Mui-disabled):not(.Mui-error):focus-visible fieldset,
      &:not(.Mui-disabled):not(.Mui-error):hover fieldset {
        border-color: transparent !important;
      }

      fieldset {
        border-color: transparent !important;
        @include bodySmall;
      }

      legend {
        width: 0;
      }
    }

    .MuiSelect-icon path {
      fill: var(--dark) !important;
    }
  }
}


.paperRoot {
  background: var(--white) !important;
  box-shadow: 0 38px 30px 0 rgba(0, 41, 54, 0.04) !important;
  border: 1px solid var(--secondary-500) !important;
  border-radius: 0 0 8px 8px !important;

  :global {

    .MuiMenuItem-root {

      &:hover,
      &.Mui-focusVisible {
        background: var(--secondary-100) !important;
      }
    }

    .MuiMenuItem-root.Mui-selected {
      background: var(--secondary-400) !important;
    }
  }

  &:has(.selectAll) {
    :global {
      .MuiMenuItem-root {
        padding: 12px 12px 12px 20px !important;
      }
    }
  }

  .selectAll {
    :global {
      .MuiMenuItem-root {
        padding: 12px !important;
      }
    }
  }
}

.tile__paper {
  background: var(--secondary-50) !important;
  max-width: 400px !important;
  border: 1px solid var(--secondary-400) !important;
  border-top: none !important;
  border-bottom: none !important;

  :global {
    .MuiList-root {
      padding: 0 !important;
    }

    .MuiMenuItem-root {
      padding: 12px !important;
      @include bodySmall;
      white-space: wrap;
      border-top: 1px solid var(--secondary-400);
      border-left: none;
      border-left: none;


      &:last-of-type {
        border-bottom: 1px solid var(--secondary-400);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:hover,
      &:focus,
      &:focus-visible {
        background-color: var(--secondary-200) !important;
      }
    }

    .MuiMenuItem-root.Mui-selected {
      background-color: var(--secondary-300) !important;
    }

    .MuiCheckbox-root {
      padding: 0 8px;
    }

    .MuiTypography-root {
      @include bodySmall;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }

  .selectAll {
    :global {
      .MuiMenuItem-root:last-of-type {
        border-radius: 0 !important;
        border-radius: 1px solid var(--secondary-500) !important;
      }
    }
  }
}

.label {
  color: var(--grey) !important;
}

.error {
  @include bodySmall;
  color: var(--red);
  margin-top: 8px;
}

.arrow {
  position: absolute;
  cursor: pointer;
  right: 20px;
  height: 16px;
  width: 16px;
  pointer-events: none;
  color: var(--grey);
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;

  &__checkbox {
    margin-right: 0 !important;
  }

  &__icon {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}