@import 'src/styles/helpers';

.statusLabel {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: rgba(0, 103, 136, 0.1);
  @include bodyExtraSmall;

  &_large {
    @include bodySmall;
  }

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    background: var(--primary-500);
    border-radius: 50%;
    flex-shrink: 0;
  }

  &_active {
    background: rgba(96, 198, 106, 0.07);

    &:before {
      background: var(--green);
    }
  }


  &_inactive {
    border: 1px solid var(--secondary-500);
    color: var(--grey);
    background: transparent;

    &:before {
      background: var(--grey);
    }
  }

  &_warning {
    background: rgba(46, 120, 230, 0.07);

    &:before {
      background: var(--blue);
    }
  }

  &_error {
    background: rgba(235, 96, 96, 0.07);

    &:before {
      background: var(--red);
    }
  }

  &_pending {
    background: rgba(208, 140, 38, 0.07);

    &:before {
      background: var(--orange);
    }
  }
}