@import "src/styles/helpers";

.twoFactorAuth {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__subtitle {
    @include bodyMedium;
  }

  &__tilesSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__block {
    padding: 16px;
    border: 1px solid var(--secondary-500);
    display: flex;
    border-radius: 8px;
    gap: 16px;
    flex-direction: column;
    background-color: var(--secondary-100);

    &__subtitle {
      @include subtitleSmall;
      margin-bottom: 8px;
    }

    &__title {
      @include subtitle;
      display: flex;
      gap: 6px;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;
        color: var(--red);
      }
    }

    &__paragraph {
      @include bodySmall;
    }

    &__prop {
      margin-bottom: 8px;
      display: flex;
      gap: 8px;
      align-items: center;

      &:last-of-type {
        margin-bottom: 0;
      }

      p {
        &:first-of-type {
          color: var(--grey);
        }
      }
    }

    &_qrCode {
      display: grid;
      grid-template-columns: auto 180px;
      gap: 40px;

      @include media(mobile) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__textContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
  }

  &__code {
    min-height: 180px;

    svg {
      width: 180px;
      height: 180px;

      path {
        fill: var(--black);
      }
    }

    @include media(mobile) {
      margin: 0 auto;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;

    &__title {
      @include h5;
    }

    &__btn {
      margin-top: auto;
      width: 100%;
    }

    &__clue {
      margin-top: 8px;
      @include bodySmall;
      color: var(--grey);
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    padding: 24px 0;
    background-color: var(--white);
    z-index: 2;
  }
}