@import "src/styles/helpers";

.form {
  $block-padding: 24px;

  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @include media(mobile) {
    max-width: calc(90 * var(--vw) - $block-padding * 2);
  }

  @include media(mobileSmall) {
    max-width: calc(100 * var(--vw) - $block-padding * 2);
  }

  &__content,
  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__content {
    gap: 32px;
  }

  &__inner {
    gap: 24px;
  }

  &__block {
    padding: $block-padding;
    border-radius: 8px;
    border: 1px solid var(--secondary-500);
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 492px;

    @include media(mobile) {
      width: unset;
    }

    &_loader {
      display: flex;
      justify-content: center;
    }

    &_select {
      max-height: 500px !important;
      max-width: 442px !important;
      overflow-x: auto !important;

      .noResults {
        display: flex;
        padding: 24px 0;
        justify-content: center;
        @include subtitleSmall;
        color: var(--grey);
      }

      @include media(mobile) {
        max-width: 90% !important;
      }
    }

    &_radio {
      :global {
        .MuiTypography-root {
          font-weight: 600 !important;
        }
      }
    }

    &_subtitle {
      @include h5;
    }

    &_paragraph {
      @include bodyMedium;
      margin-top: 8px;
      color: var(--grey)
    }
  }

  &__input {
    position: relative;

    :global {
      .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
        left: 39px !important;
      }

      .MuiInputBase-input {
        @include bodyMedium;
        padding-left: 52px !important;
        font-family: Inter, sans-serif;
        color: var(--dark);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 17px;
      left: 16px;
      width: 20px;
      height: 20px;
      background-size: contain;
      z-index: 2;
    }


    &_en {
      &:before {
        background: url('/public/media/eng-lang-icon.svg') no-repeat center;
      }
    }

    &_de {
      &:before {
        background: url('/public/media/deu-lang-icon.svg') no-repeat center;
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding: 32px 0 24px;
    display: flex;
    gap: 16px;

    button {
      width: 100%;

      &>div {
        margin-right: 8px !important;
      }
    }
  }
}

.newDeliverable {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  background: var(--secondary-100);
  border: 1px solid var(--secondary-500);
  border-radius: 8px;
  @include bodySmall;

  button {
    text-decoration: underline;
    color: var(--primary-500);

    &:hover,
    &:active,
    &:focus {
      color: var(--primary-400);
    }
  }

  &__infoIcon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}