@import "src/styles/helpers";

@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}

.notification {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 32px;
  --border-line-width: 4px;
  border-left: var(--border-line-width) solid;
  min-width: 360px;
  width: 100%;
  max-height: 392px; // 2x of default example in figma
  top: 20px;
  left: 20px;
  padding: 18px 18px 18px calc(18px - var(--border-line-width));
  border-radius: 8px;
  background: var(--white);
  box-shadow: 0 12px 22.1px 0 rgba(5, 33, 41, 0.07);
  overflow: hidden;

  &.error {
    border-color: var(--red);
  }

  &.success {
    border-color: var(--green);
  }

  .notificationText {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      color: var(--dark);
      @include subtitle;
    }

    .body {
      color: var(--grey);
      @include bodySmall;
    }
  }

  .closeButton {
    color: var(--grey);

    &:hover {
      color: var(--dark);
    }

    svg {
      width: 14px;
      height: 14px;
      margin-bottom: auto;
    }
  }
}

.infoPopup {
  display: flex;
  width: 268px;
  height: 96px;
  background-color: var(--dark);
  border-radius: 8px;
  padding: 16px;
  gap: 12px;

  .icon {
    background-color: var(--primary-900);
    padding: 8px;
    border-radius: 100px;
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
      color: var(--white);
    }

    &_success {
      background-color: var(--green);

      svg {
        color: var(--dark);
      }
    }

    &_pending {
      svg {
        animation: spinner 1.5s infinite linear;
      }
    }
  }

  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--white);

    .title {
      @include subtitleSmall;
    }

    .body {
      @include bodySmall;
    }
  }

  .closeButton {
    color: var(--white);
    width: 14px;
    height: 14px;

    &:hover {
      color: var(--grey);
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.popupWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 1, 1, 0.2);
    z-index: 1;
  }

  .popup {
    --padding: 32px;
    position: relative;
    top: calc(var(--vh, 1vh) * 30);
    margin: 0 auto;
    padding: var(--padding);
    border-radius: 8px;
    width: 310px;
    display: flex;
    gap: 32px;
    flex-direction: column;
    text-align: center;
    background-color: var(--white);
    z-index: 2;

    @include media(mobile) {
      width: calc(var(--wh, 1vw) * 90);
    }

    &__text {
      display: flex;
      align-content: center;
      flex-direction: column;
      gap: 16px;

      h4 {
        @include h2;
      }

      p {
        @include bodySmall;
      }
    }


    &__closeButton {
      position: absolute;
      right: calc(var(--padding) - 8px);
      top: calc(var(--padding) - 8px);
      border: none;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;

      svg {
        width: 20px;
        height: 20px;
        color: var(--grey);
        transition: all easy 0.3s;
      }

      &:hover {
        svg {
          color: var(--dark)
        }
      }
    }

    &__okButton {
      width: 100%;
    }
  }
}