@import "src/styles/helpers";

@mixin desktopFont {
  @include media(desktopMedium) {
    @include bodyMedium;
  }
}

:global {
  .tableRowActionIcon {
    height: 14px;
    width: 14px;
    color: var(--dark);
  }

  .MuiDataGrid-menu {

    .MuiDivider-root {
      display: none;
    }

    .MuiPaper-rounded {
      border-radius: 8px !important;
    }
  }

  .MuiDataGrid-menuList {
    padding: 0 !important;
    border-radius: 8px !important;
    background: var(--white) !important;
    box-shadow: 0 9px 45px 0 rgba(0, 41, 54, 0.16) !important;

    .MuiMenuItem-gutters {
      padding: 12px;
      min-width: 170px;
      display: grid;
      grid-template-columns: 12px auto;
      gap: 8px !important;
      font-family: Inter, sans-serif;
      @include bodySmall;
      text-overflow: ellipsis;
      border-bottom: 1px solid var(--secondary-100);

      @include desktopFont;

      &:last-of-type {
        border-bottom: none;
      }

      .MuiListItemText-root {
        overflow: hidden;

        .MuiTypography-root {
          font-family: Inter, sans-serif;
          @include bodySmall;
          text-overflow: ellipsis;

          @include desktopFont;
        }
      }

      svg {
        margin-right: 8px;

        path {
          fill: var(--dark);
        }
      }

      &:hover {
        color: var(--dark);
      }

      &[data-value="asc"] {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .MuiDataGrid-filterFormValueInput {
    flex-grow: 1;
  }

  .MuiTooltip-popper {
    .MuiTooltip-tooltip {
      border-radius: 8px;
      background: var(--dark);
      padding: 12px;
    }

    &.MuiTooltip-popperArrow {
      .MuiTooltip-tooltip {
        border-radius: 0 8px 8px 8px !important;
      }

      .MuiTooltip-arrow {
        left: 0 !important;
        top: 2px !important;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 0 16px;
        border-color: transparent transparent transparent var(--dark);
        position: absolute;
        transform: none !important;

        &::before {
          content: none;
        }
      }

      &[data-popper-placement*="top"] {
        .MuiTooltip-tooltip {
          border-radius: 8px 8px 8px 0;
        }

        .MuiTooltip-arrow {
          border-width: 8px 16px 0 0;
          border-color: var(--dark) transparent transparent transparent;
        }
      }

      &[data-popper-placement*="bottom-end"] {
        .MuiTooltip-tooltip {
          border-radius: 8px 0 8px 8px !important;
        }

        .MuiTooltip-arrow {
          left: initial !important;
          right: 0;
          border-width: 0 0 8px 16px;
          border-color: transparent transparent var(--dark) transparent;
        }
      }
    }
  }

  .MuiDataGrid-columnsPanelRow {
    padding-left: 24px !important;
  }

  .MuiDataGrid-panelWrapper {
    .MuiFormControlLabel-labelPlacementEnd {
      margin-right: 0 !important;

      .MuiCheckbox-root {
        padding: 0;
        margin-right: 8px;
      }
    }
  }

  .MuiDataGrid-panel {

    .MuiDataGrid-paper {
      box-shadow: 0 9px 45px 0 rgba(0, 41, 54, 0.16);
      border-radius: 8px;
    }

    .MuiDataGrid-panelHeader {
      display: none;
    }

    .MuiDataGrid-columnsPanel {
      padding: 24px;
      gap: 16px;
      display: flex;
      flex-direction: column;
    }

    .MuiDataGrid-columnsPanelRow {
      .MuiFormControlLabel-label {
        @include bodyMedium;

        @include desktopFont;
      }
    }
  }
}

.table {
  margin-bottom: 24px;
  flex: 1;
  display: grid;
  align-items: center;

  &__loader {
    margin: 0 auto;

  }

  &_loading {
    opacity: 0.5;
    cursor: progress;

    > * {
      pointer-events: none;
    }
  }

  :global {
    .MuiDataGrid-root {
      border: none !important;

      @include media(notDesktop) {
        overflow: auto;
        padding-bottom: 5px;
      }
    }


    .MuiDataGrid-main {

      @include media(notDesktop) {
        width: 1080px;
        overflow: unset;
      }
    }

    .MuiDataGrid-columnHeader {
      padding: 0 12px !important;

      &:focus,
      &:focus-within {
        outline: none !important;
      }

      @include media(notDesktop) {
        &:first-of-type {
          position: sticky;
          left: 0;
          background-color: var(--white);
          z-index: 2;
          box-shadow: -10px 0 2px -2px #ffffff;

          &::before {
            position: absolute;
            left: -10px;
            content: '';
            background-color: var(--white);
            height: 100%;
            width: 10px;
            z-index: 1;
          }

          &::after {
            position: absolute;
            right: -27px;
            bottom: 0;
            width: 28px;
            height: 100%;
            content: '';
            background: linear-gradient(90deg, #0000000f 0%, #d9d9d900 100%);
          }
        }
      }
    }

    .MuiDataGrid-virtualScroller {
      @include media(notDesktop) {
        position: static;
        overflow: unset !important;
      }
    }

    .MuiDataGrid-columnHeaders {
      color: var(--dark);
      font-family: Inter, sans-serif;
      @include subtitleSmall;

      @include desktopFont;


      line-height: 16px !important;
      border: none;

      .MuiDataGrid-columnSeparator {
        display: none;
      }

      @include media(notDesktop) {
        overflow: unset;
      }
    }

    .MuiDataGrid-iconButtonContainer {
      margin-left: 4px;
      visibility: visible;
      width: auto;

      .MuiDataGrid-sortIcon {
        opacity: 0.5 !important;
      }
    }

    .MuiDataGrid-columnHeader--sorted {
      .MuiDataGrid-sortIcon {
        opacity: 1 !important;
      }
    }

    .MuiDataGrid-menuOpen {
      svg {
        fill: var(--primary-500);
      }
    }

    .MuiDataGrid-row {

      &.Mui-hovered,
      &:hover {
        background: var(--secondary-100) !important;

        &:nth-of-type(2n) {
          background: var(--bg-main) !important;
        }
      }

      @include media(notDesktop) {
        .MuiDataGrid-cell {
          background: var(--white);
        }
      }

      &:nth-of-type(2n) {
        background: var(--secondary-100);

        @include media(notDesktop) {
          .MuiDataGrid-cell {
            background: var(--secondary-100);
          }
        }
      }

      & > div {
        border: 1px solid var(--secondary-500);
        border-left: none;
        border-top: none;

        &:nth-of-type(1) {
          border-left: 1px solid var(--secondary-500);
        }
      }

      &:first-of-type {

        & > div {
          border-top: 1px solid var(--secondary-500);

          &:first-of-type {
            border-top-left-radius: 8px;
          }

          &:last-of-type {
            border-top-right-radius: 8px;
          }
        }
      }

      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 1px solid var(--secondary-500);


        & > div {
          &:first-of-type {
            border-bottom-left-radius: 8px;
          }

          &:last-of-type {
            border-bottom-right-radius: 8px;
          }
        }
      }
    }

    .MuiDataGrid-cell {
      padding: 16px 12px !important;
      color: var(--dark);
      font-family: Inter, sans-serif;
      @include bodySmall;
      @include desktopFont;

      &:global {
        &:has(.fullSizeCell) {
          padding: 0 !important;
        }

        &:has(textarea) {

          &:hover {
            background: var(--bg-main);
          }
        }

        &:has(textarea:focus) {
          background: var(--bg-main);
          outline: none;
        }

        &:has(.translationsTimestamp) {
          background: var(--secondary-100);
        }
      }

      @include media(notDesktop) {
        &:first-of-type {
          position: sticky;
          left: 0;
          z-index: 2;
          box-shadow: -10px 0 2px -2px #ffffff;

          &::after {
            position: absolute;
            right: -29px;
            bottom: 0;
            width: 28px;
            height: 100%;
            content: '';
            background: linear-gradient(90deg, #0000000f 0%, #d9d9d900 100%);
          }
        }
      }

      &:focus {
        outline: none !important;
      }

      &[data-field="actions"] {
        padding: 0 !important;

        div,
        button {
          border-radius: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .MuiDataGrid-cellContent {
      word-break: break-word;
      white-space: break-spaces;
    }

    .MuiDataGrid-footerContainer {
      border: none;
    }

    button[role="menuitem"] {
      padding: 0;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    .MuiDataGrid-menuList {
      color: var(--grey);
      text-overflow: ellipsis;

      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      overflow: hidden;

      @include desktopFont;
    }
  }

  &_simple {

    :global {

      .MuiDataGrid-row {

        &.Mui-hovered,
        &:hover {
          background: transparent !important;

          &:nth-of-type(2n) {
            background: transparent !important;
          }
        }

        @include media(notDesktop) {
          .MuiDataGrid-cell {
            background: var(--white);
          }
        }

        &:nth-of-type(2n) {
          background: transparent;

          @include media(notDesktop) {
            .MuiDataGrid-cell {
              background: var(--white);
            }
          }
        }
      }
    }
  }
}

.sortArrowSVG {
  height: 12px;
  width: 12px;
}

.filteredSVG {
  height: 20px;
  width: 20px;

  rect {
    height: 20px;
    width: 20px
  }
}

.menuIcon {
  width: 12px;
  height: 13px;
  color: var(--dark);
}

.pagination {
  margin: 24px auto 0;

  :global {

    .MuiButtonBase-root.MuiPaginationItem-root {

      &.Mui-selected {
        background: var(--primary-500) !important;
      }
    }
  }

  ul {
    justify-content: center;

    li {
      &:last-of-type {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__item {
    margin: 0 4px !important;
    width: 32px !important;
    height: 32px !important;
    padding: 10px !important;
    border-radius: 4px !important;
    color: var(--dark) !important;
    text-align: center !important;
    font-family: Inter, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    border: 1px solid var(--bg-main) !important;

    // @include desktopFont with important;
    @include media(desktopMedium) {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    svg {
      height: 12px;
      width: 14px;
    }
  }

  :global {
    .Mui-selected {
      color: var(--white) !important;
    }

    .MuiPaginationItem-ellipsis {
      border: none !important;
    }
  }
}

.clippedCell {
  word-break: break-word;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.filter {
  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 4px;
  }

  &__title {
    color: var(--grey);
    @include bodySmall;

    @include desktopFont;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__inputGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }

  &__search {
    position: relative;
  }

  &__input {
    height: 36px;
    width: 100%;

    :global {
      .MuiInputBase-root {
        height: 36px;

        .MuiInputBase-input {
          padding-right: 0 !important;
          padding-left: 10px !important;
          @include bodySmall;

          @include desktopFont;
        }
      }


      .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        transform: translate(10px, 8px) scale(1);
      }

      .MuiButtonBase-root {
        padding: 4px !important;
      }
    }

    &_search {
      height: 40px;

      &:before {
        content: '';
      }

      :global {
        .MuiInputBase-root {
          height: 40px;

          .MuiInputBase-input {
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 36px !important;
            @include bodySmall;

            @include desktopFont;
          }
        }
      }
    }
  }

  &__searchIcon {
    position: absolute;
    left: 12px;
    top: 9px;
    height: 15px;
    width: 15px;
    color: var(--grey);
    z-index: 2;
  }

  &__list {
    padding-left: 11px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @include bodySmall;
    color: var(--dark);
    max-height: 150px;
    overflow-y: auto;

    @include desktopFont;

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__search {
    height: 32px;

    :global {
      .MuiInputBase-root {
        height: 32px;
      }

      .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        transform: translate(14px, 5px) scale(1);
      }
    }
  }

  &__clearBtn {
    width: fit-content;
    @include bodySmall;
    color: var(--primary-500);

    @include desktopFont;

    &:hover {
      color: var(--primary-300);
    }

    &:focus {
      color: var(--primary-600);
    }

    &:active {
      color: var(--primary-800);
    }

    &_small {
      position: absolute;
      top: 9px;
      right: 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 2;

      &:hover,
      &:focus {
        background-color: var(--secondary-100);
      }

      &:active {
        background-color: var(--secondary-200);
      }
    }
  }

  &__clearIcon {
    height: 16px;
    width: 16px;
    color: var(--grey);
  }
}

.columnMenuFilterItem {
  font-size: 40px;
}
