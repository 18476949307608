@import "../../styles/helpers";

.subHeader {
  background: var(--white);
  padding: 16px;
  display: flex;
  align-items: center;

  @include media(mobile) {
    max-width: calc(100 * var(--vw));
    row-gap: 16px;
    flex-wrap: wrap;
  }

  &__base {
    display: flex;
    align-items: center;
  }

  &__back {
    padding: 11px;
    margin-right: 16px;

    svg {
      color: var(--primary-500);
      transform: rotate(90deg);
    }
  }

  &__title {
    @include h3;
  }
}
