@import "src/styles/helpers";

.deliverable {
  max-width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: var(--secondary-200);
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.isRestricted {
    opacity: 0.5;
  }

  &__body {
    flex-grow: 1;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &.warning {

      span:first-of-type,
      .warningArrowSvg {
        color: var(--orange)
      }
    }

    span {
      @include bodyExtraSmall;
      color: var(--grey);
      display: flex;
      align-items: center;
    }

    .divider {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 0 8px;
      background-color: var(--secondary-500);
    }

    svg {
      width: 12px;
      height: 12px;
      color: var(--grey);
    }

    .warningArrowSvg {
      margin-left: 4px;
    }

    .attachSvg {
      margin-right: 2px;
    }
  }

  &__draggable {
    display: flex;
    flex-shrink: 0;
    align-items: center;

    .riskWarning {
      @include bodyExtraSmall;
      color: var(--red);
      display: flex;
      align-items: center;
      min-width: max-content;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 2px;
      }
    }

    button {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px solid var(--secondary-500);
      background-color: var(--white);
      cursor: grab;
      margin-left: 16px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  a {
    @include subtitleSmall;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: stretch;

    @include media(desktopAll) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  a:hover {
    color: var(--primary-500);
    text-decoration: underline;
  }
}

.deliverable.draggingElement {
  button {
    cursor: grabbing;
  }
}

.deliverable:last-of-type {
  margin-bottom: 0;
}

.deliverable.isDragging {
  opacity: 0.5;

  * {
    opacity: 0;
  }
}