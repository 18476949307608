@import "src/styles/helpers";

.modal {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
  }

  h4 {
    margin-bottom: 8px;
    color: var(--dark);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;

    @include h4;
  }

  &__btns {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;

    @include media(mobileSmall) {
      display: flex;
      flex-direction: column;
    }

    button {
      width: 100%;
    }
  }
}

.titleLeft {
  align-self: flex-start;
}

.titleCenter {
  align-self: center;
}

.progressBar {
  width: 148px;
  margin-bottom: 24px;
}

.numberOfSeсonds {

  &__number {
    @include h1;
  }

  &__text {
    @include bodyMedium;
  }
}