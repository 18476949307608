@import "src/styles/helpers";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 8px 0 8px 8px;
  border-radius: 8px;
  @include ease;
  width: 100%;

  &__logo {
    width: 60px;
    height: 60px;
    color: var(--primary-500);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @include media(desktopSmall) {
      width: 40px;
      height: 40px;
    }

    @include media(tablet) {
      width: 40px;
      height: 40px;
    }

    svg,
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__text {
    max-width: 105px;

    h5 {
      max-width: 105px;
      @include subtitle;
      hyphens: auto;
      overflow-wrap: break-word;
      word-break: break-word;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
    }

    p {
      @include bodySmall;
      color: var(--grey);
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    align-self: stretch;
  }

  &__arrow {
    width: 16px;
    height: 16px;
    color: var(--primary-500);
  }

  @include media(notDesktop) {
    gap: 8px;
    justify-content: flex-start;
    max-width: fit-content;
  }
}
.active, .header:hover {
  background: var(--secondary-200);
}

.rotate {
  transform: rotate(180deg);
}

.menu {
  position: absolute;
  width: 280px;
  padding: 16px;
  background: var(--white);
  box-shadow: 0 33px 73.3px 0 rgba(10, 39, 48, 0.12);
  border-radius: 8px;
  left: 214px;
  top: 0;

  h5 {
    @include subtitleSmall;
    color: var(--dark);
    margin-bottom: 8px;
  }

  &__item {
    color: var(--grey);
    padding: 12px;
    margin-top: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    @include ease;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    span {
      @include subtitleSmall;
    }
  }

  &__item:hover {
    background: var(--secondary-200);
    color: var(--dark);
  }

  @include media(notTabletLandscape) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 240px;
    border-radius: 0;
  }
}

.closeMenuButton {
  display: none;
  position: absolute;
  top: 36px;
  right: 32px;
  svg {
    width: 32px;
    height: 32px;
  }

  @include media(notTabletLandscape) {
    display: block;
  }
}

.name {

}
