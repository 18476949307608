@import "src/styles/helpers";

.releaseDrawer {
  max-width: 560px;
  padding-bottom: 24px;

  @include media(mobile) {
    max-width: 390px;
  }

  .releaseDrawerContent {
    overflow: auto;
  }
}

.header {
  position: sticky;
  top: var(--project-edit-notification-height, 0px);
  background-color: var(--white);
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 16px 24px 15px;
  border-bottom: 1px solid var(--secondary-300);
  z-index: 7;

  &_public {
    display: flex;
    align-items: center;
  }

  @include media(notTabletLandscape) {
    padding: 12px 24px 11px;
  }

  @include media(mobile) {
    gap: 24px;
  }

  @include media(mobileSmall) {
    gap: 16px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &__icon {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media(tabletLandscape) {
        width: 40px;
        height: 40px;
      }

      @include media(desktopSmall) {
        width: 40px;
        height: 40px;
      }

      @include media(tablet) {
        width: 40px;
        height: 40px;
      }

      @include media(mobile) {
        width: 40px;
        height: 40px;
      }

      svg,
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__title {
      color: var(--dark);
      @include subtitle;
      text-transform: uppercase;
    }
  }
}

.zeemlessLogo {
  display: inline-block;
  height: 60px;
  color: var(--primary-500);

  @include media(desktopSmall) {
    height: 40px;
  }

  @include media(tablet) {
    height: 40px;
  }

  @include media(mobile) {
    height: 40px;
  }

  @include media(notTabletLandscape) {
    display: none;

    &.visibleOnMobile {
      height: 40px;
      display: inline-block;
    }
  }

  @include media(mobile) {
    height: 40px;
  }
}

.withoutLogo {
  justify-content: flex-end;

  @include media(notTabletLandscape) {
    justify-content: space-between;
  }
}

.menuButton {
  display: none;
  color: var(--dark);

  &:hover {
    color: var(--primary-500);
  }

  &_close {
    width: 32px;
    height: 32px;

    &:hover {
      color: var(--primary-500);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  svg {
    width: 32px;
    height: 32px;
  }

  @include media(notTabletLandscape) {
    display: block;
  }
}

.content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
}

.modal {
  &>div {
    padding: 0;
  }

  .user {
    background: var(--bg-main);
    padding: 24px;
    width: 100%;

    &__placeholder {
      color: var(--primary-500);
    }

    svg {
      width: 48px;
      height: 48px;
      margin-bottom: 12px;
    }

    h5,
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 370px;
    }

    h5 {
      margin-bottom: 2px;
    }

    p {
      @include bodySmall;
      color: var(--grey)
    }
  }

  .info {
    padding: 24px;
  }

  h5 {
    @include h5;
  }

  .buttons {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    margin-top: 16px;

    button {
      width: 100%;
    }
  }
}

.logoutButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: var(--primary-500);
  transition: .3s ease;

  &:hover {
    color: var(--primary-400);
  }
}

.logout {

  @include media(notTabletLandscape) {
    display: none;
  }
}

.mobileMenu {

  &__paper {
    width: 340px;

    &.withLeftMenu {

      @include media(notTabletLandscape) {
        overflow: visible;
        margin-left: 240px;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    gap: 8px;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--primary-500);

    &__image {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img,
    svg {
      max-width: 100%;
      max-height: 100%;
    }

    p {
      color: var(--dark);
      @include subtitle;
      text-transform: uppercase;
    }
  }

  &__user {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    background: var(--secondary-100);
    padding: 16px 24px;

    &__icon {
      width: 32px;
      height: 32px;
      color: var(--primary-500);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      @include subtitleSmall;
      margin-bottom: 2px;
      color: var(--dark);
    }

    &__email {
      @include bodyExtraSmall;
      color: var(--grey);
    }
  }

  &__button {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 12px;
    padding: 16px 24px;
    border-bottom: 1px solid var(--secondary-200);

    svg {
      width: 20px;
      height: 20px;
      color: var(--grey);
    }

    &:hover {

      svg {
        color: var(--primary-500);
      }
    }
  }
}