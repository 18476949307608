@import "src/styles/helpers";

.modal {
  z-index: var(--top-z-index);

  &__content {
    display: grid;
    gap: 24px;
    justify-items: center;
  }

  &__illustration {
    width: 230px;
    height: 205px;
  }

  h4 {
    @include h4;
    margin-bottom: 16px;
    text-align: center;
  }

  &__gateDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--secondary-300);

    &>div {
      margin-right: 8px;
    }

    &_caption {
      @include subtitleSmall;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 161px;
      margin-bottom: 5px;
    }

    span {
      @include bodyExtraSmall;
      color: var(--grey);
      display: flex;
      align-items: center;
    }

    svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  &__buttons {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    button {
      width: 100%;
    }
  }
}