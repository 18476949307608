@import "src/styles/helpers";

.action {
    &__option {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.release__popover {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-50%, 50%);

    &>button {
        padding: 10px;

        &:hover svg {
            color: #005475;
        }
    }
}

.releaseDrawer {
    max-width: 560px;
    padding-bottom: 24px;

    @include media(mobile) {
        max-width: 390px;
    }

    .releaseDrawerContent {
        overflow: auto;
    }
}

.releases {

    .loader {
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    .markedAllButton {
        padding: 10px 12px;

        &>div {
            margin-right: 4px !important;
        }
    }

    .badge {
        background: var(--green);
        color: var(--white);
        border-radius: 4px;
        padding: 2px 4px;
        margin-left: 4px;
        @include bodyExtraSmall();
    }

    &_wrap {
        display: grid;
        grid-template-rows: auto 1fr;
        height: calc(var(--vh, 1vh) * 100 - 92px - 74px - 32px);
        gap: 16px;
        background: var(--white);
        border-radius: 8px;
        padding: 24px;
        margin: 16px;

        @include media(desktopSmall) {
            height: calc(var(--vh, 1vh) * 100 - 74px - 74px - 32px);
        }

        @include media(notTabletLandscape) {
            height: calc(var(--vh, 1vh) * 100 - 66px - 74px - 32px);
        }

        @include media(mobile) {
            padding: 16px;
        }
    }

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            @include h5();
        }
    }

    &_list {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__row {
            position: relative;
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 16px;
            border-radius: 8px;
            border: 1px solid var(--secondary-300);
            background: var(--secondary-100);
            @include ease();
            width: 100%;

            &:not(.readOnly) {
                cursor: pointer;

                &:hover {
                    background: var(--secondary-200);
                }
            }

            &:not(.read, .readOnly) {
                border: 1px solid var(--green);
                padding: 16px 60px 16px 16px;
            }

            &_title {
                color: var(--dark);
                @include subtitle();
            }

            &_description {
                color: var(--grey);
                @include bodySmall();
            }
        }
    }
}