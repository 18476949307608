.buttonGroup {
  width: fit-content !important;
  border-radius: 8px !important;
  padding: 2px !important;
  gap: 4px !important;
  background: var(--secondary-300) !important;

  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }
}

.button {
  border: none !important;
  border-radius: 6px !important;
  background: transparent !important;
  font-size: 10px !important;
  line-height: 12px !important;
  font-family: Inter, sans-serif !important;
  color: var(--dark) !important;
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 8px !important;

  &:hover {
    background: rgba(255, 255, 255, 0.5) !important;
  }

  &:global {

    &.Mui-selected {
      background: var(--white) !important;
    }
  }
}
