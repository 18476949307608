.toggleSwitch {
  flex-shrink: 0;
  width: 44px !important;
  height: 24px !important;
  padding: 0 !important;

  :global {
    .MuiSwitch-switchBase {
      margin: 4px;
      padding: 0 !important;
    }

    .MuiSwitch-track {
      padding: 0;
      border-radius: 12px;
      height: 24px;
      width: 44px;
      background-color: var(--secondary-400);
      opacity: 1 !important;
    }

    .Mui-checked {
      color: var(--white);
      transform: translateX(16px);

      &+.MuiSwitch-track {
        border: 0;
        background-color: var(--primary-500) !important;
        opacity: 1;
      }
    }

    .MuiSwitch-thumb {
      height: 16px;
      width: 16px;
      box-sizing: border-box;
      background: var(--white);
    }
  }
}