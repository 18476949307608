@import 'responsive';
@import 'images';
@import 'typography';
@import 'colors';
@import 'viewportHeight';
@import 'hideScrollbar';
@import 'reset';

@mixin ease {
  transition: all 0.3s ease;
}

@mixin centerFlexContent {
  display: flex;
  align-items: center;
  justify-content: center;
}