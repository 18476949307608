@import "src/styles/helpers";

.loader {
  display: flex;
  justify-content: center;
}

.form {

  &__block {
    display: grid;
    gap: 40px;
  }

  &__subtitle {
    @include h4;
    margin: 32px 0;
  }

  &__twoFA {
    padding: 16px;
    border: 1px solid var(--secondary-300);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media(mobile) {
      gap: 18px;
    }

    @include media(mobile) {
      gap: 6px;
    }

    &__text {
      @include bodyMedium;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &_colored {
        @include bodySmall;
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--green);

        svg {
          height: 15px;
          width: 15px;
          flex-shrink: 0;
        }
      }
    }
  }

  &__footer {
    position: sticky;
    left: 0;
    bottom: 0;
    margin-top: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 32px 0 24px;
    background: var(--white);
    z-index: 1;

    button {
      width: 100%;
    }
  }

  &__resetPassword {
    margin-top: 40px;
    width: 100%;
  }
}

.authDrawer {
  width: 560px;

  @include media(mobile) {
    width: 90%;
  }

  @include media(mobileSmall) {
    width: 100%;
  }

  header>div {
    margin-bottom: 16px;
  }
}

.noPassword {
  border: 1px solid #EAA6A6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  // #EB6060 is var(--red)
  background: rgba(#EB6060, 0.05);
  gap: 48px;

  p {
    @include bodySmall;

    &:nth-child(1) {
      @include subtitle;
      margin-bottom: 8px;
    }
  }

  &__button {
    white-space: nowrap;
  }
}