@import "src/styles/helpers";

.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}

.board {
  background-color: var(--white);
  border-radius: 8px;
  overflow-x: auto;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 80px - 32px - var(--project-summary-header-height) - 16px);
  position: relative;
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  &__add {
    position: fixed;
    top: calc(100% - 68px);
    right: 32px;
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 8px;
    background: var(--primary-500);
    box-shadow: 0 8px 21.5px 0 rgba(0, 50, 67, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 20px;
      height: 20px;
      color: var(--white);
    }
  }
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  svg {
    width: 230px;
    height: 205px;
  }

  p {
    @include bodyMedium;
    margin-top: 24px;
  }
}