@import "src/styles/helpers";

:global {
  .MuiPickersYear-root {
    button {
      text-align: center;
    }
  }

  .MuiPickersPopper-root {

    .MuiPickersYear-yearButton.Mui-selected {
        background: var(--primary-500) !important;
        color: var(--white) !important;
    }

    .MuiButtonBase-root.MuiPickersDay-root {

      &:hover, &:focus {
        background: var(--primary-50);
      }

      &.Mui-selected {
        background: var(--primary-500) !important;
        color: var(--white) !important;
      }
    }

    .MuiPickersDay-today {
      border-color: var(--primary-500) !important;
    }
  }
}

.datePicker {
  width: 100%;

  &.disabled {
    opacity: 0.5;
  }

  :global {

    .MuiInputLabel-root {
      color: var(--grey) !important;

      &.MuiInputLabel-shrink {
        font-weight: 600 !important;
        color: var(--dark) !important;
      }

      &:not(.MuiInputLabel-shrink) {
        left: 3px !important;
        @include bodyMedium;
      }
    }

    .MuiInputBase-root {
      fieldset {
        border-color: var(--secondary-500) !important;
        border-radius: 8px !important;
      }

      &:not(.Mui-disabled):not(.Mui-focused) {

        &:hover {
          fieldset {
            border-color: var(--secondary-700);
          }
        }
      }

      &:focus-within,
      &:focus-visible {
        fieldset {
          border: 1px solid var(--primary-500) !important
        }
      }
    }

    .MuiInputBase-input {
      @include bodyMedium;
      padding-left: 17px !important;
      padding-right: 17px !important;
    }
  }

  &.error {
    :global {
      .MuiInputBase-root {
        fieldset {
          border-color: var(--red) !important;
        }
      }
    }
  }
}

.error {
  @include bodySmall;
  color: var(--red);
  margin-top: 8px;
}
