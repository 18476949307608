@import "src/styles/helpers";

.loader {
  display: flex;
  justify-content: center;
}

.wrap {
  padding: 16px;
  width: 100%;
  height: 100%;
}

.accountSettings {
  background: var(--white);
  padding: 40px 32px 32px;
  max-width: 800px;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  height: 100%;

  &__header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;

    @include media(mobile) {
      flex-direction: column;
      gap: 24px;
      width: 100%;
    }

    h3 {
      @include h3;
    }
  }

  @include media(mobile) {
    &__newPasswordButton {
      width: 100%;
    }
  }
}
