@import "src/styles/helpers";

.paper {
  border-radius: 8px !important;
  margin-left: 0 !important;
}

.trigger {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 4px;
    height: 16px;
    color: var(--primary-500)
  }
}

.menu {
  display: flex;
  flex-direction: column;

  button {
    width: 190px;
    padding: 12px;
    color: var(--grey);
    @include bodySmall;
    display: flex;
    align-items: center;
    @include ease;

    &:hover {
      color: var(--dark);
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
}
