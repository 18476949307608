@import "src/styles/helpers";

$basePercent: 76%;

@keyframes spinner {
  100% {
    transform: rotate(1turn);
  }
}

.loader {
  border-radius: 50%;
  background: conic-gradient(#F0F8FB, #F9FBFC 47%, var(--primary-500));
  -webkit-mask-image: radial-gradient(farthest-side, #0000 $basePercent, #000 calc($basePercent + 1px), #000 0);
  animation: spinner 1s infinite linear;
}
