@import "src/styles/helpers";

.modalRoot {
  width: 100vw;
  @include viewportHeight(100);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1201;
  background: rgba(0, 1, 1, 0.40);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: 370px;
  @include maxViewportHeight(90);
  overflow-y: auto;
  background: var(--white);
  padding: 40px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 29px 129px 0 rgba(0, 41, 54, 0.23);

  @include media(mobileSmall) {
    width: 90%;
  }
}

.closeButton {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
    color: var(--grey)
  }

  &:hover svg {
    color: var(--dark);
  }
}