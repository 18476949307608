.paper {
  background: var(--white) !important;
  box-shadow: 0 9px 45px 0 rgba(0, 41, 54, 0.16) !important;
  overflow: unset !important;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &.left {
    margin-left: -20px !important;

    &:before {
      top: calc(50% - 15px);
      right: -15px;
      border-width: 15px 0 15px 15px;
      border-color: transparent transparent transparent var(--white);
    }
  }

  &.right {
    margin-left: 20px !important;

    &:before {
      top: calc(50% - 15px);
      left: -15px;
      border-width: 15px 15px 15px 0;
      border-color: transparent var(--white) transparent transparent;
    }
  }

  &.top {
    margin-top: -20px !important;

    &:before {
      left: calc(50% - 15px);
      bottom: -15px;
      border-width: 15px 15px 0 15px;
      border-color: var(--white) transparent transparent transparent;
    }
  }

  &.bottom {
    margin-top: 20px !important;

    &:before {
      left: calc(50% - 15px);
      top: -15px;
      border-width: 0 15px 15px 15px;
      border-color: transparent transparent var(--white) transparent;
    }
  }

  &.contextMenu {
    margin-top: 20px;
    margin-left: 22px;

    &:before {
      right: 20px !important;
      left: unset !important;
      top: -15px;
      border-width: 0 15px 15px 15px;
      border-color: transparent transparent var(--white) transparent;
    }

    &.select {
      margin-left: 0;
    }
  }

  &.contextMenuLeft {
    margin-top: 20px;

    &:before {
      left: 20px;
      top: -15px;
      border-width: 0 15px 15px 15px;
      border-color: transparent transparent var(--white) transparent;
    }
  }
}

.button {
  display: block;

  &:disabled {
    cursor: not-allowed;
  }
}
