@import "src/styles/helpers";

.button {
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
  padding: 12px 18px;
  @include ease;
  width: fit-content;
  font-size: 13px;
  line-height: 18px;
  font-family: Inter, sans-serif;

  &:disabled,
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.primary {
    background: var(--primary-500);
    color: var(--white);

    &:hover {
      background: var(--primary-300);
    }

    &:focus {
      background: var(--primary-600);
    }

    &:active {
      background: var(--primary-800);
    }

    &:disabled,
    &.disabled {
      background: var(--primary-100);
      color: var(--white);
    }
  }

  &.secondary {
    background: var(--secondary-100);
    color: var(--primary-500);

    &:hover {
      background: var(--secondary-300);
    }

    &:focus {
      background: var(--secondary-200);
    }

    &:active {
      background: var(--secondary-400);
    }

    &:disabled,
    &.disabled {
      background: var(--secondary-50);
      color: var(--primary-100);
    }
  }

  &.outlined {
    border: 1px solid var(--primary-500);
    background: transparent;
    padding: 11px 18px;
    color: var(--primary-500);

    &:hover {
      border-color: var(--primary-300);
      color: var(--primary-300);
    }

    &:focus {
      border-color: var(--primary-600);
      color: var(--primary-600);
    }

    &:active {
      border-color: var(--primary-800);
      color: var(--primary-800);
    }

    &:disabled,
    &.disabled {
      border-color: var(--primary-100);
      color: var(--primary-100);
      pointer-events: none;
    }
  }

  &.transparent {
    background: transparent;
    color: var(--primary-500);

    &:hover {
      background-color: var(--secondary-500);
    }

    &:focus {
      background-color: var(--secondary-700);

    }

    &:active {
      background: var(--secondary-400);
    }

    &:disabled,
    &.disabled {
      color: var(--primary-100);
      pointer-events: none;
    }
  }

  &.withIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: transform 0.3s ease;
      margin-left: 12px;
      flex-shrink: 0;

      svg,
      img {
        width: 100%;
        height: 100%;
      }

      @keyframes spin {
        to {
          transform: rotate(1turn);
        }
      }

      .spinner {
        width: 16px;
        height: 16px;
        animation: 1s spin linear infinite;
      }
    }

    &_left {
      flex-direction: row-reverse;

      .icon {
        margin-right: 12px;
        margin-left: 0;
      }
    }

    &_center {
      .icon {
        margin-left: 0;
        justify-content: center;
      }
    }
  }

  &.small {
    padding: 10px 12px;
    @include bodyExtraSmall;

    &.withIcon {
      .icon {
        margin-left: 4px;
      }

      &_left {
        flex-direction: row-reverse;

        .icon {
          margin-right: 4px;
          margin-left: 0;
        }
      }

      &_center {
        .icon {
          margin-right: 0;
          margin-left: 0;

          .spinner {
            color: var(--primary-500);
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}