@import "src/styles/helpers";

.optionsGroup {
  width: fit-content !important;
  padding: 4px !important;
  gap: 4px !important;
  background: var(--secondary-200) !important;
  border-radius: 20px !important;
  border: 1px solid transparent;

  &.error {
    border-color: var(--red);
  }

  &.disabled {
    background: var(--secondary-100) !important;
  }
}

.button {
  border: none !important;
  border-radius: 20px !important;
  background: transparent !important;
  font-size: 12px !important;
  line-height: 16px !important;
  font-family: Inter, sans-serif !important;
  color: var(--dark) !important;
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 8px 16px !important;

  &:hover {
    background: rgba(255, 255, 255, 0.5) !important;
  }

  &:global {

    &.Mui-selected {
      background: var(--white) !important;
      box-shadow: 0 2px 7.8px 0 rgba(0, 48, 64, 0.09) !important;
      color: var(--primary-500) !important;
    }
  }

  &:disabled {
    color: var(--grey) !important;
    box-shadow: none !important;
  }
}
p.error {
  @include bodySmall;
  color: var(--red);
  margin-top: 8px;
}
