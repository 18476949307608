.button {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: var(--primary-500);

  svg {
    width: 100%;
    height: 100%;
  }
}

.popoverPaper {
  min-width: 150px;
}

.option {
  padding: 12px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;

  &_withIcon {
    width: unset;
    min-width: 150px;
    display: flex;
    gap: 8px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--secondary-200);
  }

  &:first-of-type {
    border-radius: 4px 4px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    color: var(--dark);
    background: var(--secondary-100);
  }
}