@import "src/styles/helpers";

.progress {
  display: flex;
  align-items: center;
  justify-content: center;

  :global {
    .CircularProgressbar-trail {
      stroke: var(--secondary-400) !important;
    }
  }
}