@import "src/styles/helpers";

.list {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-right: 1px solid var(--secondary-300);
  // including the border
  min-width: 381px;
  max-width: 381px;
}

.header {
  padding: 12px 16px;
  border-bottom: 1px solid var(--secondary-300);
  display: flex;
  justify-content: space-between;
  align-self: stretch;

  h5 {
    @include subtitle;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: stretch;

    @include media(desktopAll) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    @include media(mobile) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  &__info {
    margin-top: 8px;
    display: flex;
    align-items: center;

    @include media(mobile) {
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }

    span {
      @include bodyExtraSmall;
      color: var(--grey);
      margin-right: 8px;
      display: flex;
      align-items: center;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-self: flex-start;
    gap: 8px;
    align-content: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .header__buttons_button {
    width: 42px;
    height: 42px;
    padding: 12px;
    background-color: var(--secondary-200);
  }
}

.body {
  padding: 12px;
  height: calc(var(--vh, 1vh) * 100 - 380px);
  overflow-y: auto;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
