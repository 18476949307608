@import "src/styles/helpers";

.menu {
  padding: 8px;

  &__item {
    padding: 12px;
    width: 100%;
    border-bottom: 1px solid var(--secondary-100);
    cursor: pointer;
    display: flex;
    align-items: center;
    @include bodySmall;
    @include ease;

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      background: var(--secondary-200);
      border-radius: 8px;
    }

    svg {
      width: 20px;
      height: 15px;
      margin-right: 11px;
      color: var(--dark);
    }

    &:last-child svg {
      height: 18px;
    }
  }
}

.popover {
  margin-left: 10px;
}

.menuButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: var(--primary-500);
  transition: .3s ease;

  &:hover {
    color: var(--primary-400);
  }
}