@import "src/styles/helpers";

.alertWrapper {
    position: sticky;
    top: 0;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    background-color: var(--secondary-200);
    min-height: 48px;
    z-index: 3;

    @include media(notTabletLandscape) {
        justify-content: flex-start;
        display: grid;
        grid-template-columns: 1fr auto;
    }

    @include media(mobileSmall) {
        padding: 8px 16px;
    }

    &__message {
        @include bodySmall;
        color: var(--dark);
        text-align: center;

        @include media(notTabletLandscape) {
            text-align: start;
        }

        @include media(mobileSmall) {
            font-size: 10px;
        }

        &__user {
            border-radius: 4px;
            border: 1px solid var(--primary-500);
            background: rgba(0, 103, 136, 0.10);
            padding: 2px 4px;
            margin: 0px 8px;
            white-space: nowrap;
        }
    }

    &__button {
        width: 95px;
        height: 32px;
        font-size: 2px;
        padding: 0px 12px;
        @include bodyExtraSmall;

        @include media(notTabletLandscape) {
            margin-left: auto;
        }

        div {
            margin-right: 4px !important;
        }
    }
}