@mixin colors {
  --primary-50: #E0EDF1;
  --primary-100: #B3D1DB;
  --primary-200: #80B3C4;
  --primary-300: #4D95AC;
  --primary-400: #267E9A;
  --primary-500: #006788;
  --primary-600: #005F80;
  --primary-700: #005475;
  --primary-800: #004A6B;
  --primary-900: #003958;

  --secondary-50: #FCFCFC;
  --secondary-100: #F8F9F9;
  --secondary-200: #F4F5F5;
  --secondary-300: #EFF0F0;
  --secondary-400: #EBEDED;
  --secondary-500: #E8EAEA;
  --secondary-600: #E5E7E7;
  --secondary-700: #E2E4E4;
  --secondary-800: #DEE1E1;
  --secondary-900: #D8DBDB;

  --black: #000;
  --dark: #052129;
  --grey: #9FA8AB;
  --white: #FFF;
  --red: #EB6060;
  --red-accent: #ef4545;
  --green: #60C66A;
  --blue: #2E78E6;
  --orange: #F3B216;

  --bg-main: #F5F6F8;
  --outlines-light: #F4F5F5;
  --progress-bar-path: #91C4E6;
}
