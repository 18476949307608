@import "src/styles/helpers";

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 8px 16px;
    @include bodyMedium();
    color: var(--white);
    background: linear-gradient(90deg, var(--primary-700) 0%, var(--primary-300) 100%);

    @media screen and (notDesktop) {
        justify-content: space-between;
    }

    @include media(mobileSmall) {
        @include bodySmall();
    }

    .actionButton {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            background: var(--white);
            height: 1px;
            @include ease;
        }

        &:hover::before {
            background: transparent;
        }
    }

    .closeButton {
        color: var(--white);
        display: flex;
        @include ease;

        &:hover {
            color: var(--primary-100);
        }

        svg {
            width: 16px;
            height: 16px;
            margin-bottom: auto;
        }
    }
}