@import "src/styles/helpers";

.translations {
  min-height: calc(var(--vh, 1vh) * 100);
  background: var(--bg-main);
}

.main {
  padding: 16px;
  margin: 16px;
  border-radius: 8px;
  background: var(--white);
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 76px - 32px);
  overflow-y: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 24px;

    @include media(mobile) {
      flex-direction: column;
      gap: 8px;
    }

    &__controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;


      @include media(mobile) {
        width: 100%;
        gap: 8px;
      }

      &__item {
        @include media(mobile) {
          width: 100%;
          flex-grow: 1;

          button {
            width: 100%;
          }
        }
      }
    }
  }

  .translationInput {
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    font-family: inherit;
    padding: 16px 12px;
    background: transparent;
    @include bodySmall;

    &:focus {
      outline: none;
    }
  }

  .search {
    position: relative;

    @include media(mobile) {
      width: 100%;
    }

    &__searchIcon {
      left: 12px;
      top: calc(50% - 8px);
      position: absolute;
      width: 16px;
      height: 16px;
      color: var(--grey);

      @include media(mobileSmall) {
        left: 8px;
      }
    }

    &__input {
      border-radius: 8px;
      border: 1px solid var(--secondary-300);
      width: 370px;
      height: 32px;
      @include bodySmall;
      color: var(--dark);
      padding: 4px 12px 4px 36px;

      @include media(mobile) {
        width: 100%;
      }

      @include media(mobileSmall) {
        // padding: 4px 8px 4px 30px;
      }

      &::placeholder {
        @include bodySmall;
        color: var(--grey);
      }

      &:hover {
        border-color: var(--secondary-500);
      }

      &:focus-visible,
      &:active {
        border-color: var(--secondary-500);
        outline: none;
      }
    }
  }
}

.localesList {
  display: grid;
  min-width: 150px;

  .localeItem {
    padding: 12px;
    @include bodyMedium;
    color: var(--grey);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--secondary-200);
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &:hover {
      color: var(--dark);
      background: var(--secondary-100);
    }

    .flagIcon {
      width: 16px;
      height: 16px;
    }
  }
}

.searchFilterIcon {
  width: 16px;
  height: 16px;
}

.contextMenuOption {
  width: 100%;
  padding: 12px;
  @include bodySmall;
  display: flex;
  align-items: center;

  &_danger {
    color: var(--red);
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  @include media(desktopMedium) {
    @include bodyMedium;
  }
}

.deleteModal {

  >div {
    padding: 24px;
  }

  &__title {
    text-align: center;
    @include h4;
    margin: 24px 0;
  }

  &__illustration {
    width: 200px;
    height: 178px;
    margin: 0 auto;
    display: block;
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    button {
      width: 100%;
    }
  }
}

.timestampCell {
  color: var(--grey);

  mark {
    color: var(--dark);
    background: transparent;
  }
}