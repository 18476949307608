@import "src/styles/helpers";

.customerSection {
  border-radius: 8px;
  border: 1px solid var(--secondary-500);
  display: grid;
  gap: 24px;
  padding: 24px;

  &__title {
    @include h5;
  }

  @media screen and (max-width: 1280px) {
    gap: 16px;
    padding: 16px;
  }
}

.aiSection {
  position: relative;

  &__generateBtn {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 2;
  }
}

.checkboxGroup {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 48px;

  @include media(mobile) {
    grid-template-columns: 1fr;
  }
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;

  &> :first-child {
    margin-bottom: 16px;
  }
}

.content {
  position: relative;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

.footer {
  position: sticky;
  left: 0;
  bottom: 0;
  margin-top: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 32px 0 24px;
  background: var(--white);

  &__button {
    width: 100%;
  }
}