@import 'src/styles/helpers';

.taskCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: normal;
  padding-left: 16px;
  @include bodySmall;

  @include media(tabletLandscape) {
    padding-left: 14px;
    line-height: 24px;
  }

  @include media(mobile) {
    padding-left: 14px;
    @include subtitle;
    overflow: hidden;
  }
}

.durationCell {
  @include bodySmall;
  color: var(--dark);
  display: flex;
  align-items: center;
}

:global {
  .MuiAccordionSummary-content {

    :local {

      .taskCell {
        @include h5;

        @include media(mobile) {
          @include subtitle;
        }
      }
    }
  }
}

$subheaderHeight: 76px;
$contentHeaderHeight: 65px;

.content {
  margin: 16px;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 32px - $subheaderHeight);
  overflow-y: auto;
  border-radius: 8px;
  width: calc(var(--vw, 1vw) * 100 - 32px);

  .header {
    position: sticky;
    top: 0;
    z-index: 1;

    &__content {
      background: var(--white);
      border-radius: 8px 8px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      border-bottom: 1px solid var(--secondary-300);
    }
  }
}

.tracking {

  &__subheader {
    margin-left: auto;
  }
}

.loader {

  &__wrap {
    height: calc(var(--vh, 1vh) * 100 - var(--header-height) - $subheaderHeight - 32px);
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.treelist {
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - $subheaderHeight - $contentHeaderHeight - 32px);
  overflow-y: auto;
  border-radius: 0 0 8px 8px;

  &.loading {
    opacity: 0.6;
    pointer-events: none;
  }
}

.durationColumn {
  @include bodySmall;
}

.statusUpdate {
  white-space: normal;
  @include bodySmall;

  &__author {
    color: var(--dark);
  }

  &__date {
    color: var(--grey);
    margin-top: 4px;
  }
}

.jiraLink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 5px;
  border: 1px solid var(--secondary-400);
  color: var(--secondary-900);
  border-radius: 4px;
  flex-shrink: 0;
  margin-left: 16px;

  &:hover {
    border-color: var(--primary-300);
  }

  img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    opacity: 0.7;
  }
}

.taskName {
  margin-left: 16px;
  word-break: break-word;

  @include media(tabletLandscape) {
    font-size: 14px;
  }

  @include media(mobile) {
    margin-left: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.taskCellNameDurationMobileWrapper {
  @include media(mobile) {
    margin-left: 8px;
    overflow: hidden;
  }

  .mobileDuration {
    @include bodySmall;
  }
}

.attachButton {
  @include bodyExtraSmall;
  padding: 8px;

  >div {
    margin-right: 4px !important;
  }
}

.attachButtonWithoutText {
  padding: 8px;
  background: transparent !important;
}

.attachmentsCell {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.angleSVG {
  width: 12px;
  height: 12px;
}

.statusButton {
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  @include bodySmall;
  // var(--blue) with 0.07 opacity
  background-color: rgba(46, 120, 230, 0.07);

  &:hover {
    background-color: rgba(46, 120, 230, 0.14);
  }

  &_loading {
    margin: 0 auto;
    pointer-events: none;
    cursor: progress;
  }

  &_grey {
    background: var(--secondary-200);

    &:hover {
      background: var(--secondary-300);
    }
  }

  &_orange {
    background: rgba(208, 140, 38, 0.07);

    &:hover {
      background: rgba(208, 140, 38, 0.14);
    }
  }

  &_green {
    background: rgba(96, 198, 106, 0.07);

    &:hover {
      background: rgba(96, 198, 106, 0.14);
    }
  }

  &_readOnly {
    background: transparent !important;
  }

  &__indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
    background-color: var(--blue);

    .statusButton_grey & {
      background: var(--grey);
    }

    .statusButton_green & {
      background: var(--green);
    }

    .statusButton_orange & {
      background: var(--orange);
    }
  }

  .angleSVG {
    margin-left: auto;
    flex-shrink: 0;
  }
}

.statusItem {
  @include bodySmall;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &__indicator {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%;
    flex-shrink: 0;

    &_green {
      background: var(--green);
    }

    &_orange {
      background: var(--orange);
    }

    &_grey {
      background: var(--grey);
    }
  }
}

.statusPaper,
.statusButton {
  width: 120px;
}

.statusBlock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.statusJiraButton {
  @include bodyExtraSmall;
  padding: 10px 8px;
}

.attachedLinks {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
}

.attachedLink {
  border: 1px solid var(--secondary-500);
  border-radius: 8px;
  @include bodyExtraSmall;
  position: relative;
  height: 32px;
  width: 84px;

  &:hover {

    .attachedLink {

      &__editButton {
        display: block;
      }

      &__link {
        width: 70px;
      }
    }
  }

  &__link {
    padding: 9px 7px;
    display: inline-block;
    color: var(--primary-500);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 84px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  &__editButton {
    display: none;
    width: 12px;
    height: 12px;
    position: absolute;
    right: 4px;
    top: 50%;
    margin-top: -6px;
    color: var(--grey);

    &:hover {
      color: var(--primary-500);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.attachedLinksDropdown {
  width: 270px;
  border-radius: 8px;

  &__padding {
    padding: 16px;
  }

  &__button {
    border: 1px solid var(--secondary-500);
    border-radius: 8px;
    @include bodyExtraSmall;
    position: relative;
    height: 32px;
    width: 104px;
    display: flex;
    align-items: center;
    color: var(--primary-500);
    padding: 0 7px;

    @include media(tabletLandscape) {
      width: 74px;
    }

    &__content {
      margin-top: -4px;
    }

    .angleSVG {
      margin-left: auto;
    }
  }
}

.buttonWrapper {
  padding: 16px;
  border-bottom: 1px solid var(--secondary-200);
}

.reimportButton {
  @include bodyExtraSmall;

  color: var(--primary-500);

  button {
    color: var(--primary-500);
  }

  padding: 14px 8px;

  &.disabled {
    pointer-events: none;
  }

  >div {
    margin-right: 8px !important;
  }

  @keyframes rotate {
    to {
      transform: rotate(360deg)
    }
  }

  .spinnerIcon {
    width: 12px !important;
    height: 12px !important;
    animation: rotate 1s infinite linear;
  }
}

.searchInput {
  border-radius: 8px;
  border: 1px solid var(--secondary-300);
  width: 437px;
  height: 40px;
  @include bodySmall;
  color: var(--dark);
  padding: 4px 43px 4px 40px;

  @include media(mobile) {
    width: 180px;
  }

  @include media(mobileSmall) {
    width: 100px;
    padding: 4px 8px 4px 30px;
  }

  &::placeholder {
    @include bodySmall;
    color: var(--grey);
  }

  &:hover {
    border-color: var(--secondary-500);
  }

  &:focus-visible,
  &:active {
    border-color: var(--secondary-500);
    outline: none;
  }

  &__wrap {
    position: relative;
  }

  &__icon {
    left: 16px;
    top: calc(50% - 8px);
    position: absolute;
    width: 16px;
    height: 16px;
    color: var(--grey);

    @include media(mobileSmall) {
      left: 8px;
    }
  }
}

.downloadButton {
  @include bodySmall;
  padding: 10px 12px;
}

.backButton {
  padding-left: 12px;
  position: relative;
  right: 16px;
  display: flex;
  flex-direction: row;

  svg {
    transform: rotate(90deg);
  }
}

.backButtonWrapper {
  display: flex;
  flex-direction: row;
  gap: 6px;

  .backButtonIcon {
    color: var(--primary-500);
  }
}

.table {
  background: var(--white);
  overflow: hidden;
  margin-bottom: 16px;
  border-radius: 0 0 8px 8px;

  >div.table__row:last-of-type {
    border-bottom: none;
  }

  &__loader {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - var(--header-height));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__row {
    width: 100%;
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 160px 270px 260px 280px;
    padding: 12px 16px;
    border-bottom: 1px solid var(--secondary-400);
    position: relative;

    &_restricted {
      opacity: 0.5;
    }

    @include media(desktopBig) {
      gap: 128px;
      grid-template-columns: 1fr 160px 237px 377px 320px;
    }

    @include media(desktopSmall) {
      gap: 32px;
      grid-template-columns: 1fr 160px 237px 220.5px 220.5px;
    }

    // 1024
    @include media(tabletLandscape) {
      //gap: 32px;
      // we use 24 gap here, as in design there is DD.MM.YY format instead of our DD.MM.YY
      // This affect on duration width (from 120 to 160)
      // So we need to decrease gap for this screen resolution
      gap: 24px;
      grid-template-columns: 1fr 160px 237px 153px 114px;
    }

    @include media(tabletPortrait) {
      gap: 24px;
      grid-template-columns: 1fr 160px 32px 32px;
    }

    @include media(mobile) {
      padding: 12px;
      gap: 8px;
      grid-template-columns: 1fr 32px 32px;
    }

    &:before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      border-bottom: 1px solid var(--secondary-400);
      bottom: -1px;
      right: 100%;
    }

    &__expandArrow {
      width: 18px;
      height: 18px;

      @include media(tabletLandscape) {
        width: 14px;
        height: 14px;
      }

      @include media(mobile) {
        width: 14px;
        height: 14px;
      }
    }
  }

  .additionalSection {
    padding: 12px 16px;
    margin-left: 16px;
    border-bottom: 1px solid var(--secondary-400);
    border-left: 1px solid var(--secondary-400);
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &Item {
      .statusLabel {
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        margin-bottom: 4px;
      }

      &:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px solid var(--secondary-400);
      }

      @include media(tabletPortrait) {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .statusLabel {
          margin: 0;
        }
      }

      @include media(mobile) {
        .statusLabel {
          color: var(--grey);
        }
      }
    }
  }

  &__rowWrapper {
    width: 100%;

    &.withOpenedSection {
      background: var(--secondary-100);
    }
  }

  &__header {
    padding: 16px;
    background: var(--bg-main);

    p {
      @include bodyExtraSmall;
      color: var(--grey);

      &:first-child {
        text-transform: capitalize;
      }

      &:last-child {
        text-align: right;
      }
    }

    .attachmentTitle {
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }

  :global {
    .highlighted {
      // #60C66A is var(--green), but background-color is not working with var(--green)
      background-color: rgba(#60C66A, .5);
      transition: background-color 0.3s;
    }

    .MuiCollapse-root {
      padding-left: 16px;
    }

    .MuiAccordion-root {
      box-shadow: none;

      &:before {
        content: none;
      }
    }

    .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      left: 16px;
      height: 18px;
      transform: rotate(-90deg) !important;

      @include media(tabletPortrait) {
        top: 21px;
      }

      @include media(mobile) {
        left: 12px;
        top: 25px;
      }

      &.Mui-expanded {
        transform: none !important;
      }
    }

    .Mui-expanded,
    .MuiAccordionSummary-root {
      margin: 0 !important;
      min-height: unset !important;
      padding: 0 !important;
    }

    .MuiAccordionSummary-content,
    .MuiAccordionDetails-root {
      margin: 0 !important;
      padding: 0 !important;
    }

    .MuiAccordionSummary-root {
      cursor: default !important;

      &.Mui-focusVisible {
        background: inherit !important;
      }
    }
  }
}

.tableAccordionWrapper {
  border-left: 1px solid var(--secondary-400);
}

.hideOnMobile {
  @include media(mobile) {
    display: none !important;
  }
}

.hideOnTabletPortrait {
  @include media(tabletPortrait) {
    display: none !important;
  }
}

.hideNotMobile {
  @include media(notMobile) {
    display: none !important;
  }
}

.hideNotMobileOrTabletPortrait {
  @include media(desktopAll) {
    display: none !important;
  }

  @include media(tabletLandscape) {
    display: none !important;
  }
}

.showHideLastStatusUpdateMobileButton {
  padding: 8px;
  align-self: center;
  background: transparent !important;
}

.resetToJiraMobileSection {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @include bodySmall;
  color: var(--dark);

  .cancelButton,
  .yesButton {
    width: 100%;
    padding: 10px;
    @include bodyExtraSmall;
  }
}

.mobileTabletActionsDropdown {
  width: 194px;
}

.emptyState {
  $searchBarHeight: 65px;
  $columnHeaderRowHeight: 45px;
  $sectionPaddings: 48px;
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-height) - $subheaderHeight - $searchBarHeight - $columnHeaderRowHeight - $sectionPaddings);
  flex-grow: 1;
  padding: 36px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  text-align: center;

  svg {
    width: 230px;
    height: 206px;
  }

  p {
    @include bodyMedium;
  }
}