@import 'src/styles/helpers';

.heading {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    @include h5();
    color: var(--dark);
  }

  .button {
    font-size: 12px;

    @include media(desktopMedium) {
      @include subtitle;
    }
  }
}


.loader {
  margin: 0 auto;
}

.popover__paper {

  button {
    padding-right: 20px;
    display: flex;
    align-content: center;
    gap: 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.createUserBtn {
  padding: 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--primary-500);
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  background-color: var(--secondary-100);

  &:hover,
  &:focus,
  &:active {
    background-color: var(--secondary-200);
  }

  svg {
    height: 14px;
    width: 14px;
  }

  @at-root button[aria-describedby="simple-popover"] & {
    background-color: var(--secondary-200);
  }
}

.drawer {
  width: 480px;

  @include media(mobile) {
    width: 90%;
  }

  @include media(mobileSmall) {
    width: 100%;
  }
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__uploadArea {
    margin-bottom: 24px;
  }

  &__successMsg {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--green);

    svg {
      width: 16px;
      height: 16px;
    }

    p {
      @include bodySmall;
    }
  }

  &__file {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid transparent;
    width: 432px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    background: var(--bg-main);

    &_error {
      border-color: var(--red);
    }

    @include media(mobile) {
      width: unset;
    }

    &__svg {
      width: 32px;
      height: 32px;
      flex-shrink: 0
    }

    &__text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      overflow: hidden;
    }

    &__title {
      @include subtitleSmall;
      color: var(--dark);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include media(mobile) {
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    &__subtitle {
      @include bodySmall;
      color: var(--grey);

      &_error {
        color: var(--red);
      }
    }

    &__removeBtn,
    &__downloadBtn {
      margin-left: auto;
      cursor: pointer;
      color: var(--grey);
      flex-shrink: 0;
    }

    &__removeBtn {
      width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__error {
    margin: 24px 0;
    padding: 16px;
    border: 1px solid var(--secondary-500);
    border-radius: 8px;
    background: var(--secondary-100);
    display: flex;
    gap: 16px;
    flex-direction: column;

    &__heading {
      @include subtitle;
      display: flex;
      gap: 6px;
      align-items: center;
      color: var(--dark);

      svg {
        width: 16px;
        height: 16px;
        color: var(--red);
      }
    }

    &__cell {
      border: 1px solid var(--red);
      padding: 2px 4px;
      border-radius: 4px;
      @include bodyMedium;
      color: var(--dark);
      background-color: rgba(235, 96, 96, 0.1);

      &_email {
        border-color: var(--blue);
        background: #2E78E61A;
      }
    }

    &__item {
      margin-bottom: 8px;
      @include bodyMedium;
    }
  }

  &__uploadAgainBtn {
    width: 100%;
  }

  footer {
    position: sticky;
    left: 0;
    bottom: 0;
    margin-top: auto;
    padding: 32px 0 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    background: var(--white);
    z-index: 1;

    button {
      width: 100%;
    }
  }
}