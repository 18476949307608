@import "src/styles/helpers";

$success: #158F4E;
$fail: #D80027;

.ci {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;

  &__content {
    display: grid;
    gap: 32px;
    grid-template-rows: auto;
  }

  &__footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    position: sticky;
    bottom: 0;
    padding: 16px 0 24px;

    .footerButton {
      width: 100%;
    }
  }
}

.picker {
  box-shadow: 0 9px 45px 0 rgba(0, 41, 54, 0.16);
  border-radius: 8px;
  width: 320px;

  &:before {
    display: none !important;
  }

  &__container {

    > button {
      width: 100%;
    }
  }

  :global {
    .chrome-picker {
      overflow: hidden !important;
      font-family: Inter, sans-serif !important;
      box-shadow: none !important;
      padding: 16px !important;
      width: calc(100% - 32px) !important;
      border-radius: 0 !important;
    }
  }

  .contrast {

    &__preview {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__indicators {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &__indicator {
      @include bodyMedium;
      padding: 3px 8px;
      border-radius: 100px;
      color: var(--grey);
      border: 1px solid var(--secondary-500);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }

      &.failed {
        background: $fail;
        border-color: $fail;
        color: var(--white);
      }

      &.passed {
        background: $success;
        border-color: $success;
        color: var(--white);
      }
    }

    &__box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__ratio {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;

      &__title {
        @include subtitleSmall;
        margin-bottom: 4px;
        color: var(--grey);
      }

      &__value {
        @include subtitle;
      }
    }
  }

  &__content {
    padding: 16px;
    border-top: 1px solid var(--secondary-500);
    display: grid;
    gap: 16px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    color: var(--grey);
    @include bodyMedium;

    .infoIcon {
      width: 20px;
      height: 20px;
    }
  }
}

.currentColor {
  border-radius: 8px;
  border: 1px solid var(--secondary-500);
  padding: 27px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  @include bodyMedium;
  width: 100%;

  &:hover {
    border-color: var(--grey);
  }

  &__preview {
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
}

.section {

  &__header {
    margin-bottom: 16px;
    display: grid;
    align-items: center;
  grid-template-columns: 1fr auto;
    gap: 16px;
  }

  &__title {
    @include h5;
    margin-bottom: 4px;
  }

  &__text {
    @include bodyExtraSmall;
    color: var(--grey);
  }
}

.logoPreview {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 16px;

  &.hidden {
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  &__default {
    width: 100%;
    height: 100%;
    color: var(--primary-500);
  }

  &__image {
    position: relative;
    width: 64px;
    height: 64px;
    padding: 11px;
    border-radius: 12px;
    border: 1px solid var(--secondary-500);
    @include imgContain;
  }

  &__filename {
    @include subtitleSmall;
  }
}

.dragNdrop {
  border-radius: 8px;
  border: 1px dashed var(--secondary-500);
  padding: 15px 23px;
  text-align: center;
  cursor: pointer;

  &:hover {
    border-color: var(--grey);
  }

  &.accepted {
    border-color: var(--green);
  }

  &.rejected {
    border-color: var(--red);
  }

  &__title {
    @include h5;
    margin-bottom: 8px;
  }

  &__subtitle {
    @include bodyMedium;
    color: var(--grey);
    margin-bottom: 16px;
  }

  &__text {
    color: var(--grey);
    @include bodyExtraSmall;

    &.error {
      color: var(--red);
    }
  }
}

.colorPicker {
  padding: 16px;
  position: relative;

  &__preloader {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.showHex {

    :global {
      .rcp-field:has(input#rgb), .rcp-field:has(input#hsv) {
        display: none;
      }
    }
  }

  &.showRgb {
    :global {
      .rcp-field:has(input#hex), .rcp-field:has(input#hsv) {
        display: none;
      }
    }
  }

  &.showHsv {
    :global {
      .rcp-field:has(input#hex), .rcp-field:has(input#rgb) {
        display: none;
      }
    }
  }

  :global {
    .rcp {
      background: var(--white);
    }

    .rcp-saturation {
      border-radius: 8px;
    }

    .rcp-body {
      border-top: 1px solid var(--secondary-500);
      padding: 16px 0 0;
      margin-top: 16px;
    }

    .rcp-field-label {
      display: none;
    }

    .rcp-fields-floor {
      grid-template-columns: 1fr;
    }

    .rcp-fields {
      gap: 0;
      padding-left: calc(75px + 16px);
      padding-right: calc(36px + 16px);

      input {
        border: 1px solid var(--secondary-500);
        color: var(--dark);
        font-family: Inter, sans-serif;
        border-radius: 8px;
        padding: 8px 12px;
        height: 36px;
      }
    }

    .rcp-field-label {
      margin-top: 4px;
      color: var(--grey);
      @include bodySmall;
    }
  }
}

.colorTypeSelect {
  position: absolute;
  bottom: 16px;
  left: 16px;

  &__select {
    width: 75px;
  }

  &__tile {
    height: 36px;

    :global {

      .MuiSelect-select {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }
    }
  }
}

.copyButton {
  color: var(--dark) !important;
  width: 36px;
  height: 36px;
  padding: 10px;
  position: absolute;
  bottom: 16px;
  right: 16px;

  svg {
    width: 16px;
    height: 16px;
  }
}
