@import "src/styles/helpers";

.autocomplete {
  width: 100%;
  position: relative;
  display: flex;

  &>button {
    width: 100%;
  }

  .input {
    border: 1px solid var(--secondary-500);
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    display: flex;
    align-items: center;
    height: 56px;

    svg {
      position: absolute;
      width: 16px;
      height: 16px;
      color: var(--grey);
    }

    .closeIcon {
      right: 44px;
    }

    .angleIcon {
      right: 18px;
    }
  }

  .label {
    color: var(--grey);
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    padding: 2px 5px 0;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(14px, 16px) scale(1);
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
    background-color: white;

    &:not(.activeLabel) {
      @include bodyMedium;
    }
  }

  .activeLabel {
    padding: 0 5px;
    transform: translate(9px, -9px) scale(0.75);
    color: var(--dark);
    font-weight: 600;
  }
}

.autocomplete:hover {
  .input {
    border-color: var(--secondary-700);
  }
}

.autocomplete.active {
  .input {
    border-color: var(--secondary-500);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .angleIcon {
      transform: rotate(180deg);
    }
  }
}

.autocomplete.selected {
  .input {
    height: 70px;
  }
}

.autocomplete.error {
  .input {
    border-color: var(--red);
  }
}

.autocomplete.disabled {
  opacity: 0.5;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: var(--white);
  border: 1px solid var(--secondary-500);
  border-top: 0;
  border-radius: 0 0 8px 8px;

  &__search {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    position: relative;

    svg {
      position: absolute;
      width: 16px;
      height: 16px;
    }

    input {
      border: none;
      width: 100%;
      height: 32px;
      padding-left: 24px;

      &:focus-visible {
        outline: none;
      }
    }
  }

  &__items {
    border-top: 1px solid var(--secondary-500);
    display: flex;
    flex-direction: column;
    max-height: 257px;
    overflow-y: auto;
  }
}

.item {
  padding: 16px;
  cursor: pointer;
  display: flex;
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  width: 100%;

  .itemText {
    width: 100%;
  }

  &:hover:not(.selected) {
    background-color: var(--secondary-100);
  }

  p {
    @include bodySmall;
  }

  .title {
    color: var(--grey);
  }

  svg {
    width: 14px;
    height: 16px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--green);
  }
}
.item.selected {
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
}
.item.inactive {
  p, .title {
    opacity: 0.4;
  }
}

p.error {
  @include bodySmall;
  color: var(--red);
  margin-top: 8px;
}
