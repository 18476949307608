@import "src/styles/helpers";

.wrapper {
  padding: 16px;
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
}
.error {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 8px;
  display: grid;
  gap: 32px;
  justify-content: center;
  align-content: center;
  justify-items: center;

  svg {
    width: 424px;
    height: 264px;

    @include media(mobile) {
      width: 315px;
    }
  }
  h1 {
    @include h1;
  }
  p {
    @include bodyLarge;

    @include media(mobile) {
      text-align: center;
    }
  }
  button {
    width: 140px;
  }
}
