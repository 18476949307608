@import "src/styles/helpers";

.routeWrapper {
  padding: 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--secondary-300);

  .routeWrapperBtn {
    display: flex;
    width: 42px;
    height: 42px;
    min-width: 0;
    margin-right: 16px;
    border-radius: 8px;
    background-color: var(--outlines-light);

    svg {
      width: 10px;
      height: 15px;
    }
  }
}

.routeTitle {
  color: var(--dark);
  font-weight: 700;
  font-size: 21px;
}