@import "src/styles/helpers";

.errorTooltip {
  &__label {
    margin-left: 4px;
  }
}

.loader {
  @include centerFlexContent;
}

.statusLabel {
  @include media(desktopMedium) {
    @include bodyMedium;
  }
}

.drawer {
  max-width: 480px;
}