@import "src/styles/helpers";

.tabs {
  border-bottom: 1px solid var(--secondary-300);
  background-color: var(--white);
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.links {
  display: flex;
  gap: 24px;

  &__item {
    display: flex;
    align-items: center;
    padding: 24px 8px 14px 8px;
    color: var(--grey);
    @include ease;
    border-bottom: 2px solid transparent;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    p {
      @include subtitleSmall;

      @include media(desktopBig) {
        @include subtitle;
      }
    }

    &.active,
    &:hover {
      color: var(--primary-500);
      border-color: var(--primary-500);
    }
  }
}