@import "src/styles/helpers";

.header {
  background: var(--white);
  padding: 12px 16px;
  border-radius: 8px;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
  gap: 48px;

  @include media(mobile) {
    border-radius: 8px 8px 0 0;
  }
}

.name {
  @include bodySmall;
  color: var(--grey);
  margin-bottom: 4px;
}

.title {
  @include h4;
}
