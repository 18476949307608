@import "src/styles/helpers";

.projectRisksTableWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .projectRisksTable {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--secondary-400);
    width: 100%;

    &__header {
      display: flex;
      align-items: flex-start;
      background: var(--secondary-100);

      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--grey);
        @include bodySmall;

        &>svg {
          width: 16px;
          height: 16px;
          color: var(--primary-500);
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .row {
      display: grid;
      grid-template-columns: 40px minmax(0, 320px) repeat(2, minmax(0, 1fr)) minmax(0, 168px) minmax(0, 144px) 44px;
      border-bottom: 1px solid var(--secondary-400);

      @include media(landscape) {
        grid-template-columns: 40px repeat(3, minmax(0, 1fr)) minmax(0, 168px) minmax(0, 144px) 44px;
      }

      @include media(tabletPortrait) {
        grid-template-columns: 40px repeat(3, minmax(0, 1fr)) 44px;
      }

      @include media(mobile) {
        padding-bottom: 7px;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    .item {
      padding: 16px 12px;

      @include media(tablet) {
        padding: 9px 16px;
      }

      @include media(mobile) {
        padding: 9px 16px;
      }

      &:last-child {
        border-right: none;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;

      .item {
        display: grid;
        grid-template-columns: minmax(0, 1fr) 20px;
        align-items: center;

        gap: 4px;
        width: 100%;
        border-right: 1px solid var(--secondary-400);
        color: var(--dark);
        @include bodyMedium;

        @include media(tablet) {
          border: none;
        }

        @include media(mobile) {
          border: none;
        }

        .text {
          color: var(--dark);
          @include bodyMedium;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:nth-child(2) {
          .text {
            font-weight: 600;
          }
        }

        &:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .hoverElement {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          border-radius: 4px;
          border: 1px solid var(--primary-100);
          background: var(--primary-50);
          @include ease;

          &:hover {
            background: var(--primary-100);
          }
        }
      }
    }

    .switchWrapper {
      display: flex;
      align-items: center;
      gap: 8px;

      &>label {
        color: var(--grey);
        @include bodySmall;

        @include media(tablet) {
          display: none;
        }

        @include media(mobile) {
          display: none;
        }
      }
    }

    &_editMode {
      @include media(mobile) {
        border: none;
      }

      .row {
        grid-template-columns: 40px minmax(0, 320px) repeat(2, minmax(0, 1fr)) 172px 185px 130px;

        @include media(tablet) {
          grid-template-columns: 140px 1fr 1fr 140px;
          padding-bottom: 12px;
        }

        @include media(mobile) {
          grid-template-columns: 140px 1fr 1fr 140px;
        }

        .input {
          border: none;
          height: 100%;
          width: 100%;



          @include media(mobile) {
            @include bodySmall;
          }

          &:focus {
            outline: none;
          }

          &:focus {
            outline: none;
          }

          &.error {

            &::placeholder {
              color: var(--red);
            }
          }
        }
      }

      .projectRisksTable__header {
        @include media(tablet) {
          display: none;
        }

        @include media(mobile) {
          display: none;
        }
      }

      .item {

        &__cell {
          border-right: 1px solid var(--secondary-400);

          &>div {
            height: 100%;
          }

          .datepicker {
            height: 100%;

            @include media(tablet) {
              height: 32px;
            }

            @include media(mobile) {
              height: 32px;
            }

            fieldset {
              border: none !important;
            }

            :global {

              .MuiInputLabel-shrink {
                display: none !important;
              }

              .MuiInputLabel-root {
                top: 2px !important;
                left: -2px !important;

                @include media(tablet) {
                  top: -10px !important;
                  left: 2px !important;
                }

                @include media(mobile) {
                  font-weight: 500 !important; // bodySmall
                  font-size: 12px !important; // bodySmall
                  line-height: 16px !important; // bodySmall
                  top: -8px !important;
                  left: 2px !important;
                }
              }

              .MuiInputBase-root {
                height: 100%;

                input {
                  padding-left: 16px !important;
                  padding-right: 0 !important;

                  @include media(tablet) {
                    padding: 0 !important;
                  }

                  @include media(mobile) {
                    font-weight: 500 !important; // bodySmall
                    font-size: 12px !important; // bodySmall
                    line-height: 16px !important; // bodySmall
                    padding: 0 !important;
                  }
                }

              }
            }

            &:global {

              &.field-error {

                input {
                  color: var(--red) !important;
                }

                .MuiInputLabel-root {
                  color: var(--red) !important;
                }
              }
            }

            &.error {
              //border-color: var(--red) !important;
            }
          }

          &_owner {
            padding: 16px 12px;
          }

          &__inputWrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            max-height: 32px;
          }

          &__dueDate {
            display: flex;
            align-items: center;
            gap: 8px;

            &_error {
              padding-left: 16px !important;

              :global {
                .MuiInputBase-root.Mui-error {
                  input {
                    padding-left: 0 !important;
                  }
                }
              }
            }
          }
        }

        &__mobileTitle {
          display: none;
          grid-column: 1 / 3;

          .item {
            display: block !important;
            @include bodySmall;
            color: var(--grey);
            padding: 0 0 8px 0;
          }

          @include media(tablet) {
            display: block;
          }

          @include media(mobile) {
            display: block;
          }
        }

        &:last-child {
          .saveMobileHint {
            align-items: center;
            color: var(--grey);
            @include bodySmall;

            svg {
              margin-right: 4px;
              width: 16px;
              height: 16px;
              color: var(--primary-500);
            }
          }

          @include media(tablet) {
            padding-left: 0;
            justify-content: flex-end;
          }

          @include media(mobile) {
            padding-left: 0;
            justify-content: flex-end;
          }
        }

        @include media(tablet) {

          &:first-child {
            display: none;
          }

          &:nth-child(2) {
            order: 1;
            background: var(--secondary-100);
            grid-column: 1 / 4;
          }

          &:nth-child(3) {
            order: 3;
            grid-column: 1 / 5;
          }

          &:nth-child(4) {
            order: 4;
            grid-column: 1 / 5;
          }

          &:has(.switchWrapper) {
            background: var(--secondary-100);
            order: 2;
          }

          &__cell {
            padding-top: 9px;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .item__mobileTitle {
              height: auto;
            }

            &_owner {
              order: 5;
              grid-column: 1 / 3;
              padding: 9px 16px;

              input {
                height: auto !important;
              }
            }

            &__dueDate {
              display: block;
              order: 6;
              grid-column: 3 / 5;
              padding: 9px 16px;
            }
          }
        }

        @include media(mobile) {

          &:first-child {
            display: none;
          }

          &:nth-child(2) {
            order: 1;
            background: var(--secondary-100);
            grid-column: 1 / 4;
          }

          &:nth-child(3) {
            order: 3;
            grid-column: 1 / 5;
          }

          &:nth-child(4) {
            order: 4;
            grid-column: 1 / 5;
          }

          &:has(.switchWrapper) {
            background: var(--secondary-100);
            order: 2;
          }

          &__cell {
            padding-top: 9px;
            border: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .item__mobileTitle {
              height: auto;
            }

            &_owner {
              order: 5;
              grid-column: 1 / 3;
              padding: 9px 16px;


              input {
                height: auto !important;
              }
            }

            &__dueDate {
              display: block;
              order: 6;
              grid-column: 3 / 5;
              padding: 9px 16px;

              button {
                display: none;
              }
            }
          }
        }

        &:not(:first-of-type) {
          .text {

            @include media(mobile) {
              @include bodySmall;
            }
          }
        }
      }
    }
  }
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__title {
    color: var(--grey);
    @include subtitleSmall;
  }

  &__text {
    color: var(--white);
    @include bodySmall;
  }
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  @include bodySmall;

  svg {
    width: 16px;
    height: 16px;
  }

  &_delete {
    color: var(--red);
  }
}

.saveMobileHint {
  display: none;

  @include media(tablet) {
    display: flex;
  }

  @include media(mobile) {
    display: flex;
  }
}