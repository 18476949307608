@import "src/styles/helpers";

.recovery {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__warning {
    padding: 16px;
    border: 1px solid var(--green);
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--secondary-100);

    &__title {
      @include subtitleSmall;
      display: flex;
      gap: 6px;

      svg {
        color: var(--green);
        width: 15px;
        height: 15px;
      }
    }

    &__body {
      @include bodySmall;
    }
  }

  &__code {
    @include bodyMedium;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid var(--secondary-500);
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-100);

    ul,
    li {
      list-style: disc;
    }

    ul {
      padding-left: 24px;
    }

    li {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    margin-top: auto;
    padding: 24px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    background-color: var(--white);

    @include media(mobile) {
      grid-template-columns: 1fr;

      button:first-child {
        order: 2;
      }

      button:last-child {
        order: -1;
      }
    }
  }

  &__btn {
    padding: 12px;
    width: 100%;

    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
}