@import "src/styles/helpers";

.page {
  display: flex;
}

.wrapper {
  width: 100%;
}

.inner {
  background: var(--bg-main);
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 16px;
}

.archiveHeader {
  display: flex;
  padding: 16px;
  gap: 16px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--secondary-300);
  background: var(--white);

  button {
    width: 42px;
  }

  h2 {
    @include h2;
  }
}

.block {
  margin: 0 16px 16px;
  background: var(--white);
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto 1fr;
}

.content {
  padding: 16px;
}