@import "src/styles/helpers";

.heading {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    @include h5;
    color: var(--dark);
  }
}

.loader {
  margin: 0 auto;
}

.drawer {
  max-width: 480px;
}