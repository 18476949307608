@import '/src/styles/helpers.scss';

.projectRisksList {
    display: flex;
    flex-direction: column;
    width: 100%;

    .projectRisksListItem {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        border-top: 1px solid var(--secondary-500);
        border-bottom: 1px solid var(--secondary-500);
        background: var(--white);

        &__header {
            display: flex;
            padding: 12px 16px;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            align-self: stretch;
            background: var(--secondary-100);

            & > p {
                overflow: hidden;
                color: var(--dark);
                text-overflow: ellipsis;
                @include subtitle;
            }
        }


        &__description {
            display: flex;
            padding: 0px 16px 16px 16px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;

            .twoColumns {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;
                width: 100%;
            }

            &__item {
                display: flex;
                height: 66px;
                padding-top: 6px;
                flex-direction: column;
                align-items: flex-start;
                gap: 8px;
                align-self: stretch;

                & > figcaption {
                    @include bodySmall;
                    color: var(--grey);
                }

              &_half {
                height: auto;
              }

                .value {
                    display: grid;
                    grid-template-columns: minmax(0, 1fr) 20px;
                    align-items: flex-end;
                    gap: 8px;

                    & > p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                        color: var(--dark);
                        text-overflow: ellipsis;
                        @include bodySmall;
                    }

                    &_oneLine {
                        &>p {
                            display: block;
                        }
                    }
                }

            }
        }

    }

    nav {
        margin-bottom: 24px;
    }
}

.hoverElement {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    border: 1px solid var(--primary-100);
    background: var(--primary-50);
    @include ease;

    &:hover {
        background: var(--primary-100);
    }
}

.tooltipContent {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__title {
        color: var(--grey);
        @include subtitleSmall;
    }

    &__text {
        color: var(--white);
        @include bodySmall;
    }
}

.option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    @include bodySmall;

    svg {
      width: 16px;
      height: 16px;
    }

    &_delete {
      color: var(--red);
    }
}
