@import "src/styles/helpers";

.drawer {
  width: 480px;
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hint {
  margin-bottom: 20px;

  &__link {
    text-decoration: underline;
    color: var(--primary-500)
  }

  &__icon {
    color: var(--red);
  }
}

.filters {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  @include media(mobile) {
    grid-template-columns: 1fr;
  }

  &>div {
    width: 208px;
  }

  &__filter {
    ul {
      padding: 0;

      li {
        padding: 16px;
        font-size: 14px;
      }
    }
  }
}

.history {
  padding-bottom: 40px;

  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-200);
    padding: 6px 8px 14px 8px;
    margin-bottom: 8px;
  }

  &__item:last-child {
    border-bottom: none;
  }

  &__version {
    @include subtitleSmall;
    color: var(--primary-500);
    margin-right: 4px;
  }

  &__restored {
    @include bodySmall;
    color: var(--grey);
  }

  &__info {
    display: flex;
    margin-top: 4px;

    span {
      @include bodySmall;
    }

    span:first-child {
      margin-right: 4px;
    }

    span:last-child {
      color: var(--grey);
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }

  &__button {
    padding: 8px 16px;
    height: fit-content;
    align-self: center;
    @include bodySmall;
  }
}

.emptyHistory {
  height: calc(var(--vh, 1vh) * 100 - 230px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 230px;
    height: 205px;
    margin-bottom: 40px;
  }
}