@import "src/styles/helpers";

.tabs {
  border-bottom: 1px solid var(--secondary-300);
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 12px 0;
  gap: 12px;
  width: 100%;
}

.navigation__mobile {
    width: 100%;
  
    display: none;
  
    @include media(mobile) {
      display: block;
    }
  
    button { 
      width: 100%;
      color: var(--primary-500);
    }
  
    .popoverButton__content {
      width: 100%;
      display: flex;
      height: 42px;
      padding: 11px 12px;
      align-items: center;
      gap: 8px;
      align-self: stretch;
    
      border-radius: 8px;
      background: var(--secondary-100);
    }
    
    .downloadButton__arrow {
      width: 20px;
      height: 20px;
    
      margin-left: auto;
    }
  }
  
  .popoverOption {
    color: var(--grey);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    padding: 0;
    border-bottom: unset;
  
    position: relative;

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
    }
  
    &::before {
      content: "";
  
      position: absolute;
      top: -12px;
      left: -12px;
  
      height: 44px;
      width: 2px;
  
      background-color: transparent;
    }
  
    &:hover, &:focus {
      color: var(--primary-500);
    }
  
    &.active {
      color: var(--primary-500);

      &::before {
        background-color: var(--primary-500);
      }
    }
  
    .popoverOption__text {
      margin-top: 2px;
    }
  }
  
  .navigation__mobile__paper {
    width: 220px;
    left: 16px;
  }
  
  .popoverOption__icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }