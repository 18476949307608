@import "src/styles/helpers";

.notificationCounter {
    cursor: pointer;
    position: relative;

    &::before {
        content: attr(data-notification-count);
        position: absolute;
        background: var(--red);
        color: var(--white);
        height: 16px;
        min-width: 16px;
        padding: 2px;
        border-radius: 20px;
        top: -4px;
        right: -4px;
        transform: translate(4px, -4px);
        display: flex;
        align-items: center;
        justify-content: center;
        @include bodyExtraSmall();
        text-align: center;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &[data-notification-count^="-"],
    &[data-notification-count="0"]::before {
      content: none;
    }

    .bellSvg {
        cursor: pointer;
        width: 24px;
        height: 24px;
        color: var(--primary-500);
        transition: .3s ease;

        &:hover {
            color: var(--primary-400);
        }
    }

}