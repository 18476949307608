@mixin viewportHeight($height) {
  height: calc(var(--vh, 1vh) * $height);
}

@mixin maxViewportHeight($height) {
  max-height: calc(var(--vh, 1vh) * $height);
}

@mixin minViewportHeight($height) {
  min-height: calc(var(--vh, 1vh) * $height);
}
