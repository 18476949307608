@import "src/styles/helpers";

.header {
  background: var(--white);
  padding: 16px 24px;
  max-height: 80px;
  align-items: center;
  position: relative;
  display: grid;
  grid-template-columns: 42px 1fr 42px;
  gap: 16px;
  max-width: 100vw;

  @include media(notDesktop) {
    padding: 12px 24px;
  }

  @include media(notTabletLandscape) {
    gap: 16px;
  }

  @include media(desktopSmall) {
    padding: 14px 24px;
  }

  @include media(mobile) {
    grid-template-columns: 42px 1fr;

    padding: 12px 16px;
  }
}

.backButton {
  width: 42px;
  height: 42px;
  padding: 12px;
  left: 16px;

  svg {
    transform: rotate(90deg);
  }
}

.tabs {
  display: flex;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    min-width: 800px;

    @include media(notTabletLandscape) {
      gap: 24px;
      justify-content: center;
      flex: 1 0 0;
      width: auto;
    }

    @include media(tabletPortrait) {
      min-width: auto;
    }

    @include media(mobile) {
      min-width: unset;
      gap: 16px;
      width: 100%;
    }

    @include media(mobileSmall) {
      gap: 8px;
    }
  }
}

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @include media(notTabletLandscape) {
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
  }

  @include media(tabletPortrait) {
    gap: 0;
  }

  @include media(mobile) {
    gap: 0;
  }
}

.rectangle {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 2px;
  background: var(--secondary-500);
}

.tab {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  color: var(--grey);
  overflow-x: hidden;
  cursor: pointer;

  @include media(landscape) {
    width: 150px;
    height: 48px;
  }

  @include media(portrait) {
    width: 120px;
    height: 48px;
  }

  @include media(notTabletLandscape) {
    &:not(.summary) {
      justify-content: center;
      align-items: center;
      flex: 1 0 0;
      align-self: stretch;
    }
  }

  &.disabled {
    cursor: default;
  }

  &:not(.disabled):hover {
    color: var(--dark);
  }

  &.active {
    background: var(--secondary-100);
    color: var(--dark);

    .tab__status {

      &__icon {
        color: var(--dark);
      }
    }
  }

  &.summary {
    padding: 15px;

    @include media(tablet) {
      width: 155px;
      padding: 16px;
    }

    &.active {
      border: 1px solid var(--primary-500);
      background: none;
      color: var(--primary-500);
      overflow: hidden;

      svg {
        color: var(--primary-500);
      }
    }

    &.inactive,
    &.disabled {
      color: var(--grey);
    }

    .tab__status {
      width: 16px;
      height: 16px;
    }
  }

  &__status {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
      width: 16px;
      height: 16px;
      color: var(--grey);
    }

    :global {
      .CircularProgressbar-path {
        stroke: var(--green);
      }

      .CircularProgressbar-trail {
        stroke: var(--secondary-300);
      }

      .CircularProgressbar-background {
        fill: transparent;
      }
    }
  }

  &__title {
    @include subtitleSmall;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}