@import "src/styles/helpers";

@mixin preventInputZoom {
  :global {
    .MuiInputBase-input {
      @include media(tablet) {
        font-size: 16px;
      }

      @include media(mobile) {
        font-size: 16px;
      }
    }
  }
}

.selfVoting {
  .header {
    position: sticky;
    top: 0;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 16px 24px 15px;
    border-bottom: 1px solid var(--secondary-300);
    z-index: 7;

    @include media(notTabletLandscape) {
      padding: 12px 24px 11px;
    }

    @include media(mobile) {
      gap: 24px;
    }

    @include media(mobileSmall) {
      gap: 16px;
    }

    &__logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      &__icon {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media(desktopSmall) {
          width: 40px;
          height: 40px;
        }

        @include media(tablet) {
          width: 40px;
          height: 40px;
        }

        svg,
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &__title {
        color: var(--dark);
        @include subtitle;
        text-transform: uppercase;
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  }

  .body {
    background: var(--bg-main);
    min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
    padding-bottom: 16px;
    display: grid;
    grid-template-rows: auto 1fr;

    &__header {
      min-height: 60px;
      display: flex;
      align-items: center;
      width: 100%;
      background-color: var(--bg-main);

      header {
        width: 100%;
        max-width: unset;
      }

      h3 {
        @include h3;
        margin: 0 16px;
      }
    }
  }


  .form {
    height: 100%;

    &__inputs {
      padding: 16px 32px;
      display: flex;
      gap: 16px;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;

      @include preventInputZoom;
    }


    &__textarea {
      height: 170px;
      font-size: 16px;
      @include preventInputZoom;


      :global {
        .MuiInputBase-root {
          padding: 0 !important;
          height: 170px;
          align-items: flex-start;
        }

        .MuiInputBase-root.Mui-error {
          &:after {
            top: 16px
          }
        }
      }
    }

    &__error {
      @include bodySmall;
      color: var(--red);
      margin-top: 8px;
    }
  }

  .nextBtn {
    margin-top: auto;
    padding: 32px 16px 16px;
    background-color: var(--bg-main);
    width: 100%;
    z-index: 3;

    button {
      width: 100%;
    }
  }

  .participant {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .infoGroup {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;

    &__title {
      @include bodySmall;
      color: var(--grey);
    }

    &__value {
      @include subtitle;
      color: var(--dark);
    }
  }

  .step {
    &_first {
      background: white;
      display: flex;
      flex-direction: column;
      display: grid;
      grid-template-rows: auto auto 1fr;

      .heading {
        padding: 16px 32px 8px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        p {
          @include h5;
        }

        span {
          margin-left: unset;
        }

        footer {
          position: sticky;
          bottom: 0;
        }
      }
    }

    &_second {
      padding: 20px 16px;
      border-radius: 8px;
      margin: 16px 16px 0;
      background: var(--white);
      display: grid;
      grid-template-rows: auto 1fr;

      hr {
        border: 1px solid var(--secondary-300);
        margin: 0;
        border-bottom: none;
      }

      .label {
        @include subtitle;
        display: inline-block;
        margin-bottom: 16px;
      }

      footer {
        margin-top: auto;
        padding: 16px 0;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 100%;
        background-color: var(--white);
        position: sticky;
        bottom: 0;

        button {
          width: 100%;
        }
      }
    }
  }

  .radioGroup {
    display: flex;
    gap: 16px;
    flex-direction: column;
    width: 100%;
    background-color: var(--bg-main);
    padding: 16px;
    border-radius: 8px;
  }
}

.successMessage {
  background: var(--bg-main);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(var(--vh, 1vh) * 100);
  padding: 23px;
  text-align: center;

  &__content {
    background-color: var(--white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 24px;

    svg {
      width: 205px;
      height: 205px
    }
  }

  &__title {
    @include h4;
    color: var(--dark);
    margin-bottom: 16px;
  }

  &__subtitle {
    @include bodyMedium;
    color: var(--dark);
    margin-bottom: 8px;
  }
}