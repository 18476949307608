@import "src/styles/helpers";

.areYouStillHereModal {
  width: 420px;
  height: 406px;
  padding: 32px;

  @include media(mobileSmall) {
    width: 374px;
    height: 432px;
    padding: 24px;
  }

  &__content {
    display: flex;
    gap: 8px !important;
  }

  &__message {
    @include bodyMedium;
    color: var(--dark);
    text-align: center;
    margin-bottom: 24px;
  }
}