@import "src/styles/helpers";

@mixin listScrollbarPadding {
  padding-right: 16px;
  margin-right: -16px;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  // 100vh - header - project step navigation height
  // - top and bottom paddings - project summary heading
  // - project summary heading padding
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 80px - 32px - var(--project-summary-header-height) - 16px);
  overflow: auto;

  @include media(desktopBig) {
    gap: 24px;
  }

  @include media(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
    gap: 1px;
    height: unset;
  }
}

.section {
  background: var(--white);
  border-radius: 8px;
  padding: 16px;
  justify-content: center;

  &_validating {
    opacity: 0.7;
    cursor: progress;
  }

  @include media(desktopBig) {
    padding: 24px;
  }

  @include media(mobile) {
    border-radius: 0;
  }

  &_withList {
    padding-bottom: 0;
    min-height: 420px;

    @include media(mobile) {
      min-height: unset;
    }
  }

  &_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}

.loader {
  margin: 0 auto;
}

.parameters {
  grid-column: 1 / 3;

  @include media(tablet) {
    grid-column: unset;
    grid-row: 1 / 3;
  }

  @include media(mobile) {
    grid-row: unset;
    grid-column: unset;
  }

  .loader {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }

  &__content {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include media(desktopBig) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media(tablet) {
      display: flex;
      flex-direction: column;
    }

    @include media(mobile) {
      grid-template-columns: 1fr;
    }
  }

  .parameter {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 4px 12px;
    padding: 8px 0;

    &_wide {
      grid-column: 1 / 3;

      @include media(mobile) {
        grid-column: unset;
      }
    }

    &__icon {
      grid-row: 1 / 3;
    }

    &__value {
      @include h5;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      overflow: hidden;
    }

    &__priorityValue {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__title {
      @include bodySmall;
      color: var(--grey);
    }
  }
}

.descriptionDrawer {
  word-break: break-all;
  max-width: 480px;

  h3 {
    @include caption;
    letter-spacing: 0;
    color: var(--grey);
  }
}

.score {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__comfortScore {
    border: none;

    h4 {
      @include h2;
    }

    p {
      @include bodySmall;
    }
  }

  &__moreButton {
    @include bodySmall;
  }

  &__drawer {
    width: 680px;
    background: var(--bg-main) !important;

    @include media(portrait) {
      width: 602px;
    }

    @include media(mobile) {
      width: 90%;
    }

    @include media(mobileSmall) {
      width: 100%;
    }

    .scores {
      padding-bottom: 24px;
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 16px;
      overflow-y: auto;

      &__top,
      &__bottom {
        border-radius: 8px;
        display: grid;
        gap: 1px;
        overflow: hidden;
      }

      &__top {
        grid-template-columns: 2fr 1fr;

        @include media(mobile) {
          grid-template-columns: 1fr;
        }
      }

      &__bottom {
        grid-template-columns: repeat(3, minmax(0, 1fr));

        @include media(mobile) {
          grid-template-columns: 1fr;
        }
      }

      &__item {
        border: none;
        background-color: var(--white);
      }

      @include media(desktopBig) {
        gap: 24px;
        grid-template-rows: repeat(2, 1fr);
      }
    }
  }
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  @include bodySmall;

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.sectionTitle {
  @include subtitle;
}

.detailsButton {
  @include subtitleSmall;
  font-weight: 500;
}

.list {
  &__header {
    @include bodyExtraSmall;
    position: sticky;
    top: 0;
    color: var(--grey);
    text-align: center;
    background: var(--white);

    &_nowrap {
      white-space: nowrap;
    }
  }

  &__row {
    padding: 12px 0;
    border-bottom: 1px solid var(--secondary-200);
    align-items: center;
    column-gap: 4px;

    &_onLeft {
      text-align: left;
    }

    &_onRight {
      text-align: right;
    }
  }
}

.riskTasks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;

  @include media(mobile) {
    overflow: visible;
  }

  .loader {
    margin: auto;
  }

  .sectionHeader {
    padding: 24px 0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    background: var(--white);
  }

  &__groupTitle {
    @include bodySmall;
    margin: 12px 0 16px;
  }

  &__list {
    @include listScrollbarPadding;
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    flex: 1;
    width: 100%;

    @include media(tablet) {
      padding-bottom: 16px;
    }

    &__header {
      @include bodyExtraSmall;
      position: sticky;
      top: 0;
      background: var(--white);
      color: var(--grey);
      text-align: center;

      &_task {
        text-align: left;
      }
    }

    &__row {
      padding: 12px 0;
      display: grid;
      grid-template-columns: minmax(0, auto) repeat(2, minmax(0, 64px));
      align-items: center;
      column-gap: 4px;
      border-bottom: 1px solid var(--secondary-200);

      &.list__header {
        top: 68px !important;
      }

      .caption {
        @include bodyExtraSmall;
        color: var(--dark);
      }
    }

    header {
      padding: 0 0 8px;

      &::before {
        content: '';
        width: 100%;
        height: 10px;
        position: absolute;
        top: -10px;
        background-color: var(--white);
      }
    }
  }

  &__status {
    @include bodyExtraSmall;
    padding: 4px 12.5px;
    border-radius: 4px;
    text-align: center;

    &_risk {
      background-color: rgba(235, 96, 96, 0.1);
    }

    &_default {
      background-color: rgba(0, 103, 136, 0.1);
    }
  }

  @include media(desktopBig) {
    padding: 0 24px 24px;
  }
}

.deliverables {
  --yellow: #FCC010;
  --light-blue: #46A1E1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .summary {
    padding: 12px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: auto 48px;
    gap: 16px;
    align-items: center;
    background-color: var(--secondary-100);

    &__items {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__item {
      display: flex;
      gap: 12px;
      align-items: center;


      &__title {
        @include bodySmall;
        width: 48px;
        color: var(--grey);
      }

      &__dot {
        border-radius: 50%;
        width: 6px;
        height: 6px;
        display: inline-block;

        &_internal {
          background-color: var(--light-blue);
        }

        &_external {
          background-color: var(--yellow);
        }
      }

      &__percentage {
        @include subtitle;
        color: var(--dark);
      }
    }

    &__diagram {
      :global {
        .CircularProgressbar-trail {
          stroke: var(--yellow);
          stroke-linecap: butt;
        }

        .CircularProgressbar-path {
          stroke: var(--light-blue);
          stroke-linecap: butt;
        }
      }
    }
  }

  &__list {
    padding-bottom: 24px;
    @include listScrollbarPadding;
    overflow: auto;
    flex: 1;

    @include media(tablet) {
      padding-bottom: 16px;
    }

    &__row {
      display: grid;
      grid-template-columns: 3fr 1fr;
      align-items: center;
    }

    header {
      padding: 0 0 8px;
    }

    &__item {
      @include bodySmall;
    }
  }

  &__details {
    &__title {
      @include subtitle;
      margin-bottom: 8px;
    }

    &__list {
      @include listScrollbarPadding;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      padding: 12px;
      background-color: var(--secondary-200);
      align-items: center;

      .flexBlock {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .deliverable {
        @include bodySmall;
      }

      .status {
        @include bodyExtraSmall;
        padding: 8px;
        border-radius: 4px;

        &_external {
          background-color: rgba(147, 170, 255, 0.1);
        }

        &_internal {
          background: rgba(58, 198, 173, 0.1);
        }
      }

      &:nth-child(even) {
        background-color: var(--white);
      }
    }
  }

  &__filter {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 8px;

    &__title {
      @include subtitleSmall;
      color: var(--grey);
    }
  }

  &__drawer {
    width: 600px;

    @include media(mobile) {
      width: 90%;
    }

    @include media(mobileSmall) {
      width: 100%;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    .drawerSettings {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__footer {
    --drawer-padding: 24px;
    position: sticky;
    margin-top: auto;
    bottom: 0;
    box-shadow: 0px 9px 45px 0px rgba(0, 41, 54, 0.14);
    height: 72px;

    &__content {
      padding: 16px 24px;
      position: absolute;
      background-color: var(--white);
      left: calc(0px - var(--drawer-padding));
      width: calc(100% + var(--drawer-padding) * 2);

      display: flex;
      gap: 16px;
      flex-direction: row;

    }
  }

  &__downloadButton {
    @include bodySmall;
    white-space: nowrap;
  }

  &__manageButton {
    width: 100%;
    @include bodySmall;
  }

  &__emptyList {
    height: auto;
    flex-grow: 1;
  }
}

.skills {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__list {
    @include listScrollbarPadding;
    padding-bottom: 24px;
    flex: 1;
    overflow: auto;

    @include media(tablet) {
      padding-bottom: 16px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 8px;
    }

    &__row {
      display: grid;
      grid-template-columns: 3fr 1fr;
      align-items: center;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      @include bodySmall;
    }

    &__deliverables {
      display: flex;
      align-items: center;
      gap: 6px;

      &__chart {
        border-radius: 2px;
        height: 24px;
        min-width: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background-color: var(--primary-500);
      }
    }
  }
}

.stakeholders {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__list {
    @include listScrollbarPadding;
    flex: 1;
    padding-bottom: 24px;
    overflow: auto;

    @include media(tablet) {
      padding-bottom: 16px;
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
    }

    header {
      padding: 0 0 8px;
    }


    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      @include bodySmall;

      &__date {
        padding: 6px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--secondary-100);
        @include bodyExtraSmall;
      }
    }
  }
}

.downloadButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding: 6px;
  border-radius: 8px;
  background-color: var(--secondary-100);

  &__rfp {
    gap: 8px;
  }

  &__wrapper {

    button {
      &:disabled {
        opacity: 0.5;
        cursor: progress;
      }
    }
  }

  &_wide {
    width: 100%;
    border-radius: 0;
    padding: 12px;
    background: var(--white);
    @include bodySmall;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__arrow {
    width: 12px;
    height: 12px;
  }

  &:focus-within,
  &:focus-visible,
  &:hover {
    background-color: var(--secondary-200);
  }

  @include media(desktopAll) {
    gap: 8px;
  }
}

.addons {
  padding: 0;

  @include media(mobile) {
    padding-bottom: 24px;
  }

  :global {
    .MuiTabs-root {
      .MuiTabs-flexContainer {
        justify-content: center;
        border-bottom: 1px solid var(--secondary-300);
        padding: 0 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 !important;
      }
    }
  }

  &__message {
    padding: 16px 6.5px;
    border-radius: 8px;
    align-items: center;
    display: flex;
    justify-content: center;
    @include bodySmall;
    background: var(--secondary-100);
    color: var(--dark);
  }

  &__tools {
    &__button {
      color: var(--dark) !important;
      padding: 16px 6.5px;
      width: 100%;
      @include bodySmall;

      &_disconnect {
        svg {
          color: var(--secondary-900) !important;
        }
      }

      svg {
        height: 24px;
        width: 24px;
      }
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      padding-bottom: 24px;

      button {
        width: 100%;
      }
    }

    &__links {
      padding-bottom: 16px;
      border-bottom: 1px var(--secondary-200) solid;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center
    }

    &__link {
      @include bodySmall;
      text-decoration: underline;
      color: var(--primary-500);

      &:hover,
      &:focus-visible,
      &:focus {
        color: var(--primary-700);
      }
    }

    &__hint {
      margin-top: 24px;
      max-width: 432px;

      @include media(mobile) {
        max-width: 100%;
      }
    }
  }

  &__list {
    margin-top: 32px;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media(tablet) {
      padding: 0 16px;
    }

    &__loader {
      margin: 0 auto;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__title {
      @include bodySmall;
      color: var(--dark);
    }

    &__formats {
      @include bodyExtraSmall;
      color: var(--grey);
    }

    &__extras {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
    }

    &__infoSvg {
      width: 12px;
      height: 12px;
      color: var(--grey);
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    overflow: hidden;
  }

  &__title {
    @include subtitle;
    padding: 16px 16px 0;

    @include media(desktopAll) {
      padding: 24px 24px 0;
    }
  }


  [aria-describedby="simple-popover"] {
    .downloadButton__arrow {
      transform: rotate(180deg);
    }
  }
}

.downloadTaskButton {
  background-color: var(--white);
  border: 1px solid var(--bg-main);
  padding: 7px;
  border-radius: 8px;
  color: var(--primary-400);
  width: 32px;
  height: 32px;

  &:hover {
    border-color: var(--primary-400);
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.rfp {
  --table-template-columns: auto repeat(2, 176px);

  &_loading {
    opacity: 0.5;
    cursor: progress;

    * {
      pointer-events: none;
    }
  }

  @include media(mobile) {
    --table-template-columns: minmax(0, auto) repeat(2, minmax(32px, 64px));
  }

  &__drawer {
    width: 700px;

    @include media(mobile) {
      width: 90%;
    }

    @include media(mobileSmall) {
      width: 100%;
    }
  }

  &__loader {
    margin: 0 auto;
  }

  &__btnsGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    @include media(mobile) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      button {
        width: 100%;
      }
    }
  }

  &__table {
    margin: 24px 0;
    @include resetTable;

    table {
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--secondary-500);
      border-radius: 8px;
    }

    thead {
      width: 100%;
      border-radius: 8px 8px 0px 0px;

      tr {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: var(--table-template-columns);
        background-color: var(--secondary-100);
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid var(--secondary-500);


        td {
          @include bodySmall;
          padding: 8px 12px;

          svg {
            width: 16px;
            height: 16px;
            margin-right: 4px;
            color: var(--primary-500);
            flex-shrink: 0;
          }
        }
      }
    }

    td {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-left: 1px solid var(--secondary-500);

      &:first-of-type {
        border-left: none;
        justify-content: flex-start;
      }
    }

    tbody {
      tr {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: var(--table-template-columns);

        td {
          @include bodySmall;
          padding: 9px 12px;
          height: 100%;
          border-bottom: 1px solid var(--secondary-500);
        }

        &:last-of-type {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }

  &__domainLabel {
    text-align: left !important;
    @include subtitle;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  &__footer {
    width: calc(100% + 48px);
    padding: 16px;
    position: sticky;
    bottom: 0;
    background-color: var(--white);
    box-shadow: 0px -4px 12.7px 0px rgba(0, 32, 42, 0.05);
    margin-left: -24px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 2;
  }

  &__submitSection {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    grid-template-columns: 60px 1fr;
    width: 100%;

    &__error {
      grid-column: 2/3;
      @include bodySmall;
      color: var(--red);
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__switchsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__submitBtn {
    width: 100%;
  }
}

.summaryRisks {
  padding: 8px 16px 16px;
  position: relative;
  overflow: hidden;

  .tabs {
    :global {

      .MuiTabs-flexContainer {
        width: 100%;
        gap: 8px !important;
        top: 0;
        height: 100%;

        &>button {
          padding: 15px 8px 16px 8px !important;
          flex: 1;
        }
      }
    }
  }

  .tabsContent {
    padding: 0;
    height: 100%;
    overflow-y: auto;
    flex: 1;
  }
}

.generalProjectRisks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding: 0;
  height: 100%;

  .loader {
    margin: auto;
  }

  @include media(mobile) {
    overflow: visible;
  }

  .sectionHeader {
    padding: 24px 0 24px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 3;
    background: var(--white);
  }

  &__list {
    @include listScrollbarPadding;
    flex: 1;
    padding-bottom: 24px;
    overflow: auto;

    @include media(tablet) {
      padding-bottom: 16px;
    }

    &__row {
      display: flex;
      align-items: center;
      padding: 12px 0px;
      gap: 12px;
      @include bodySmall;
      border-top: 1px solid var(--outlines-light);

      .order {
        color: var(--grey);
      }

      &:last-child {
        border-bottom: 1px solid var(--outlines-light);
      }
    }
  }

  @include media(desktopBig) {
    padding: 0 24px 24px;
  }
}