@import "../../../../styles/helpers";

.paper {
  margin-left: 14px!important;
  border-radius: 8px!important;
}
.trigger {
  border-radius: 8px;
  background: var(--secondary-200);
  width: 42px;
  height: 42px;
  padding: 12px;
  @include ease;

  &:hover {
    background: var(--secondary-300);
  }

  &:focus {
    background: var(--secondary-200);
  }

  &:active {
    background: var(--secondary-400);
  }

  svg {
    width: 18px;
    height: 18px;
    color: var(--primary-500)
  }
}

.menu {
  display: flex;
  flex-direction: column;

  button {
    width: 190px;
    padding: 12px;
    color: var(--grey);
    @include bodySmall;
    border-bottom: 1px solid var(--secondary-100);
    display: flex;
    align-items: center;
    @include ease;

    &:hover {
      color: var(--dark);
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  button:last-of-type {
    border-bottom: 0;
  }
}
