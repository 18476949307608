@import "src/styles/helpers";

@mixin row {
  padding: 12px 8px 11px;
  align-items: center;
  border-bottom: 1px solid var(--secondary-300);

  @include bodyExtraSmall;

  @include media(mobile) {
    padding: 12px 0;
  }

  &:last-child {
    border-bottom: none;
  }
}

@mixin listHeader {
  position: sticky;
  top: 0;
  padding-top: 24px;
  padding-bottom: 8px;
  color: var(--grey);
  @include bodyExtraSmall;
  background: var(--white);
  z-index: 2;
}

@mixin actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  padding: 8px 0;
  border-radius: 4px;

  &:hover {
    background: var(--secondary-300);
  }
}

@mixin drawerForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__footer {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    padding: 24px 0;
    background: var(--white);
    z-index: 2;
  }
}

.voting {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: space-between;

  &__body {
    max-height: 616px;
    overflow: hidden;
    flex: 1;
    background-color: var(--white);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0 16px;
    display: grid;
    grid-template-columns: auto 480px;

    @include media(desktop) {
      grid-template-columns: auto 320px;
    }

    @include media(desktopSmall) {
      grid-template-columns: auto 320px;
    }

    @include media(tabletLandscape) {
      grid-template-columns: auto 220px;
    }

    @include media(tabletPortrait) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
    }

    @include media(mobile) {
      max-height: unset;
      grid-template-columns: 1fr;
      overflow: unset;
    }

    &_readOnly {
      height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 76px - 76px - 16px);
    }

    &_noContent {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: none;
    }

    &__results {
      h5 {
        @include h5;
        margin-bottom: 40px;
      }

      .chart {
        margin: 0 auto;
        max-width: 240px;
      }
    }
  }

  &__footer {
    display: flex;
    padding: 16px;
    border-radius: 8px;
    gap: 8px;
    justify-content: space-between;
    background-color: var(--white);

    @include media(mobileSmall) {
      flex-direction: column;
    }

    &__btn {
      min-width: 146px;

      @include media(mobile) {
        min-width: 105px;
      }

      @include media(mobileSmall) {
        width: 100%;
      }
    }
  }
}

.loader {
  margin: 300px 0;
  margin: 0 auto;
}

.placeholder {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 24px;
  padding: 24px 0;

  &__img {
    width: 230px;
    height: 205px;
  }

  &__title {
    @include bodyMedium;
    margin-bottom: 16px;
  }
}

.list {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  border-right: 1px solid var(--secondary-300);
  height: inherit;
  overflow: auto;

  @include media(tabletPortrait) {
    border-right: none;
    padding-right: 0;
  }

  @include media(mobile) {
    border-right: unset;
    padding: 0;
  }

  &__loader {
    margin: 0 auto;
  }

  &__body {
    height: 100%;

    @include media(tabletPortrait) {
      height: auto;
    }

    &_validating {
      opacity: .5;
      cursor: progress;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: minmax(145px, 1.5fr) repeat(2, minmax(70px, 1fr)) minmax(290px, 390px) minmax(0, 55px) minmax(0, 24px);
    column-gap: 8px;
    width: 100%;
    @include row;

    &_de {
      grid-template-columns: repeat(3, minmax(70px, 1fr)) minmax(0, 380px) minmax(0, 55px) minmax(0, 24px);
    }

    @include bodyExtraSmall;

    @include media(mobile) {
      grid-template-columns: repeat(2, 1fr) 32px;
      row-gap: 16px;
      column-gap: 12px;
      overflow: hidden;
    }

    .role {
      @include subtitleSmall;

      @include media(mobile) {
        grid-column: 1/3;
      }
    }

    &__name {
      @include media(mobile) {
        grid-column: 1/2;
        grid-row: 2/3;
      }
    }

    &__deputy {
      @include media(mobile) {
        grid-column: 2/4;
        grid-row: 2/3;
      }
    }

    &>div:first-of-type,
    .vote {

      // toggle
      @include media(mobile) {
        grid-column: 1/4;
      }
    }

    .list__actionBtn__wrapper {
      @include media(mobile) {
        grid-column: 3/4;
        grid-row: 1/2;
        margin-right: -10px;
      }
    }

    &__explanation {
      @include media(mobile) {
        grid-column: 3/4;
        grid-row: 2/3;
      }
    }

    &__explanationBtn {
      padding: 0;
      width: 32px;
      height: 32px;
    }
  }

  &__header {
    @include listHeader;

    @include media(mobile) {
      display: none;
    }
  }

  &__actionBtn {
    @include actionButton;

    @include media(mobile) {
      width: 32px;
      height: 32px;
      padding: 8px 3px 8px 14px;
    }
  }
}

.vote {
  --constraint-color: #FFBB18;
  --fail-color: #F99B9B;
  display: flex;
  gap: 8px;
  align-items: center;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 2px;
    flex-shrink: 0;
    background-color: var(--secondary-700);
  }

  &_passed {
    &:before {
      background: var(--green);
    }
  }

  &_constraint {
    &:before {
      background: var(--constraint-color);
    }
  }

  &_fail {
    &:before {
      background: var(--fail-color);
    }
  }

  p {
    @include bodySmall;
    color: var(--dark);
  }
}

.action {
  &__option {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.results {
  height: inherit;
  overflow: auto;

  padding: 24px 12px;

  @include media(mobile) {
    grid-row: -1;
  }

  &__title {
    @include h5;
    padding: 0 12px;

    @include media(mobile) {
      padding: 0 0 16px 0;
    }
  }

  @include media(tabletPortrait) {
    height: auto;
    padding: 16px 0;
    overflow: visible;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      height: 1px;
      bottom: 0;
      left: -16px;
      width: calc(100% + 16px * 2);
      background-color: var(--secondary-300);
    }
  }

  &__body {
    height: 100%;

    @include media(tabletPortrait) {
      padding-top: 16px;
      display: flex;
      gap: 32px;
      align-items: center;
      height: auto;
    }

    @include media(mobile) {
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 32px;
    }
  }


  &__chart {
    width: 100%;
    max-width: 346px;
    max-height: 346px;
    height: 100%;
    margin-bottom: 30px;
    margin: 0 auto;
    transition: all .3s ease;
    flex-shrink: 0;

    @include media(tabletLandscape) {
      max-height: 205px;
    }

    @include media(tabletPortrait) {
      width: 40px;
      height: 40px;
      margin: 0;
    }

    @include media(mobile) {
      width: 40px;
      height: 40px;
      margin: 0;
    }
  }

  &__legend {
    padding: 20px 20px 20px 32px;
    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include media(tabletLandscape) {
      margin-top: 24px;
      padding: 0;
    }

    @include media(tabletPortrait) {
      flex-direction: row;
      padding: 0;
      margin-top: 0;
      gap: 32px;
    }

    @include media(mobile) {
      gap: 8px;
      align-items: flex-start;
      padding: 0;
    }

    .vote {
      &__label {
        color: var(--grey);
      }

      &__percent {
        display: none;
        color: var(--dark);

        @include media(tabletPortrait) {
          display: inline-block;
        }

        @include media(mobile) {
          display: inline-block;
        }
      }
    }
  }
}

.participantForm {
  @include drawerForm;

  &__submitBtn {
    width: 100%;
  }
}

.downloadButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  padding: 12px 18px;
  border-radius: 8px;
  background-color: var(--secondary-100);

  @include media(mobile) {
    padding: 12px;
    gap: 12px;
  }

  @include media(mobileSmall) {
    justify-content: center;
  }

  &__wrapper {
    margin-left: auto;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    color: var(--primary-500);

    @include media(mobileSmall) {
      width: 100%;
    }

    button {
      @include media(mobileSmall) {
        width: 100%;
      }

      &:disabled {
        opacity: 0.5;
        cursor: progress;
      }
    }
  }

  &_wide {
    width: 100%;
    border-radius: 0;
    padding: 12px;
    background: var(--white);
    @include bodySmall;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__arrow {
    width: 18px;
    height: 18px;
  }

  &:focus-within,
  &:focus-visible,
  &:hover {
    background-color: var(--secondary-200);
  }

  @include media(desktopAll) {
    gap: 8px;
  }
}

.selfVoting {
  &__qrSection {
    padding: 64px;
    border: 1px solid var(--secondary-500);
    display: flex;
    justify-content: center;
    border-radius: 8px;
    gap: 16px;
    background-color: var(--secondary-100);
  }

  &__code {
    min-height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 220px;
      max-height: 220px;
      width: 100%;
      aspect-ratio: 1/1;

      path {
        fill: var(--black);
      }
    }

    @include media(mobile) {
      margin: 0 auto;
    }
  }
}