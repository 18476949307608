@import "src/styles/helpers";

.fileInputSection {
  border: 1px dashed var(--secondary-500);
  border-radius: 8px;
  width: 432px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  @include media(mobile) {
    width: unset;
  }

  label {
    padding: 16px 24px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &:hover {
    border-radius: 8px;
    border: 1px dashed var(--grey);
    text-align: center;
    background: var(--bg-main);
  }

  &__svg {
    margin: 0 auto 8px;
    width: 40px;
    height: 40px;
  }

  &__title {
    @include h5;
    margin-bottom: 4px;
    text-align: center;
    color: var(--dark);
  }

  &__subtitle {
    @include bodyMedium;
    margin-bottom: 16px;
    text-align: center;
    color: var(--grey);
  }

  &__error {
    @include bodySmall;
    margin: 4px 0;
    text-align: center;
    color: var(--red);
  }

  &__info {
    @include bodyExtraSmall;
    text-align: center;
    color: var(--grey);
  }
}

.file {
  &__svg {
    margin-bottom: 8px;
    width: 40px;
    height: 40px;
    flex-shrink: 0
  }
}