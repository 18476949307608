@import "src/styles/helpers";

.deliverables {
  display: grid;
  grid-template-columns: 1fr 636px;
  gap: 16px;
  overflow: hidden;
  grid-template-rows: calc(var(--vh, 1vh) * 100 - var(--header-height) - 80px - 32px); // 100 viewport height - header - new project header - paddings;

  @include media(desktopBig) {
    grid-template-columns: 1fr 698px;
    gap: 24px;
    grid-template-rows: calc(var(--vh, 1vh) * 100 - var(--header-height) - 80px - 48px);
  }

  @include media(desktopSmall) {
    grid-template-columns: 1fr 504px;
  }

  @include media(tabletLandscape) {
    grid-template-columns: 1fr 270px;
  }

  @include media(portrait) {
    grid-template-columns: 1fr 250px;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
    grid-template-rows: calc(var(--vh, 1vh) * 100 - var(--header-height) - 80px);
  }

  :global {
    .CircularProgressbar-path {
      stroke: var(--progress-bar-path);
    }

    .CircularProgressbar-trail {
      stroke: var(--secondary-200);
    }
  }

  &__scoreModal {
    background: var(--bg-main) !important;
    padding-bottom: 24px;
  }
}

.section {
  background: var(--white);
  border-radius: 8px;
}

.scores {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 16px;
  overflow-y: auto;
  @include ease;

  &.loading {
    opacity: 0.4;
  }

  @include media(desktopBig) {
    gap: 24px;
    grid-template-rows: repeat(2, 1fr);
  }

  &_top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    @include media(tablet) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    @include media(mobile) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
}

.bottomScores {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include media(desktopSmall) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media(tablet) {
    grid-template-columns: 1fr;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
  }

  .scoreItem {

    @include media(desktopBig) {
      &:not(:nth-child(3n)) {
        border-right: 1px solid var(--bg-main);
      }
    }

    @include media(desktop) {
      &:not(:nth-child(3n)) {
        border-right: 1px solid var(--bg-main);
      }
    }

    @include media(desktopSmall) {
      &:not(:nth-child(2n)) {
        border-right: 1px solid var(--bg-main);
      }
    }
  }
}

.selectDeliverables {
  background: var(--white);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 240px 1fr;
  height: 100%;

  @include media(desktopBig) {
    grid-template-columns: 320px 1fr;
  }

  @include media(desktopSmall) {
    grid-template-columns: 220px 1fr;
  }

  @include media(portrait) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
  }
}

.loader {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media(portrait) {
    grid-column: 1 / 2;
  }
}
