@import "src/styles/helpers";

.emptyList {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  &__icon {
    width: 57px;
    height: 64px;
  }

  &__title {
    @include bodySmall;
    color: var(--grey);
  }
}