.tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabButtons {

  :global {

    .MuiTabs-flexContainer {
      gap: 24px !important;
    }

    .MuiTabs-indicator {
      background: var(--primary-500) !important;
    }
  }
}

.tabButton {
  text-transform: none !important;
  padding: 24px 8px 16px !important;
  font-weight: 600 !important;

  &:global {

    &.MuiTab-root:not(.Mui-selected) {
      color: var(--grey) !important;

      &:hover, &:focus {
        color: var(--primary-500) !important;
      }
    }

    &.Mui-selected {
      color: var(--primary-500) !important;
    }
  }
}
