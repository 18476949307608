.checkbox {
  margin-left: -9px !important;

  :global {
    .MuiTypography-root {
      font-size: inherit !important;
      line-height: inherit !important;
      font-weight: 500 !important;

    }

    .MuiCheckbox-root {
      &.Mui-disabled {
        color: var(--primary-100);

        :local {
          .icon {
            color: var(--primary-100);
          }
        }
      }
    }

    .MuiCheckbox-root {
      &.Mui-disabled {
        color: var(--primary-100);

        :local {
          .icon {
            color: var(--primary-100);
          }
        }
      }
    }
  }
}

.icon {
  color: var(--primary-500);
}