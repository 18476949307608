@import 'src/styles/helpers';

.container {
}

.dropdownItem {
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 12px;
  color: var(--dark);
  @include bodySmall;


  .iconSVG {
    width: 16px;
    height: 16px;
  }

  .itemText {
    flex: 1;
  }

  .nextIconSVG {
    width: 12px;
    height: 12px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--secondary-200);
  }
}