@import "src/styles/helpers";

.settings {
  margin-left: 10px !important;
}

.trigger {
  display: flex;
  padding: 12px;
  border-radius: 8px;
  background: var(--secondary-100);

  &:hover {
    background: var(--secondary-200);
  }

  svg {
    width: 18px;
    height: 18px;
    color: var(--primary-500);
  }
}

.menu {
  &__item {
    padding: 12px;
    min-width: 138px;
    border-bottom: 1px solid var(--secondary-100);
    cursor: pointer;
    display: block;
    @include bodySmall;
    @include ease;

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      background: var(--secondary-100);
    }
  }
}

.modal {
  display: grid;
  justify-items: center;
  gap: 24px;

  h4 {
    @include h4;
    text-align: center;
  }

  &__buttons {
    width: 100%;
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;

    button {
      width: 100%;
    }
  }
}

.drawer {
  position: absolute;
}

.form {
  display: grid;
  align-content: space-between;
  height: 100%;

  button {
    width: 100%;
  }
}

.cIdrawer {
  width: 540px;

  @include media(mobile) {
    width: 90%;
  }

  @include media(mobileSmall) {
    width: 100%;
  }
}
