@import "src/styles/helpers";

.paper {
  min-width: 480px;
  background: var(--white) !important;
  box-shadow: -35px 0px 48.7px 0px rgba(0, 41, 54, 0.10) !important;
  padding: 40px 24px 0 24px;
  position: relative;
  display: grid !important;
  align-items: flex-start;
  grid-template-rows: auto 1fr;

  @include media(mobile) {
    width: 90%;
    min-width: unset;
  }

  @include media(mobileSmall) {
    width: 100%;
  }

  &__close {
    position: absolute;
    z-index: 1;
    right: 14px;
    top: 14px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--grey);

    & > svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      color: var(--dark);
    }
  }

  &__title {
    margin-bottom: 40px;

    h3 {
      @include h3;
      color: var(--dark);
    }

    p {
      @include bodySmall;
      color: var(--grey);
      margin-top: 8px;
    }
  }
}

.drawer {
  :global {

    .MuiBackdrop-root {
      background: rgba(0, 1, 1, 0.20) !important;
    }
  }

  &__content {
    height: 100%;
  }

  .isAbsoluteLoader {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
  }

  &__error {
    width: 100%;
    height: 100%;
    display: grid;
    gap: 24px;
    justify-content: center;
    align-content: center;
    justify-items: center;
    text-align: center;

    svg {
      width: 268px;
      height: 264px;
      margin: 0 auto;
    }

    h3 {
      @include h3;
    }

    p {
      @include bodyMedium;
      max-width: 370px;
      color: var(--grey);
    }
  }
}