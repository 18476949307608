@import "src/styles/helpers";

.score {
  grid-row: 1 / 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px 32px;
  border-right: 1px solid var(--bg-main);

  @include media(desktopBig) {
    justify-content: center;
  }

  @include media(desktopSmall) {
    padding: 24px;
  }

  @include media(mobile) {
    grid-row: unset;
  }

  &__circle {
    width: 144px;
    height: 144px;
    margin: 0 auto;
  }

  &__title {
    margin: 24px 0 12px;
    @include h3;

    @include media(desktopSmall) {
      @include h4;
    }
  }

  &__text {
    @include bodyExtraSmall;
    color: var(--grey);
  }

  &__icon {
    width: 32px;
  }
}
