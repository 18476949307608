@import "src/styles/helpers";

.answerGroup {
  padding: 2px !important;
  border: none !important;
  border-radius: 8px !important;
  gap: 4px !important;
  background: var(--bg-main) !important;
  width: 100% !important;

  @include media(mobile) {
    gap: 2px !important;
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
    text-align: center;
  }

  &__button {
    padding: 6px !important;
    border-radius: 6px !important;
    border: 2px solid transparent !important;
    font-size: 10px !important;
    line-height: 12px !important;
    flex-grow: 1;

    @include media(mobile) {
      width: 100%;
    }

    &:first-child {
      min-width: 82px;
    }

    &:nth-child(3) {
      min-width: 122px;
    }
  }

  :global {
    .Mui-selected {
      color: var(--dark) !important;
      border: 2px solid rgba(239, 240, 240, 1) !important;
      box-shadow: none !important;
    }
  }

  &_pass {
    background-color: rgba(96, 198, 106, 0.12) !important;

    :global {
      .Mui-selected {
        border-color: rgba(96, 198, 106, 0.3) !important;
      }
    }
  }

  &_passWithConstraints {
    background-color: rgba(255, 187, 24, 0.12) !important;

    @include media(mobile) {
      min-width: 136px;
    }

    :global {
      .Mui-selected {
        border-color: rgba(255, 187, 24, 0.3) !important;
      }
    }

  }

  &_fail {
    background-color: rgba(235, 96, 96, 0.12) !important;

    :global {
      .Mui-selected {
        border-color: rgba(235, 96, 96, 0.3) !important;
      }
    }
  }
}