@import "src/styles/helpers";

.input {
  width: 100%;

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  &.disabled {
    opacity: 0.5;
  }

  :global {

    .MuiInputLabel-root {
      color: var(--grey) !important;

      &.MuiInputLabel-shrink {
        font-weight: 600 !important;
        color: var(--dark) !important;

        .MuiInputLabel-asterisk {
          color: var(--red) !important;
        }
      }

      &:not(.MuiInputLabel-shrink) {
        left: 3px !important;
        @include bodyMedium;
        padding-top: 2px;
      }

      &.Mui-error {
        color: var(--grey) !important;

        &.MuiInputLabel-shrink {
          color: var(--dark) !important;
        }
      }
    }

    .MuiInputBase-root {

      .MuiInputBase-input {
        @include bodyMedium;
        padding: 18px;
      }

      fieldset {
        border-color: var(--secondary-500) !important;
        border-radius: 8px !important;
      }

      &:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused) {

        &:hover {

          fieldset {
            border-color: var(--secondary-700) !important;
          }
        }
      }

      &:focus-within,
      &:focus-visible {

        fieldset {
          border: 1px solid var(--primary-500) !important
        }
      }

      &.Mui-error {

        fieldset {
          border-color: var(--red) !important;
        }

        input {
          padding-right: 54px !important;
        }

        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          background: url('../../../public/media/error.svg') no-repeat center / contain;
          right: 17px;
          top: calc(50% - 10px);
        }
      }
    }

    .MuiInputBase-input {
      padding-left: 17px !important;
      padding-right: 17px !important;
    }

    .MuiInputBase-multiline {
      padding: 0;
      padding-right: 17px !important;
    }
  }
}

.error {
  @include bodySmall;
  color: var(--red);
  margin-top: 8px;
}