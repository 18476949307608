@import "src/styles/helpers";

.disableForm {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__subtitle {
    @include bodyMedium;
  }

  &__clue {
    @include bodySmall;
    padding: 16px;
    border: 1px solid var(--secondary-500);
    border-radius: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    background-color: var(--secondary-100);

    svg {
      height: 22px;
      width: 22px;
      color: var(--dark);
    }
  }

  &__btn {
    width: 100%;
  }

  &__footer {
    position: sticky;
    bottom: 0;
    margin-top: auto;
    padding: 24px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    background: var(--white);

    @include media(mobile) {
      grid-template-columns: 1fr;

      button:first-child {
        order: 1;
      }
    }
  }
}