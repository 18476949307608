@import "src/styles/helpers";

.passwordConfig {
  background: var(--bg-main);
  padding: 16px;
  // viewport - (navbar + heading)
  min-height: calc(var(--vh, 1vh) * 100 - (76px + var(--header-height)));

  &_withoutSubHeader {
    min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  }
}

.wrapper {
  background: var(--white);
  padding: 40px 32px 32px;
  max-width: 800px;
  width: 100%;
  margin: auto;
  border-radius: 8px;
}

.confirmation {
  text-align: center;

  &>div {
    padding: 40px 0;
    margin-bottom: 40px;
  }

  .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: var(--bg-main);
    margin: 0 auto 24px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--primary-500);
      width: 32px;
      height: 32px;
    }
  }

  h3 {
    @include h3;
  }

  p {
    @include bodyMedium;
    margin-top: 16px;
  }
}

.form {
  display: grid;
  gap: 32px;

  &>button {
    margin-top: 8px;
  }
}

.button {
  width: 100%;
  display: block;
}