@import "src/styles/helpers";

.wrapper {
  background-color: var(--white);
  border-radius: 8px;
  margin: 0 auto;
  padding: 32px;

  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-width: 800px;

  h3 {
    @include h3;
    margin-bottom: 32px;
  }

  @media screen and (min-width: 1024px) {
    width: 800px;
  }

  @include media(mobile) {
    padding: 24px;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    @include bodySmall;
    margin-top: 8px;
  }
}

.form {
  display: grid;
  gap: 32px;

  &__block {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr;
    position: relative;
  }

  &__divider {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 28px;
    min-width: 8px;
    height: 1px;
    background-color: var(--grey);
  }

  &__customerIcon {
    min-width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-500);
    color: var(--white);
    margin-right: 8px;
    text-transform: uppercase;
    @include bodySmall;

    &.inactive {
      background-color: var(--grey);
    }
  }

  &__cta {
    white-space: nowrap;
  }

  &__buttons {
    display: grid;
    gap: 8px;
    margin-top: 32px;

    button {
      width: 100%;
    }
  }

  &__inputTooltipWrapper {
    display: flex;
    gap: 16px;

    &>div {
      flex-grow: 1;
    }

    svg {
      width: 21px;
      height: 21px;
      top: 18px;
      position: relative;
    }
  }

  &__subtitleBlock {
    display: flex;
    justify-content: space-between;
  }

  &__subtitle {
    @include subtitle;
    font-size: 14px;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  &__selectedDomainsWrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__subtitleButton {
    @include bodyExtraSmall;
    min-width: 95px;
    height: 32px;
    padding: 0px 12px;

    div {
      margin-right: 4px !important;
    }
  }

  &__domainSelect {
    :global {
      .MuiChip-root {
        border-radius: 4px !important;
        background-color: var(--secondary-200) !important;
      }
    }
  }

  &__domainSelectPaper {
    max-height: 440px !important;

    ul {
      padding-top: 0;
    }

    fieldset {
      border: none;
    }

    :global {
      .MuiListSubheader-root {
        padding: 8px 0 6px;
        line-height: 32px;
        border-bottom: 1px solid var(--secondary-500) !important;

        input {
          @include bodySmall;

          &:placeholder {
            color: var(--grey);
          }
        }
      }
    }

    .MuiSvgIcon-root {
      svg path {
        stroke: var(--grey);
      }
    }
  }

  &__menuItem {
    padding-left: 34px !important;
    // @include bodyMedium with !important
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;

    &_groupHeading {
      padding-left: 12px !important;
      border-top: 1px solid var(--secondary-500) !important;
      background: var(--secondary-100) !important;
      // @include subtitle with !important
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    &:nth-child(2) {
      border-top: none !important;
    }
  }

  &__noResults {
    display: flex;
    padding: 24px 0;
    justify-content: center;
    @include subtitle;
    color: var(--grey);
  }

  &__footer {
    margin-top: 32px;
  }
}

$tabsHeight: 32px;

.aiLoadingWrapper {
  background-color: var(--white);
  border-radius: 8px;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - $tabsHeight);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    margin-top: 32px;
    @include bodyMedium;
    color: var(--grey);
    text-align: center;
  }
}

.tooltip {
  width: 256px;

  &__title {
    @include bodyExtraSmall;
    color: var(--grey);
    margin-bottom: 6px;
  }

  &__text {
    @include bodyExtraSmall;
    color: var(--white);
  }
}