@import "src/styles/helpers";

.pagination {
  margin: 24px auto 0;

  :global {

    .MuiButtonBase-root.MuiPaginationItem-root {

      &.Mui-selected {
        background: var(--primary-500) !important;
      }
    }
  }

  ul {
    justify-content: center;

    li {
      &:last-of-type {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__item {
    margin: 0 4px !important;
    width: 32px !important;
    height: 32px !important;
    padding: 10px !important;
    border-radius: 4px !important;
    color: var(--dark) !important;
    text-align: center !important;
    font-family: Inter, sans-serif !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    border: 1px solid var(--bg-main) !important;

    // @include desktopFont with important;
    @include media(desktopMedium) {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
    }

    svg {
      height: 12px;
      width: 14px;
    }
  }

  :global {
    .Mui-selected {
      color: var(--white) !important;
    }

    .MuiPaginationItem-ellipsis {
      border: none !important;
    }
  }
}