@import "src/styles/helpers";

.projectRisks {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .headerActions {
        display: flex;
        align-items: center;
        margin-left: auto;
        gap: 16px;

      &_ai {
        @include media(mobile) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-top: 16px;

          button {
            width: 100%;
          }
        }
      }
    }

    &__content {
        display: flex;
        padding: 24px;
        flex-direction: column;

        align-items: center;
        gap: 16px;
        height: 100%;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 8px;
        background: var(--white);
        margin: 16px;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

          @include media(mobile) {
            display: block;
          }

            .title {
                @include h5;
                color: var(--dark);

              @include media(mobile) {
                margin-bottom: 12px;
              }
            }

          &__buttons {
            display: flex;
            gap: 8px;
            justify-content: flex-end;

            @include media(mobile) {
              flex-direction: column;

              button {
                width: 100%;
              }
            }
          }
        }

        &_mobile {
            padding: 0;
            gap: 0;

            .header {
                padding: 16px;
            }
        }

        &_empty {
            justify-content: center;

            @include media(mobile) {
                justify-content: flex-start;
                padding: 16px;
            }
        }
    }

    &__emptyState {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include media(mobile) {
            height: 100%;
            width: 100%;
        }

        .actions {
            display: flex;
            gap: 16px;

            @include media(mobile) {
                flex-direction: column;
                width: 100%;

                & > button {
                    width: 100%;
                }
            }
        }
    }

  .collapsedSubheader {

    @include media(mobile) {
      display: block;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
}

.aiGeneratedModal {
  width: 100%;
  height: calc(var(--vh, 1vh) * 90);
  background: var(--white);
  border-radius: 8px 8px 0 0;
  animation: appear .4s ease-out forwards;
  bottom: -100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  @keyframes appear {
    to {
      bottom: 0;
    }
  }

  &__wrap {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 8;
    background: rgba(0, 1, 1, 0.20);
    display: flex;
    align-items: flex-end;
    height: calc(var(--vh, 1vh) * 100);
  }

  &__title {
    @include h3;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid var(--secondary-300);

    @include media(mobile) {
      display: block;
    }

    &__actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;

      @include media(mobile) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 16px;

        button {
          width: 100%;
        }
      }
    }
  }

  &__content {
    padding: 24px;
    overflow-y: auto;
    height: calc(100% - 75px); // minus modal header height
  }
}

.drawer {
  max-width: 480px;
}