@import "src/styles/helpers";

.header {
  background-color: var(--white);
  padding: 16px;
  border-bottom: 1px solid var(--secondary-300);
  display: flex;
  justify-content: space-between;
  height: 74px;

  @include media(mobile) {
    max-width: calc(100 * var(--vw));
  }

  &__content {
    display: flex;

    button {
      width: 42px;
      height: 42px;
      padding: 12px;
      margin-right: 16px;

      svg {
        transform: rotate(90deg);
      }
    }

    &_title {
      margin-bottom: 5px;
      display: flex;
      align-items: center;

      @include media(mobile) {
        flex-wrap: wrap;
      }
    }

    h5 {
      @include h5;
      margin-right: 16px;

      @include media(mobile) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
      }
    }

    &__date {
      @include bodyExtraSmall;
      color: var(--grey);
      display: flex;
      align-items: center;

      @include media(mobile) {
        margin-left: 8px;
      }

      svg {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }
}

.content {
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 76px);
  overflow: auto;
}

.tabs {
  position: relative;
  margin: 16px 16px 0;
  padding: 0 16px;
  background: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--secondary-300);
    z-index: 0;
  }

  .castVotesBtn {
    margin-left: auto;

    &_mobile {
      width: 32px;
    }

    svg {
      color: var(--primary-500);
      width: 18px !important;
      height: 18px !important;
    }
  }

  .actionBtns {
    display: flex;
    gap: 8px;
  }

  .addButton {
    &>div {
      margin-right: 8px !important;
    }

    @include media(tabletPortrait) {
      padding: 12px;

      &>div {
        margin-right: 0 !important;
      }

      &__label {
        display: none;
      }
    }

    @include media(mobile) {
      padding: 12px;

      &>div {
        margin-right: 0 !important;
      }

      &__label {
        display: none;
      }
    }
  }
}

.outlet {
  $side-margin: 16px;

  margin: 0 $side-margin;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - 76px - 60px - 32px);
  overflow: auto;

  @include media(mobile) {
    width: calc(100* var(--vw) - $side-margin * 2);
    overflow: visible;
    height: unset;
    padding-bottom: 16px;
  }
}