@import "src/styles/helpers";

.history {
  height: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 24px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: var(--white);
  overflow: auto;

  &_noContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_revalidating {
    opacity: 0.5;
    cursor: progress;

    * {
      pointer-events: none;
    }

  }

  &__group {
    max-width: 800px;
    width: 100%;
    display: grid;
    gap: 16px;
    grid-template-columns: 82px auto;

    &__label {
      display: flex;
      gap: 8px;
    }

    &__date {
      @include bodyExtraSmall;
      height: fit-content;
      padding: 6px;
      border-radius: 12px;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--secondary-200);
      word-break: keep-all;
      white-space: nowrap;
    }

    &__line {
      position: relative;
      height: 100%;
      width: 1px;
      background-color: var(--secondary-300);

      &:before,
      &:after {
        content: '';
        left: -3px;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--secondary-300);
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 8px;

    }

    &__item {
      padding: 16px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: var(--secondary-200);

      &__time {
        @include bodyExtraSmall;
        color: var(--grey);
      }

      &__text {
        @include bodySmall;
        color: var(--dark);
      }
    }
  }
}

.placeholder {
  padding: 24px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  &__img {
    width: 230px;
    height: 205px;
  }

  &__title {
    @include bodyMedium;
    margin-bottom: 16px;
  }
}