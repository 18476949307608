@import "src/styles/helpers";

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__icon {
    width: 230px;
    height: 205px;
  }

  &__text {
    @include bodyMedium;
    margin: 32px 0;
  }
}