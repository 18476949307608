@import "src/styles/helpers";

.page {

  &_withSidebar {
    display: grid;
    grid-template-columns: 230px 1fr;

    @include media(notTabletLandscape) {
      grid-template-columns: 1fr;
    }
  }
}

.wrapper {
  width: 100%;
}

.inner {
  padding: 16px;
  background: var(--bg-main);
  min-height: calc(var(--vh, 1vh) * 100 - var(--header-height));
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 16px;

  @include media(mobile) {
    gap: 0;
  }
}

.block {
  background: var(--white);
  border-radius: 8px;
  display: grid;
  grid-template-rows: auto 1fr;

  @include media(mobile) {
    border-radius: 0 0 8px 8px;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;

  @include media(mobile) {
    display: none;
  }
}

.tab {
  position: relative;
  padding: 24px 8px 16px;
  @include subtitleSmall;
  border-bottom: 1px solid var(--secondary-300);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  @include media(desktopMedium) {
    @include subtitle;
  }

  &:hover {
    color: var(--primary-500);
  }

  &.active {
    color: var(--primary-500);

    &:before {
      content: '';
      position: absolute;
      bottom: -1px;
      height: 2px;
      width: 100%;
      background: var(--primary-500);
      // border-bottom: 2px solid var(--primary-500);
    }
  }

  &__icon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
}

.content {
  padding: 16px;
}
