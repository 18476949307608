@import "src/styles/helpers";

.releaseDetails {
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;

    &_title {
        @include h5();
        margin-bottom: 16px;
    }

    &_content {
        overflow-y: auto;
    }

    &_list, &_description {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &_description {
        padding: 0 10px;
        margin-top: 32px;

        p, h5 {
            @include h5();
        }

        ul,
        ol {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0 0 0 20px;
            margin: 0;
            @include bodyMedium();
            margin-bottom: 16px;
        }

        ul li {
            list-style: disc;
        }

        ol li {
            list-style: decimal;
        }
    }

    .gotItButton {
        margin-top: 32px;
        width: 100%;
    }

}