@import "src/styles/helpers";

.newProject {
  display: grid;
  grid-template-rows: auto 1fr;

  &_pending {
    opacity: 0.4;
    cursor: progress;
    pointer-events: none;
    filter: blur(10px);
  }
}

.loader {
  position: fixed;
  top: 40%;
  width: fit-content;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 2;

  &__icon {
    margin: 0 auto 24px;
  }

  &__text {
    text-align: center;
    @include h3;
  }
}

.wrapper {
  padding: 16px;
  width: calc(var(--vw, 1vw) * 100);

  &>div {
    min-height: 100%;
  }
}

.iaLabelWrapper {
  position: relative;
  width: 100%;

  &_active {
    div {

      fieldset,
      [role="group"],
      [role="combobox"] {
        border-color: var(--primary-100) !important;
      }
    }
  }

  &>* {
    width: 100%;
  }

  .aiLabel {
    position: absolute;
    top: -17px;
    right: 0;
    width: fit-content;
    display: flex;
    gap: 3px;
    align-items: center;
    color: var(--primary-500);
    @include bodyExtraSmall;

    svg {
      height: 14px;
      width: 14px;

      @include media(mobile) {
        height: 12px;
        width: 12px;
      }
    }
  }
}