@import "src/styles/helpers";

.wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: 6px;

  &__title,
  &__text {
    @include bodyExtraSmall;

    color: var(--grey);
    font-family: Inter, sans-serif;

    @include media(desktopMedium) {
      @include bodySmall;
    }
  }

  &__text {
    color: var(--white);
  }
}

.counter {
  @include bodyExtraSmall;
  padding: 2px 5px;
  border-radius: 4px;
  border: 1px solid var(--secondary-300);
  background: var(--secondary-100);
  color: var(--gray);
  text-align: center;
  font-family: Inter, sans-serif;

  @include media(desktopMedium) {
    @include bodySmall;
  }
}

.options {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.optionList {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &_list {
    display: flex;
    flex-direction: column;
    list-style: decimal;
    gap: 4px;

    span {
      display: list-item;
      margin-left: 14px;
    }
  }
}