@import "src/styles/helpers";

.button {
  width: 100%;
}

.form {
  display: grid;
  align-content: space-between;
  height: 100%;

  button {
    width: 100%;
  }
}

.newClientButton {
  position: sticky;
  bottom: 16px;
  background-color: var(--white);
  margin-top: auto;
  align-self: flex-end;

  @include media(notTabletLandscape) {
    position: static;
    align-self: stretch;
    margin-top: 16px;
  }
}
