@import "./helpers";

:root {
  @include colors;

  --header-height: calc(92px + var(--project-edit-notification-height, 0px));
  --project-tabs-height: 80px;
  --project-summary-header-height: 86px;
  --top-z-index: 1301;

  @include media(tabletLandscape) {
    --header-height: 74px;
  }

  @include media(desktopSmall) {
    --header-height: calc(74px + var(--project-edit-notification-height, 0px));
  }

  @include media(notTabletLandscape) {
    --header-height: calc(66px + var(--project-edit-notification-height, 0px));
  }

  @include media(tabletPortrait) {
    --project-summary-header-height: 144px;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-weight: normal;
  scroll-behavior: smooth;
}

body {
  font-size: 14px;
  line-height: normal;
  background-color: var(--white);
  color: var(--dark);
  font-family: Inter, sans-serif;
  font-weight: 500;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline-color: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

pre {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-align: inherit;
  cursor: pointer;
  color: inherit;
  font: inherit;
}

figure {
  margin: initial;
  padding: initial;
}

input {
  font-family: Inter, sans-serif;
  color: var(--dark);

  &::placeholder {
    font-family: Inter, sans-serif;
    color: var(--grey);
  }
}

.link {
  color: var(--primary-500);
  text-decoration: underline;
  @include ease;

  &:hover {
    color: var(--primary-400);
  }

  &:focus,
  &:active {
    color: var(--primary-600);
  }

  &:disabled {
    opacity: 0.3;
  }
}

* {
  outline-color: var(--primary-500);
}

@include font-face("Inter", "../../public/fonts/Inter/Inter-Regular", 400);
@include font-face("Inter", "../../public/fonts/Inter/Inter-Medium", 500);
@include font-face("Inter", "../../public/fonts/Inter/Inter-SemiBold", 600);
@include font-face("Inter", "../../public/fonts/Inter/Inter-Bold", 700);