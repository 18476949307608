@import "src/styles/helpers";

.login {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  gap: 24px;
  padding: 24px;

  @media screen and (max-width: 1023px) {
    padding: 0;
  }

  @include media(tabletLandscape) {
    padding: 16px;
  }

  @include media(desktopBig) {
    padding: 32px;
    gap: 32px;
  }

  &__content {
    width: 50%;
    padding: 48px 80px;
    display: flex;
    gap: 48px;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 1023px) {
      width: 100%;
      padding: 48px;
    }

    @include media(tabletLandscape) {
      padding: 24px 32px;
    }

    @include media(desktopBig) {
      padding: 48px 128px;
    }
  }

  &__illustration {
    width: 50%;
    background: url('../../../public/media/login-slogan.webp') no-repeat center / cover;
    padding: 80px;
    border-radius: 24px;

    @media screen and (max-width: 1023px) {
      display: none;
    }

    @include media(desktopBig) {
      padding: 104px;
    }

    @include media(tabletLandscape) {
      padding: 48px;
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media(tabletLandscape) {
    img {
      width: 159px;
      height: 38px;
    }
  }

  @media screen and (max-width: 1023px) {
    img {
      width: 159px;
      height: 38px;
    }
  }
}

.title {
  @include h1;
  margin-bottom: 48px;

  @include media(tabletLandscape) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1023px) {
    margin-bottom: 64px;
  }
}

.loginError {
  margin-bottom: 48px;
}

.form {
  display: grid;
  gap: 32px;
}

.motto {
  position: relative;
  font-size: 94px;
  line-height: 100px;
  letter-spacing: -0.03em;
  color: var(--white);
  font-weight: 600;
  text-align: center;
  z-index: 2;


  @include media(tabletLandscape) {
    font-size: 54px;
    font-weight: 600;
    line-height: 64px;
    letter-spacing: -1.62px;
  }
}

.passwordState {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  // bigger clickable area, but aligned correctly
  margin: -9px 0;
}

.loginButton {
  width: 100%;
}

.altLogin {
  text-align: center;
  overflow: hidden;

  &__text {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: var(--grey);

    &:before {
      content: '';
      position: absolute;
      left: calc(100% + 16px);
      top: calc(50% - 0.5px);
      width: 300px;
      height: 1px;
      background: var(--secondary-500);
    }

    &:after {
      content: '';
      position: absolute;
      right: calc(100% + 16px);
      top: calc(50% - 0.5px);
      width: 300px;
      height: 1px;
      background: var(--secondary-500);
    }
  }
}

.forgotPassword {
  font-size: 12px;
}

.microsoftLoading {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__loader {
    margin: 40px 0 24px 0;
  }

  p {
    @include bodySmall;
    color: var(--grey)
  }
}

.microsoftButton {
  width: 100%;
}

.copyright {
  @include bodySmall;
}

.backButton {
  width: 42px;
  height: 42px;
  padding: 12px;
  left: 16px;

  svg {
    transform: rotate(90deg);
  }
}

.twoFA {
  display: flex;
  flex-direction: column;
  gap: 48px;

  &__heading {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 0;
  }

  &__subtitle {
    @include bodyMedium;
  }

  .form {
    display: grid;
    gap: 24px;
  }
}