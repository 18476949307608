@import "src/styles/helpers";

.error {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--red);
  display: flex;
  align-items: center;

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: var(--red);
  }

  &__message {
    @include bodySmall;
    color: var(--red);
  }
}
