@import "src/styles/helpers";

.section {
  background: var(--white);
  border-radius: 8px;
  padding: 24px;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.summary {
  &_pending {
    opacity: 0.4;
    cursor: progress;
    pointer-events: none;
    filter: blur(10px);
  }
}

.mainLoader {
  position: absolute;
  top: 40%;
  width: fit-content;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 2;

  &__icon {
    margin: 0 auto 24px;
  }

  &__text {
    text-align: center;
    @include h3;
  }
}

.parameters {
  grid-column: 1 / 3;

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 32px;
  }

  .parameter {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 4px 12px;
    padding: 8px 0;

    &_wide {
      grid-column: 1 / 3;
    }

    &__icon {
      grid-row: 1 / 3;
    }

    &__value {
      @include h5;
    }

    &__title {
      @include bodySmall;
      color: var(--grey);
    }
  }
}

.score {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__moreButton {
    @include bodySmall;
  }

  &__drawer {
    background: var(--bg-main) !important;
  }
}

.riskTasks {

  &__list {
    margin-top: 24px;

    &__row {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 64px 64px;
      gap: 4px;
      padding: 12px 0;
      border-bottom: 1px solid var(--secondary-200);
    }

    &__header {
      color: var(--grey);
      @include bodyExtraSmall;
      padding: 0 0 8px;

      p:not(:first-of-type) {
        text-align: center;
      }
    }

    &__item {

      .caption {
        @include bodyExtraSmall;
      }
    }
  }

  &__status {
    border-radius: 4px;
    padding: 4px;
    text-align: center;
    @include bodyExtraSmall;

    &_default {
      background: rgba(#2E78E6, 0.1);
    }

    &_risk {
      background: rgba(#EB6060, 0.1);
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--white);
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 0 24px;
  height: var(--project-summary-header-height);

  @include media(mobile) {
    border-radius: 8px 8px 0 0;
    margin-bottom: 1px;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    height: unset;
    gap: 16px;
  }

  nav {
    display: flex;
    gap: 24px
  }

  &__projectBlock {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include media(mobile) {
      display: block;
    }
  }

  &__projectTrackingLink {
    color: var(--primary-500);
    @include buttonFont;
    margin-left: 16px;

    @include media(mobile) {
      margin-left: 0;
      margin-top: 8px;
      display: inline-block;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__pageInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .pageType {
    @include h3;
  }

  .projectName {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--dark);

    &_withLink {
      &:after {
        content: '';
        margin-left: 16px;
        width: 8px;
        height: 8px;
        background: var(--secondary-400);
        border-radius: 50%;

        @include media(mobile) {
          display: none;
        }
      }
    }

    &__title {
      @include bodyMedium;
    }

    &__icon {
      width: 14px;
      height: 14px;
    }
  }

  @include media(tabletPortrait) {
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 16px 16px 0;

    &__pageInfo {
      gap: 8px;
    }

    nav {
      align-self: stretch;
    }

    .pageType {
      @include h2;
    }
  }
}

.navigation {
  align-self: flex-end;

  &__link {
    color: var(--grey);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 8px 25px;
    border-bottom: 2px solid transparent;
    @include subtitleSmall;

    &:hover,
    &.active {
      color: var(--primary-500);
    }

    &.active {
      border-color: var(--primary-500);
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    @include media(tabletPortrait) {
      flex: 1 0 0;
      padding: 24px 8px 12px;

      align-self: stretch;
    }
  }
}

.navigation__mobile {
  width: 100%;

  display: none;

  @include media(mobile) {
    display: block;
  }

  button {
    width: 100%;
    color: var(--primary-500);
  }

  .popoverButton__content {
    width: 100%;
    display: flex;
    height: 42px;
    padding: 11px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--secondary-100);
  }

  .downloadButton__arrow {
    width: 20px;
    height: 20px;

    margin-left: auto;
  }
}

.popoverOption {
  color: var(--grey);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
  padding: 0;
  border-bottom: unset;

  &::before {
    content: "";

    position: absolute;
    top: -12px;
    left: -12px;

    height: 44px;
    width: 2px;

    background-color: transparent;
  }

  &:hover,
  &:focus {
    color: var(--primary-500);
  }

  &.activeOption {
    color: var(--primary-500);

    &::before {
      background-color: var(--primary-500);
    }
  }

  .popoverOption__text {
    margin-top: 2px;
  }
}

.navigation__mobile__paper {
  width: 220px;
  left: 16px;
}

.navigation__mobile__icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}