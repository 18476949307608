@import "src/styles/helpers";

.jira {
    display: flex;
    padding: 8px 24px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    &__title {
        @include h5;

        display: flex;
        gap: 16px;
        align-items: center;

        &__icon {
            width: 32px;
            height: 32px;
        }
    }

    &__fields {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        @include media(mobile) {
            grid-template-columns: 1fr;
        }
    }

}

.loader {
    margin: 0 auto;
}