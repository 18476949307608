@import "src/styles/helpers";

.addButton > div {
  margin-right: 8px !important;
}

.loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deliverables {
  background-color: var(--white);
  padding: 12px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  height: calc(var(--vh, 1vh) * 100 - 243px);
  overflow-y: auto;
}

.empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    width: 230px;
    height: 205px;
  }

  p {
    @include bodyMedium;
    margin-top: 24px;
  }
}

.addDeliverableDrawer {
  width: 540px;

  @include media(mobile) {
    width: 100vw;
  }
}
