@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
    src: url('#{$file-path}.woff') format('woff'),
    url('#{$file-path}.ttf') format('truetype');
  }
}

@mixin h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
}

@mixin h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

@mixin h3 {
  font-weight: bold;
  font-size: 21px;
  line-height: 28px;
}

@mixin h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

@mixin h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@mixin subtitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

@mixin subtitleSmall {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

@mixin bodyLarge {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin bodyMedium {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

@mixin bodySmall {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

@mixin bodyExtraSmall {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

@mixin caption {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin buttonFont {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}
