@import 'src/styles/helpers';

.preview {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 999;
  background: var(--white);
  border: 1px dashed var(--primary-500);
  border-radius: 8px;
  box-shadow: 0 33px 73.3px 0 rgba(10, 39, 48, 0.12);
  display: flex;
  align-items: center;
  @include ease;

  &.hidden {
    right: 0;
    transform: translateX(calc(100% - 52px));
  }

  &__content {
    padding: 11px 15px 11px 0;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__text {
    max-width: 100px;
    @include subtitleSmall;
  }

  button {
    padding-top: 8px;
    padding-bottom: 8px;
    @include bodySmall;
  }
}

.angleSVG, .eyeSVG {
  color: var(--primary-500);
}

.hidePreviewButton {
  width: 32px;
  height: 32px;
  padding: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.showPreviewButton {
  padding: 11px 16px 11px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;

  svg {
    width: 20px;
    height: 20px;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
