@import "../../styles/helpers";

.form {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  &__block {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__footer {
    margin-top: auto;
    padding: 32px 0 24px;
    display: flex;
    gap: 16px;

    @include media(mobile) {
      flex-direction: column;
    }

    button {
      width: 100%;

      & > div {
        margin-right: 8px !important;
      }
    }
  }
}
