@import "src/styles/helpers";
@import "../Voting/Voting.module.scss";

.constraints {
  height: 100%;
  overflow: auto;
  flex: 1;
  background-color: var(--white);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0 16px;

  display: flex;
  flex-direction: column;
  width: 100%;

  @include media(tabletPortrait) {
    width: calc(var(--vw, 1vw) * 100 - 32px);
  }

  @include media(mobile) {
    align-items: center;

    & > div {
      width: max-content;
    }
  }

  &_noContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_revalidate {
    opacity: 0.5;
    cursor: progress;

    * {
      pointer-events: none;
    }

  }

  &__loader {
    margin: 0 auto;
  }

  &__row {
    display: grid;
    gap: 24px;
    align-items: center;
    grid-template-columns: 16px repeat(4, minmax(122px, 1fr)) minmax(0, 100px) minmax(0, 60px) minmax(0, 100px) minmax(0, 24px);

    @include row;


    @include media(tabletPortrait) {
      width: 1080px;
    }

  }

  &__header {
    padding-top: 24px;
    @include listHeader;
  }

  &__actionButton {
    @include actionButton;
  }

  .greyText {
    color: var(--grey);
    @include bodySmall;
  }

  .statusLabel {
    width: fit-content;
  }
}

.inputGroup {
  // reset fieldset styles
  padding: 0;
  margin: 0;
  border: none;

  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

.drawer {
  width: 540px;

  @include media(mobile) {
    width: 90%;
  }

  @include media(mobileSmall) {
    width: 100%;
  }

  h3 {
    @include h4;
  }
}

.constraintFrom {
  @include drawerForm;

  @include media(mobile) {
    width: calc(90* var(--vw) - 48px); // 48px - padding * 2
  }

  &__select {
    width: 492px;

    @include media(mobile) {
      width: unset;
    }
  }

  &__footer {
    $padding: 24px;

    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr) minmax(0, 42px);

    @include media(mobile) {
      width: calc(90 * var(--vw) - $padding * 2);
    }

    &_gateFinished {
      grid-template-columns: repeat(2, 1fr);
    }

    &_simple {
      display: flex;
      align-items: center;
    }
  }

  &__button {
    width: 100%;
    align-content: center;

    &_remove {
      color: var(--red) !important;
    }
  }

  svg {
    height: 18px;
    width: 18px;
  }
}