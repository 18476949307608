@import "src/styles/helpers";

.scoreItem {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 16px;
  padding: 24px;

  @include media(desktopSmall) {
    padding: 16px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--bg-main);
  }

  &__circle {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    @include subtitleSmall;
    word-break: break-word;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }
}