@import "src/styles/helpers";

.AIGeneratedRisks {
    display: flex;
    flex-direction: column;
    gap: 16px;


    &__header {

        &__title {
            @include h5;
            color: var(--dark);

          @include media(tablet) {
            padding: 16px 16px 0;
          }

          @include media(mobile) {
            padding: 16px 16px 0;
          }
        }
    }
}
