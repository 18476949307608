@import "src/styles/helpers";

.wrapper {
  border: 1px solid var(--secondary-500);
  border-radius: 8px;
  width: 100%;

  &.error {
    border-color: var(--red);
  }

  &.readOnly {
    opacity: 0.5;
  }
}
.editor {
  padding: 8px;
  [role="textarea"] {
    min-height: 152px;
  }

  :global {
    .w-e-text-placeholder {
      @include bodyMedium;
      font-style: normal;
      color: var(--grey);
      top: 12px;
    }
    .w-e-text-container [data-slate-editor] p {
      margin: 10px 0;
    }
  }
}
.toolbar {
  border: none;
  background-color: var(--secondary-200);
  padding: 16px 18px;

  :global {
    .w-e-bar {
      padding: 0;
      background: transparent;
    }
    .w-e-bar-item {
      padding: 0 6px;
      height: auto;

      button {
        padding: 0;
        width: 20px;
        height: 20px;
      }

      svg {
        fill: var(--black);
      }
    }
    .w-e-bar-divider {
      height: 20px;
      margin: 0 18px;
    }
  }
}
.errorMessage {
  @include bodySmall;
  color: var(--red);
  margin-top: 8px;
}
