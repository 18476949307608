@mixin imgCover {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin imgContain {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
