 @import "src/styles/helpers";

 .projects {
   height: 100%;
   background: var(--white);
   border-radius: 8px;
   display: grid;
   grid-template-rows: auto 1fr;

   &__clientView {
     padding: 32px 16px;
     margin: 16px;
   }

   .panel {
     margin-bottom: 16px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     gap: 8px;

     &>div {
       padding: 0;
       width: 100%;
     }

     &__button {
       display: flex;
       align-items: center;
       justify-content: center;
       height: 44px;

       &__diagramIcon {
         height: 18px;
         width: 18px;
       }

       @include media(mobile) {
         height: 42px;
         width: 42px;
         padding: 5px;

         &.panel__button__createNewProject {
           grid-column: 1 / 4;
           width: 100%;
         }
       }
     }

     &__settingsIcon {
       height: 18px;
       width: 18px;
     }

     @include media(mobile) {
       .archiveLink {
         flex-grow: 1
       }
     }

     @include media(notDesktop) {
       position: sticky;
       left: 0;
     }
   }

   .title {
     @include h1;
     margin-right: auto;
     display: inline-block;

     &_adminView {
       @include h5;
       line-height: 42px;
     }
   }

   .statusLabel {
     @include media(desktopMedium) {
       @include bodyMedium;
     }
   }
 }

 .loader {
   @include centerFlexContent;
 }

 .editingUnavailableModal {
  width: 420px;
  height: 320px;
  padding: 32px;

  &__message {
    @include bodyMedium;
    color: var(--dark);
    text-align: start;
    margin-bottom: 8px;
  }
 }

 .userBlock {
  border-radius: 8px;
  border: 1px solid var(--secondary-300);
  padding: 12px;
  width: 100%;
  height: 62px;
  display: flex;

  &__icon {
    color: var(--primary-500);
    width: 33px;
    margin-right: 8px;
  }

   &__info {
    text-align: start;

     &__name {
      @include subtitle;
      color: var(--dark);
     }

     &__email {
      @include bodySmall;
      color: var(--grey);
     }
   }
 }