@import 'src/styles/helpers';

.attachedLink {
  border-radius: 8px;
  @include bodyExtraSmall;
  position: relative;
  width: 84px;
  display: flex;
  column-gap: 4px;


  &__link {
    padding: 9px 7px;
    display: inline-block;
    color: var(--primary-500);
    overflow: hidden;
    text-overflow: ellipsis;
    width: 84px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }


  &__editButtonMobile {
    padding: 10px;
    background-color: var(--secondary-100);

    .detailsSvg {
      width: 12px;
      height: 12px;
      color: var(--primary-500);
    }
  }
}

.attachedLinksDropdown {
  width: 270px;
  border-radius: 8px;

  &__padding {
    padding: 16px;
  }

  &__dropdownHeader {
    padding: 16px;
    border-bottom: 1px solid var(--secondary-200);
  }

  &__button {
    border: 1px solid var(--secondary-500);
    border-radius: 8px;
    @include bodyExtraSmall;
    position: relative;
    height: 32px;
    width: 104px;
    display: flex;
    align-items: center;
    color: var(--primary-500);
    padding: 0 7px;

    @include media(tabletLandscape) {
      width: 74px;
    }

    &__content {
      margin-top: -4px;
    }

    .angleSVG {
      margin-left: auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__quantity {
    @include bodySmall;
    color: var(--grey);
  }

  &__addButton {
    height: 32px;
    padding: 6px 8px;
    @include bodyExtraSmall;

    >div {
      margin-right: 4px !important;
    }
  }

  &__content {
    display: grid;
    gap: 8px;
    max-height: 196px;
    overflow-y: auto;
  }
}

.linkDeleteButton {
  @include bodyExtraSmall;
  background: var(--red);
  padding: 10px 8px;
  border-radius: 8px;
  color: var(--white);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background: var(--red-accent);
  }
}

.listedLink {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  .attachedLink {
    width: 100%;

    &__link {
      white-space: nowrap;
      width: 164px;
      color: var(--primary-500);
      border-radius: 8px;
      border: 1px solid var(--secondary-500);

      &_readonlyMode {
        width: 237px;
      }
    }
  }

  &__button {
    width: 32px;
    height: 32px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 12px;
    }

    .deleteSVG {
      color: var(--red);
    }
  }
}
