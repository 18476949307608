@import "../../styles/helpers";

.sidebar {
  min-width: 230px;
  border-right: 1px solid var(--secondary-300);
  background: var(--white);
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 8;
  padding: 24px 16px;

  &__subtitle {
    @include subtitleSmall;
    margin: 24px 0;
  }

  &__list {
    height: calc(var(--vh, 1vh) * 100 - 234px);
    overflow-y: auto;

    @include media(notTabletLandscape) {
      height: fit-content;
      max-height: 570px;
    }
  }

  @include media(notTabletLandscape) {
    display: none;
  }

  &.mobile {
    display: none;

    @include media(notTabletLandscape) {
      display: flex;
      flex-direction: column;
      position: static;
      height: fit-content;
    }
  }
}

.client {
  display: grid;
  grid-template-columns: 32px 1fr;
  gap: 12px;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px;
  @include subtitleSmall;
  color: var(--grey);
  @include ease;

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: var(--secondary-200);
    display: flex;
    align-items: center;
    justify-content: center;
    @include ease;
    @include imgCover;
    overflow: hidden;

    span {
      @include subtitleSmall;
      color: var(--grey);
      @include ease;
    }
  }
}

.active,
.client:hover {
  background: var(--secondary-200);
  border-radius: 8px;
  color: var(--dark);

  .avatar {
    background: var(--primary-500);

    span {
      color: var(--white);
    }
  }
}