@import "src/styles/helpers";

.wrapper {
  background-color: var(--white);
  border-radius: 8px;
  margin: 0 auto;
  padding: 32px;

  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  max-width: 800px;

  h3 {
    @include h3;
  }

  h4 {
    @include h4;
    margin: 32px 0;
  }

  @media screen and (min-width: 1024px) {
    width: 800px;
  }

  @include media(mobile) {
    padding: 24px;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media(mobile) {
    margin-top: 50%;
  }
}

.notQuestions {
  p {
    @include bodyMedium;
    text-align: center;
    margin: 32px 0;
  }

  button {
    width: 100%;
  }
}

.form {
  &__buttons {
    margin-top: 40px;

    button {
      width: 100%;
    }
  }
}

.question {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--secondary-200);

  &.hasCategory {
    border-top: 1px solid var(--secondary-200);
  }

  &__title {
    display: flex;

    p,
    span {
      @include bodyMedium;
    }

    span {
      margin-right: 16px;
      color: var(--grey);
    }

    p {
      color: var(--dark);
    }

    @include media(mobile) {
      margin-right: auto;
    }
  }

  &__select {
    min-width: 113px !important;
    max-width: 240px;
    font-size: 12px;
    line-height: 16px;

    @include media(mobile) {
      max-width: unset;
      width: 100%;
    }
  }

  &__iaLabel {
    width: auto;
    display: flex;
    gap: 16px;
    flex-direction: row;
    flex-shrink: 0;

    [data-role="ai-label"] {
      position: static !important;
      word-break: keep-all;
      white-space: nowrap;
    }

    @include media(mobile) {
      width: 100%;
      flex-direction: column;
      gap: 8px;

      [data-role="ai-label"] {
        margin-right: auto;
      }
    }
  }

  @include media(mobile) {
    flex-direction: column;
    gap: 16px;

    [role="group"] {
      width: 100% !important;

      button {
        flex-grow: 1;
      }
    }
  }
}

.subQuestion {
  margin-left: 32px;
}

.modal {
  &>div {
    padding: 24px;
  }

  &__content {
    display: grid;
    justify-items: center;
    gap: 24px;

    svg {
      width: 205px;
      height: 205px;
    }

    h4,
    p {
      text-align: center;
    }

    h4 {
      @include h4;
      margin-bottom: 16px;
    }

    p {
      @include bodyMedium;
    }

    button {
      width: 100%;
    }
  }
}

.headerArea {
  display: flex;
  justify-content: space-between;
  padding-right: 4px;

  &__button {
    @include bodyExtraSmall;
    min-width: 95px;
    height: 32px;
    padding: 0px 12px;
    white-space: nowrap;

    div {
      margin-right: 4px !important;
    }
  }
}

$tabsHeight: 32px;

.aiLoadingWrapper {
  background-color: var(--white);
  border-radius: 8px;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height) - $tabsHeight);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__text {
    margin-top: 32px;
    @include bodyMedium;
    color: var(--grey);
    text-align: center;
  }
}