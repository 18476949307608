@import "src/styles/helpers";

.search {
  position: relative;
  z-index: 10;

  &__container {
    position: relative;
  }

  svg {
    color: var(--grey);
  }

  &__searchIcon {
    left: 16px;
    top: calc(50% - 8px);
    position: absolute;
    width: 16px;
    height: 16px;

    @include media(mobileSmall) {
      left: 8px;
    }
  }

  &__closeButton {
    position: absolute;
    top: calc(50% - 14px);
    right: 13px;
    padding: 6px;
    box-sizing: content-box;
    cursor: pointer;
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__input {
    border-radius: 8px;
    border: 1px solid var(--secondary-300);
    width: 495px;
    height: 40px;
    @include bodySmall;
    color: var(--dark);
    padding: 4px 43px 4px 40px;

    @include media(mobile) {
      width: 180px;
    }

    @include media(mobileSmall) {
      width: 100px;
      padding: 4px 8px 4px 30px;

    }

    &::placeholder {
      @include bodySmall;
      color: var(--grey);
    }

    &:hover {
      border-color: var(--secondary-500);
    }

    &:focus-visible,
    &:active {
      border-color: var(--secondary-500);
      outline: none;
    }
  }

  &__inputActive {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  @include media(mobile) {
    &:has(input:focus) {
      $side-offset: 15px;

      position: absolute;
      top: 12px;
      left: $side-offset;
      width: calc(100 * var(--vw) - $side-offset * 2);

      & > div,
      & input {
        width: 100%;
      }
    }
  }
}

.notFound {
  text-align: center;
  padding: 32px 0;

  h6 {
    @include subtitle;
    margin-bottom: 8px;
  }

  p {
    @include bodySmall;
    color: var(--grey);
  }
}

.result {
  position: absolute;
  left: 0;
  right: 0;
  top: 40px;
  background-color: var(--white);
  padding-top: 12px;
  border-radius: 0 0 8px 8px;
  border: 1px solid var(--secondary-500);
  border-top: none;
  display: grid;
  gap: 12px;
  max-height: 480px;
  overflow-y: auto;
  box-shadow: 0 38px 30px 0 rgba(0, 41, 54, 0.04);

  &__loader {
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
  }

  &__group {
    border-bottom: 1px solid var(--secondary-200);
    padding-bottom: 8px;
    display: grid;
    gap: 4px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__groupTitle {
    @include bodySmall;
    color: var(--grey);
    text-transform: capitalize;
    margin-bottom: 4px;
    padding: 0 16px;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer;
  @include ease;

  &:hover {
    background-color: var(--secondary-200);
  }

  &__leftContent {
    display: flex;
  }

  &__projectIcon,
  &__userIcon {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    text-transform: uppercase;
    @include subtitleSmall;
  }

  &__projectIcon {
    border-radius: 8px;
  }

  &__userIcon {
    @include bodySmall;
    border-radius: 50%;
    background-color: var(--primary-500);
    color: var(--white);
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p,
    span {
      max-width: 238px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    p {
      @include subtitleSmall;
    }

    span {
      @include bodyExtraSmall;
      margin-top: 4px;
      color: var(--grey);
    }
  }

  &__email {
    @include bodyExtraSmall;
    color: var(--grey);
    padding: 6px;
    border-radius: 2px;
    background-color: var(--white);
    border: 1px solid var(--secondary-400);
    max-width: 155px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}