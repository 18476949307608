@import "src/styles/helpers";

.wrapper {
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: var(--secondary-200);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.deliverable {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;

  &.isRestricted {
    opacity: 0.5;
    // pointer-events: none;
  }

  &:not(.isOpen) {
    height: 62px;

    @include media(mobile) {
      height: unset;
      align-items: flex-start;
    }
  }

  &>div {
    display: flex;
    align-items: center;

    @include media(mobile) {
      align-items: flex-start;

      &>div:first-of-type {
        padding-top: 12px;
      }
    }
  }

  &__inner {
    margin-left: 12px;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &.warning {

      span:first-of-type,
      .warningArrowSvg {
        color: var(--orange)
      }
    }

    span {
      @include bodyExtraSmall;
      color: var(--grey);
      display: flex;
      align-items: center;
    }

    .divider {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin: 0 8px;
      background-color: var(--secondary-500);
    }

    svg {
      width: 12px;
      height: 12px;
      color: var(--grey);
      margin-right: 2px;
    }
  }

  &__title {
    @include subtitleSmall;
  }

  &__risk,
  &__done {
    @include bodyExtraSmall;
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }


  &__risk {
    color: var(--red);
    margin-right: 64px;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 2px;
    }
  }

  &__done {
    color: var(--green);
    margin-right: 52px;

    svg {
      margin-right: 4px;
    }
  }

  &__mark {
    margin-right: 36px;

    p {
      @include bodySmall;
    }
  }

  &__button {
    margin-left: 16px;
    padding: 0;
    width: 32px;
    height: 32px;

    &_hidden {
      visibility: hidden;
    }

    @include media(mobile) {
      &:not(.deliverable__toggleButton) {
        display: none;
      }
    }
  }

  &__toggleButton {
    padding: 12px;
    display: flex;

    svg {
      width: 16px;
      height: 16px;
      color: var(--primary-500);
    }
  }
}

.deliverable.isOpen {
  border-bottom: 1px solid var(--secondary-500);

  .deliverable__toggleButton svg {
    transform: rotate(180deg);
  }
}