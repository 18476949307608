@import "src/styles/helpers";

.task {
  background: var(--primary-400);
  color: var(--white);
  padding: 8px 12px;
  border-radius: 20px;
  @include bodySmall;
  height: 100%;
  font-family: Inter, sans-serif;

  &.risk {
    background: var(--red) !important;
  }

  &.sprint {
    padding: 4px;
    overflow: hidden;

    .task__content {
      gap: 4px;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__sprint {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 5px 6px;
    flex: 1 1 0;
    min-width: 46px;

    svg {
      width: 14px;
      height: 14px;
    }

    &__counter {
      font-size: 10px;
      line-height: 12px;
      margin-left: 4px;
    }
  }

  &__outsideTitle {
    margin-left: 8px;

    @include bodyExtraSmall;

    &__title {
      color: var(--dark);
      margin-bottom: 4px;
    }

    &__sprints {
      color: var(--grey);
    }
  }
}

.taskWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

// 100vh - header - project step navigation height
// - top and bottom paddings - project summary heading
// - project summary heading padding - bottom padding - qGate footer
$ganttHeight: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--project-tabs-height) - 16px - var(--project-summary-header-height) - 16px - 16px - 60px);

.schedule {
  box-shadow: 0 13px 10px -13px rgba(0, 103, 136, 0.05);

  :global {

    .dx-gantt {
      height: $ganttHeight !important;
      min-height: 400px;
      border: none;
      border-radius: 8px 8px 0 0;

      .dx-treelist-headers .dx-treelist-table .dx-row>td,
      .dx-treelist-headers,
      .dx-gantt-header .dx-gantt-tsa,
      .dx-gantt-header .dx-gantt-vb,
      .dx-gantt-header .dx-gantt-tsac {
        border-color: var(--secondary-300) !important;
      }

      .dx-treelist-container {
        border-right: 1px solid var(--secondary-300);
      }

      .dx-gantt-treelist-wrapper {
        height: 100% !important;

        >.dx-widget.dx-visibility-change-handler.dx-treelist {
          height: 100% !important;
        }
      }

      .dx-gantt-view {
        height: 100% !important;

        >div {
          height: 100% !important;
        }
      }

      .dx-gantt-touch-action {

        .dx-gantt-vb {
          border-left: none;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background-image: repeating-linear-gradient(to bottom, transparent 0, transparent 10px, #F1F3F4 10px, #F1F3F4 20px);
          }
        }
      }

      :local {

        .todayMark {
          border-left: 1px solid var(--primary-500);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          z-index: 11;

          &:before {
            content: '';
            position: sticky;
            top: 0;
            margin-left: -7.5px;
            border-style: solid;
            border-width: 8px 7px 0 7px;
            border-color: var(--primary-500) transparent transparent transparent;
            display: block;
            height: 8px;
          }

          &:after {
            content: '';
            position: sticky;
            bottom: 0;
            margin-left: -7.5px;
            border-style: solid;
            border-width: 0 7px 8px 7px;
            border-color: transparent transparent var(--primary-500) transparent;
            display: block;
            height: 8px;
          }
        }

        .qGate {
          border-left: 1px solid var(--secondary-500);
        }
      }

      .dx-treelist .dx-column-lines>td {
        border-right: none;
        border-left: none;
      }

      .dx-treelist-rowsview {
        .dx-row {
          height: 40px;
        }
      }

      .dx-row {

        .dx-treelist-text-content {
          @include bodySmall;
        }

        &.dx-gantt-collapsable-row {

          .dx-treelist-text-content {
            @include subtitleSmall;
          }
        }
      }

      .dx-treelist .dx-row>td {

        .dx-treelist-text-content {
          height: 100%;
        }
      }

      .dx-gantt-altRow,
      .dx-gantt-collapsable-row {
        background-color: transparent !important;
      }

      .dx-gantt-collapsable-row {
        td {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 100%;
            height: 32px;
            top: calc(50% - 16px);
            background: var(--secondary-200);
            z-index: 0;
          }

          >* {
            position: relative;
            z-index: 1;
          }

          &:first-child {

            &:before {
              border-radius: 8px 0 0 8px;
            }
          }

          &:last-child {

            &:before {
              border-radius: 0 8px 8px 0;
            }

            @include bodySmall;
            text-align: right !important;

            :local {
              .durationCell {
                color: var(--dark);
              }
            }
          }
        }
      }

      .dx-treelist-rowsview .dx-treelist-expanded span:before {
        content: '';
        background: url('../../../../../public/media/angle_down_blue.svg') no-repeat center / contain;
        width: 16px;
        height: 16px;
        margin-left: 0 !important;

        @-moz-document url-prefix() {
          margin-top: -12px !important;
        }
      }

      .dx-treelist-rowsview .dx-treelist-collapsed span:before {
        content: '';
        background: url('../../../../../public/media/angle_down_blue.svg') no-repeat center / contain;
        width: 16px;
        height: 16px;
        transform: rotate(-90deg);
        margin-left: 0 !important;

        @-moz-document url-prefix() {
          margin-top: -12px !important;
        }
      }

      .dx-treelist-rowsview .dx-treelist-content:not(.dx-treelist-content-fixed) {
        padding: 0 16px;
      }

      .dx-header-row {

        :local {
          .cell:first-child {
            padding-left: 16px !important;
          }

          .cell:last-child {
            padding-right: 16px !important;
          }
        }

        td:last-child {

          .dx-treelist-text-content {
            width: 100%;

            :local {

              .headerCell {
                display: flex !important;
                justify-content: flex-end;

                &_hidden {
                  opacity: 0;
                  pointer-events: none;
                }
              }
            }
          }
        }
      }

      .dx-gantt-si {
        font-family: Inter, sans-serif;
        @include caption;
      }

      .dx-treelist-table tbody {
        border-spacing: 8px;
        border-collapse: separate;
      }

      .dx-splitter-border {
        pointer-events: none !important;
        display: none !important;

        .dx-splitter {
          width: 1px !important;
        }
      }
    }

    .dx-treelist-icon-container {

      .dx-treelist-empty-space {
        width: 16px !important;
        height: 32px;

        &:first-child:not(.dx-treelist-expanded):not(.dx-treelist-collapsed) {
          display: none;
        }
      }

      &:has(.dx-treelist-empty-space.dx-treelist-expanded),
      &:has(.dx-treelist-empty-space.dx-treelist-collapsed) {
        .dx-treelist-empty-space {

          &:first-child {

            &:not(.dx-treelist-expanded),
            &:not(.dx-treelist-collapsed) {
              display: inline-block;
            }
          }
        }
      }
    }

    .dx-treelist-rowsview .dx-treelist-expanded span {
      width: 16px;
      height: 16px;
      display: block;
    }

    .dx-treelist-content .dx-treelist-table .dx-row>td,
    .dx-treelist-content .dx-treelist-table .dx-row>tr>td {
      vertical-align: middle;
    }
  }
}

.riskIcon {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  color: var(--white);
}

.tooltip {
  background: var(--white);
  border-radius: 8px;
  overflow: hidden;
  max-width: 270px;
  box-shadow: 0 9px 45px 0 rgba(0, 41, 54, 0.16);

  &.nameTooltip {
    padding: 8px 12px;

    .nameTooltip__title {
      @include subtitleSmall;
    }
  }

  &__header {
    padding: 16px;
    color: var(--red);
    @include subtitleSmall;
    border-bottom: 1px solid var(--secondary-200);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__content {
    padding: 16px;
    display: grid;
    gap: 8px;
    font-family: Inter, sans-serif;
  }

  &__title {
    @include subtitle;
    font-family: Inter, sans-serif;
  }

  &__block {
    background: var(--secondary-200);
    border-radius: 8px;
    padding: 8px;
    font-family: Inter, sans-serif;
  }

  &__text {
    color: var(--grey);
    @include bodySmall;
    margin-bottom: 4px;
    font-family: Inter, sans-serif;
  }

  &__duration {
    @include subtitleSmall;
    font-family: Inter, sans-serif;

    &_min {
      color: var(--green);
    }
  }
}

.scaleButton {
  color: var(--primary-500);
  background: var(--secondary-100);
  padding: 10px 8px;
  border-radius: 8px;
  @include bodyExtraSmall;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: capitalize;
  font-family: Inter, sans-serif;

  &:hover,
  &.active {
    background: var(--bg-main);
  }

  &__icon {
    margin-right: 4px;
  }

  svg {
    width: 12px;
    height: 12px;
  }

  .angleSVG {
    margin-left: 4px;
  }
}

.cell {
  height: 32px;
  border-bottom: none !important;
  padding: 4px 8px !important;
  color: var(--dark);
  font-family: Inter, sans-serif;

  &_hidden {
    opacity: 0;
    pointer-events: none;
  }

  &[role="columnheader"] {
    padding: 4px 8px !important;
  }

  :global {

    .dx-treelist-text-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}

.headerCell {
  height: 100%;
  display: flex;
  align-items: center;
  @include bodyExtraSmall;
  color: var(--grey);
}

.durationCell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @include bodySmall;
  color: var(--grey);
  text-align: right;
}

.taskCell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
}

.scalePaper {
  button {
    text-transform: capitalize;
  }

  &:before {
    right: auto !important;
    left: 15px;
  }
}

.levelPaper {
  max-width: 140px !important;
}

.preloader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $ganttHeight;
}

.selectCell {
  padding: 0 !important;
}

.tasksHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  @include media(tablet) {
    justify-content: flex-end;
    gap: 8px;
  }

  @include media(mobile) {
    justify-content: flex-end;
    gap: 8px;
  }

  &.minimized {
    padding: 0 10px;
  }

  &__buttons {
    display: flex;
    gap: 16px;
    width: auto;

    @include media(tabletLandscape) {
      gap: 8px;
    }
  }
}

.sidebarButton {
  width: 32px;
  height: 32px;
  border: 1px solid var(--secondary-300);
  padding: 8px;
  border-radius: 4px;
  display: none;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
  color: var(--primary-400);

  &_opened {
    background: var(--primary-400);
    color: var(--white);
    border-color: var(--primary-400);
  }

  @include media(tablet) {
    display: flex;
    margin-right: auto;
  }

  @include media(mobile) {
    display: flex;
    margin-right: auto;
  }

  &:hover {
    border-color: var(--secondary-600);

    &.sidebarButton_opened {
      border-color: var(--primary-400);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }
}

.footer {
  overflow: hidden;
  width: calc((var(--vw, 1vw) * 100) - 32px);
  display: flex;
  background: var(--white);
  border-top: 1px solid var(--secondary-200);
  @include hideScrollbar;
  border-radius: 0 0 8px 8px;

  >* {
    flex-shrink: 0;
  }

  &__sidebar {
    display: block;
    //width: 440px;
    position: sticky;
    left: 0;
    background: var(--white);
    z-index: 2;
  }

  &__track {
    position: relative;
    height: 60px;
  }
}

.qGateItem {
  width: fit-content;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border: 1px solid var(--green);
  background: #F5FBF5;
  @include bodyExtraSmall;
  text-align: center;
  padding: 6px;
  border-radius: 8px;
  height: 36px;
  max-width: 250px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &:before {
    content: '';
    position: absolute;
    left: calc(50% - 9px);
    top: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 7px 9px;
    border-color: transparent transparent var(--green) transparent;
  }

  &:hover {
    z-index: 2;
  }
}

.collapseHelperButton {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  width: 0;
  height: 0;
}

.manageButton {
  @include bodyExtraSmall;
  padding: 12px;
  overflow: hidden;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  >div {
    margin-right: 4px !important;
  }
}

.durationBlock {
  display: flex;
}

.manageDeliverableButton {
  margin-left: auto;
}