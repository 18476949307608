@import "src/styles/helpers";

.links {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__item {
    background-color: var(--white);
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: var(--grey);
    padding: 12px 44px 12px 12px;
    border-radius: 4px;
    position: relative;

    @include media(mobile) {
      width: 100%;
      overflow: hidden;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    a {
      display: flex;

      svg {
        margin-right: 4px;
      }
    }

    a:hover {
      color: var(--primary-500);
      text-decoration: underline;
    }

    span {
      display: block;
      max-width: 222px;
      @include bodySmall;
      @include ellipsis;
    }

    &_edit {
      position: absolute;
      right: 6px;
      display: flex;
      padding: 3px;
      opacity: 0;
      color: var(--primary-500);
    }
  }

  &__item:hover .links__item_edit {
    opacity: 1;
  }

  &__add {
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--secondary-500);
    background-color: var(--white);
    color: var(--primary-500);
    @include bodySmall;
    display: flex;
    align-items: center;

    @include media(mobile) {
      width: 100%;
    }

    &:hover {
      text-decoration: underline;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
