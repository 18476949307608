@import "src/styles/helpers";

.modal {
  z-index: var(--top-z-index);

  & > div {
    width: 392px;
    padding: 24px;
  }

  &__content {
    display: grid;
    gap: 24px;
    justify-items: center;
  }

  &__illustration {
    width: 230px;
    height: 205px;
  }

  h4 {
    @include h4;
    margin-bottom: 16px;
    text-align: center;
  }

  &__buttons {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    button {
      width: 100%;
    }
  }
}
