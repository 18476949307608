@import "../../../../styles/helpers";

.form {
  width: 492px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  @include media(mobile) {
    width: unset;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;

    div &_datepicker {
      margin-top: 24px;
    }
  }

  &__input {
    position: relative;

    :global {
      .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
        left: 39px !important;
      }

      .MuiInputBase-input {
        @include bodyMedium;
        padding-left: 52px !important;
        font-family: Inter, sans-serif;
        color: var(--dark);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 17px;
      left: 16px;
      width: 20px;
      height: 20px;
      background-size: contain;
      z-index: 2;
    }


    &_en {
      &:before {
        background: url('/public/media/eng-lang-icon.svg') no-repeat center;
      }
    }

    &_de {
      &:before {
        background: url('/public/media/deu-lang-icon.svg') no-repeat center;
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding: 32px 0 24px;

    button {
      width: 100%;
    }
  }
}