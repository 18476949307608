@import "src/styles/helpers";

.domains {
  border-right: 1px solid var(--secondary-200);
  padding: 24px 16px;
  overflow-y: auto;

  @include media(tabletPortrait) {
    padding: 0;
    border-right: none;
  }

  @include media(mobile) {
    border-right: none;
  }

  &__list {
    display: grid;
    gap: 8px;
    margin-top: calc(32px - 12px);
    @include hideScrollbar;

    &__wrap {
      width: 100%;
      overflow: hidden;

      @include media(tabletPortrait) {

        &.leftScrolled {
          position: relative;

          &:before {
            z-index: 2;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 70px;
            height: 100%;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.78) 49.25%, #FFF 100%);
          }
        }

        &.rightScrolled {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 70px;
            height: 100%;
            background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.78) 49.25%, rgba(255, 255, 255, 0.00) 100%);
          }
        }
      }
    }

    &__popover {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include media(tabletPortrait) {
      display: flex;
      overflow-x: auto;
      margin-top: 0;
      border-bottom: 1px solid var(--secondary-200);
      padding: 0 16px;
      gap: 12px;
    }
  }
}

.domain {

  &__item {
    @include subtitleSmall;
    color: var(--grey);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 12px 0;
    position: relative;

    @include media(tabletPortrait) {
      padding: 16px 8px;
      flex-shrink: 0;
      white-space: nowrap;
    }

    &:hover {
      color: var(--primary-500);
    }

    &.active {
      color: var(--primary-500);
      padding-left: 12px;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 10px);
        width: 2px;
        height: 20px;
        background: var(--primary-500);
      }

      @include media(tabletPortrait) {
        border-bottom: 2px solid var(--primary-500);
        padding-left: 8px;

        &:before {
          display: none;
        }
      }
    }
  }
}

.title {
  @include subtitle;

  &_domains {
    @include media(tabletPortrait) {
      display: none;
    }
  }
}

.deliverables {
  overflow-y: auto;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  @include media(mobile) {
    display: block;
    overflow-x: hidden;
  }

  &__heading {
    display: flex;
    padding: 16px 16px 8px;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;

    &__toggle {
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        @include bodyExtraSmall;
        color: var(--grey);
      }
    }
  }

  &__optionsButton {
    background: var(--secondary-100);
    width: 28px;
    height: 30px;
    border-radius: 8px;
    color: var(--primary-500);

    &:hover,
    &.active {
      background: var(--bg-main);
    }

    svg {
      height: 10px;
    }
  }

  &__options {
    max-width: 240px !important;
  }

  &__header {
    padding: 16px;
    display: grid;
    gap: 16px;
    //position: sticky;
    //top: 0;
    background: var(--white);
    z-index: 2;
    border-radius: 0 8px 0 0;

    @include media(desktopBig) {
      padding: 16px 24px;
    }

    @include media(mobile) {
      border-bottom: 1px solid var(--secondary-200);
      position: sticky;
      top: 0;
    }

    &__section {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr auto;
      gap: 48px;

      @include media(mobile) {
        gap: 16px;
      }

      &_filters {
        @include media(tablet) {
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;

          >div>button {
            width: 100%;

            .angleSVG {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  &__domain {
    p {
      @include subtitleSmall;
    }

    :global {
      .MuiTypography-root {
        // subtitleSmall mixin with !important
        font-size: 12px !important;
        line-height: 16px !important;
        font-weight: 600 !important;
      }
    }
  }

  &__section {
    border-bottom: 1px solid var(--secondary-200);
  }

  &__list {

    .deliverable {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 40px;
      padding: 7px 16px;
      @include bodySmall;

      @include media(mobile) {
        gap: 8px;
      }

      @include media(mobileSmall) {
        grid-template-columns: 1fr;
        gap: 0;

        div[role="group"]:first-child {
          width: 90% !important;

          button {
            width: 50%;
          }
        }
      }

      &.readOnly {
        padding: 12px 16px;
      }

      &__status {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        &.readOnly {
          gap: 16px;
        }
      }

      &__readOnlyStatus {
        --status-color: var(--blue);
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--status-color);

        &::before {
          display: block;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 20px;
          background-color: var(--status-color);
        }

        &.external {
          --status-color: var(--green);
        }
      }

      &__infoIcon {
        width: 16px;
        height: 16px;
        color: var(--grey);
      }
    }
  }
}

.filterButton {
  color: var(--primary-500);
  background: var(--secondary-100);
  padding: 10px 8px;
  border-radius: 8px;
  @include bodyExtraSmall;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover,
  &.active {
    background: var(--bg-main);
  }

  svg {
    width: 12px;
    height: 12px;
  }

  .filterSVG {
    margin-right: 4px;
  }

  .angleSVG {
    margin-left: auto;
  }
}

.filterCategoriesButton {
  width: 200px;

  @include media(tablet) {
    width: auto;
  }

  @include media(mobileSmall) {
    width: 170px;
  }
}

.filteredCategoriesPaper {
  width: 200px;
  display: grid;
  padding: 16px;
  margin: 20px 0;

  &__content {
    max-height: calc(var(--vh, 1vh) * 70);
    overflow-y: auto;
    display: grid;
  }
}

.filterInternalPaper {
  width: 170px;
  margin-left: 0 !important;
}

.filterInternalButton {

  .angleSVG {
    margin-left: 8px;
  }
}

.domainIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.footer {
  position: sticky;
  left: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 32px;
  align-items: center;
  background: var(--white);
  box-shadow: 0px -4px 12.7px 0px rgba(0, 32, 42, 0.05);
  padding: 16px;
  z-index: 2;

  &__title {
    @include subtitle;
    margin-bottom: 8px;
  }

  &__text {
    @include bodyExtraSmall;
    color: var(--grey);
  }

  &__button {
    &_readOnly {
      grid-column: 2/3;
    }
  }
}

.noDeliverables {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 24px 16px;
}

.bulkFilterButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 17px;
  margin: 16px 0;
}

.bulkFilterButton {
  @include bodySmall;
  color: var(--primary-500);

  &:first-child {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background: var(--secondary-700);
      right: -9px;
      pointer-events: none;
    }
  }

  &:hover {
    color: var(--primary-400);
  }
}

.filteredCategoriesTitle {
  @include subtitleSmall;
}

.domains__list__wrap {
  @include media(mobile) {
    display: grid;
    grid-template-columns: minmax(120px, 1fr) 135px;
    gap: 12px;

    button {
      width: 100%;
      color: var(--primary-500);
    }
  }

  .popoverButton__content {
    width: 100%;
    display: flex;
    height: 42px;
    padding: 11px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--secondary-100);

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .popoverButton__arrow {
    width: 20px;
    height: 20px;

    margin-left: auto;
  }

  .domains__scoreButton {
    height: 42px;
  }
}

.popoverOption {
  color: var(--grey);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;

  position: relative;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
  }

  &::before {
    content: "";

    position: absolute;
    top: -12px;
    left: -12px;

    height: 44px;
    width: 2px;

    background-color: transparent;
  }

  &:hover,
  &:focus {
    color: var(--primary-500);
  }

  &.activeOption {
    color: var(--primary-500);

    &::before {
      background-color: var(--primary-500);
    }
  }

  .popoverOption__text {
    margin-top: 2px;
  }
}

.domains__popoverPaper {
  width: 220px;
  left: 16px;
}

.tab__icon {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}