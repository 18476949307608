.palette {
  display: grid;
  gap: 24px;
}

.color {
  display: grid;
  align-items: center;
  grid-template-columns: 200px auto;

  &__value {
    height: 50px;
    width: 100px;
    border: 1px solid var(--dark);
  }
}

.primary50 {
  background: var(--primary-50);
}

.primary100 {
  background: var(--primary-100);
}

.primary200 {
  background: var(--primary-200);
}

.primary300 {
  background: var(--primary-300);
}

.primary400 {
  background: var(--primary-400);
}

.primary500 {
  background: var(--primary-500);
}

.primary600 {
  background: var(--primary-600);
}

.primary700 {
  background: var(--primary-700);
}

.primary800 {
  background: var(--primary-800);
}

.primary900 {
  background: var(--primary-900);
}

.secondary50 {
  background: var(--secondary-50);
}

.secondary100 {
  background: var(--secondary-100);
}

.secondary200 {
  background: var(--secondary-200);
}

.secondary300 {
  background: var(--secondary-300);
}

.secondary400 {
  background: var(--secondary-400);
}

.secondary500 {
  background: var(--secondary-500);
}

.secondary600 {
  background: var(--secondary-600);
}

.secondary700 {
  background: var(--secondary-700);
}

.secondary800 {
  background: var(--secondary-800);
}

.secondary900 {
  background: var(--secondary-900);
}

.dark {
  background: var(--dark);
}

.grey {
  background: var(--grey);
}

.white {
  background: var(--white);
}

.red {
  background: var(--red);
}

.green {
  background: var(--green);
}

.blue {
  background: var(--blue);
}

.orange {
  background: var(--orange);
}

.bgMain {
  background: var(--bg-main);
}
