@import "src/styles/helpers";

.notificationsPortalRoot {
  width: fit-content;
  height: fit-content;
  position: fixed;
  right: 0;
  top: 0;
  margin: 40px;
  max-width: 360px;
  // Mui Drawer have 1200
  z-index: 1201;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  &_bottomPlacement {
    bottom: 0;
    top: auto;
    flex-direction: column-reverse;
  }

  &_wide {
    max-width: unset;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    margin: 0 !important;
  }

  @include media(tablet) {
    margin: 32px;
  }

  @include media(tabletPortrait) {
    max-width: unset;
    width: calc(100% - (32px * 2));
  }

  @include media(dektopSmall) {
    margin: 32px;
  }

  @include media(desktopBig) {
    margin: 48px;
  }
}