@import "src/styles/helpers";

.downloadModal {
  padding: 24px;
  width: 518px;
  display: grid;
  gap: 24px;

  &__title {
    @include h3;
  }

  &__links {
    display: grid;
    gap: 8px;
  }

  &__link {
    display: block;
    padding: 12px 16px;
    border-radius: 8px;
    background: var(--bg-main);
    text-decoration: underline;
    color: var(--primary-400);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
    }
  }

  &__button {
    width: 100%;
    margin-top: 24px;
  }

  .sectionAccordion {
    box-shadow: none !important;
    margin: 0 !important;
    padding: 16px !important;
    border: 1px solid var(--secondary-300) !important;
    border-radius: 8px !important;

    &:before {
      display: none !important;
    }

    :global {

      .MuiAccordionSummary-content {
        margin: 0 !important;
      }

      .MuiAccordionSummary-root {
        padding: 0 !important;
        min-height: auto !important;
      }

      .MuiAccordionDetails-root {
        padding: 24px 0 0 !important;
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .MuiFormControlLabel-root {
        align-items: flex-start !important;
        padding: 3px 0 12px !important;
        margin-right: 0 !important;
        width: 100% !important;

        &:local {
          &.downloadAllCheckbox {
            margin-left: 0 !important;
            background: var(--bg-main) !important;
            border-radius: 8px !important;
            padding: 3px 0 !important;
            margin-bottom: 9px !important;

            :global {

              .MuiTypography-root {
                font-weight: 600 !important;
              }
            }
          }

          &.downloadCheckbox {
            margin-left: 20px !important;
          }
        }
      }

      .MuiTypography-root {
        margin-top: 9px !important;
      }
    }

    &__title {
      @include h4;
    }
  }
}
