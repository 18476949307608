@import "src/styles/helpers";

.form {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  &__uploadArea {
    margin: 24px 0;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  h5 {
    @include h5;
    margin-bottom: 4px;
    color: var(--dark);
  }

  &__input {
    position: relative;
    padding-left: 40px;

    :global {
      .MuiInputLabel-root:not(.MuiInputLabel-shrink) {
        left: 39px !important;
      }

      .MuiInputBase-input {
        @include bodyMedium;
        padding-left: 52px !important;
        font-family: Inter, sans-serif;
        color: var(--dark);
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 17px;
      left: 16px;
      width: 20px;
      height: 20px;
      background-size: contain;
      z-index: 2;
    }


    &_en {
      &:before {
        background: url('/public/media/eng-lang-icon.svg') no-repeat center;
      }
    }

    &_de {
      &:before {
        background: url('/public/media/deu-lang-icon.svg') no-repeat center;
      }
    }
  }

  &__file {
    padding: 16px;
    border-radius: 8px;
    width: 432px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    background: var(--bg-main);

    @include media(mobile) {
      width: unset;
    }

    &__svg {
      margin-bottom: 8px;
      width: 40px;
      height: 40px;
      flex-shrink: 0
    }

    &__text {
      overflow: hidden;
      flex-grow: 1;
    }

    &__title {
      @include subtitleSmall;
      color: var(--dark);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include media(mobile) {
        word-break: break-word;
        white-space: break-spaces;
      }
    }

    &__subtitle {
      @include bodySmall;
      color: var(--grey);
    }

    &__removeBtn,
    &__downloadBtn {
      margin-left: auto;
      cursor: pointer;
      color: var(--grey);
      flex-shrink: 0;
    }

    &__removeBtn {
      width: 24px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding: 32px 0 24px;

    button {
      width: 100%;
    }
  }
}

.info {
  margin-top: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: 16px auto;
  gap: 8px;
  color: var(--grey);

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__text {
    @include bodySmall;
  }

  &__btn {
    color: var(--primary-500);
    text-decoration: underline;
  }
}

.error {
  @include bodyMedium;
  text-align: center;
  color: var(--red);
  margin-top: 8px;
}